import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  AddAction,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Datepicker,
  DetailsSection,
  DRAFT_STATUS,
  Form,
  FormRow,
  Input,
  Select
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { capitalise, getClassNames, isEmailValue, isEmpty, scrollIntoView } from '@neslotech/utils';

import { createDependant, updateDependant } from '../../../actions/dependant/dependant.action';

import { useWizard } from '../../../hooks/useWizard';

import { ReactComponent as EditIcon } from '../../../icons/edit-icon.svg';

import EventDescription from '../../events/details/EventDescription';
import BasicSelect from '../../select/BasicSelect';
import Pill from '../../pill/Pill';

import './entrant-detail.scss';

const rules = {
  validates: {
    requirement: ['isPresent'],
    thirdPartyData: ['isPresent', 'isTruthy'],
    tsAndCs: ['isPresent', 'isTruthy']
  },

  isTruthy(value) {
    if (!value) {
      return 'Must be selected';
    }
  }
};

const MYSELF = 'myself';
const SOMEONE_ELSE = 'someone_else';
const BOTH = 'both';

const items = () => [
  {
    key: MYSELF,
    label: 'Myself',
    value: MYSELF
  },
  {
    key: SOMEONE_ELSE,
    label: 'Someone Else',
    value: SOMEONE_ELSE
  },
  {
    key: BOTH,
    label: 'Both',
    value: BOTH
  }
];

const formifyEntry = (entry) => {
  const profile = entry?.entrants_attributes ? entry.entrants_attributes[0] : undefined;
  return {
    requirement: entry?.entry_type,
    myselfDetails: profile ? formify(profile) : undefined,
    dependantDetails: [],
    otherParticipants: [],
    tsAndCs: !!profile,
    thirdPartyData: !!profile
  };
};

const formify = (profile) => ({
  id: profile?.id,
  firstName: profile?.first_name,
  lastName: profile?.last_name,
  email: profile?.email,
  contactNumber: profile?.phone_number,
  gender: profile?.gender,
  dateOfBirth: profile?.date_of_birth ? new Date(profile?.date_of_birth) : '',
  idNumber: profile?.id_number,
  emergencyContactPerson: profile?.medical_information?.contact_person,
  emergencyContactNumber: profile?.medical_information?.contact_number,
  medicalAidName: profile?.medical_information?.medical_aid,
  medicalAidNumber: profile?.medical_information?.medical_aid_number,
  allergies: profile?.medical_information?.allergies,
  sports: profile?.sports
});

const serverify = (form) => ({
  id: form.id,
  first_name: form.firstName,
  last_name: form.lastName,
  email: form.email,
  phone_number: form.contactNumber,
  gender: form.gender,
  date_of_birth: form.dateOfBirth,
  id_number: form.idNumber,
  medical_information_attributes: {
    contact_person: form.emergencyContactPerson,
    contact_number: form.emergencyContactNumber,
    medical_aid: form.medicalAidName,
    medical_aid_number: form.medicalAidNumber,
    allergies: form.allergies
  },
  sports: form.sports
});

const EntrantDetails = () => {
  const { context, profile, entry, onEntryChange, dependants } = useWizard();

  const [form, setForm] = useFormState(formifyEntry(entry), rules);

  const handleChange = (newState) => setForm({ ...form, ...newState });
  const handleMyDetailsChange = (newState) =>
    setForm({ ...form, myselfDetails: { ...form?.myselfDetails, ...newState } });

  useEffect(() => {
    const updatedForm = { ...form };
    if (
      (updatedForm?.requirement === MYSELF || updatedForm?.requirement === BOTH) &&
      typeof updatedForm?.myselfDetails?.valid === 'undefined'
    ) {
      updatedForm.myselfDetails = { ...formify(profile), valid: true };
    }

    updatedForm.dependantDetails.forEach((item) => {
      if (typeof item.valid === 'undefined') {
        item.valid = true;
      }
    });
    setForm(updatedForm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.requirement, form?.dependantDetails, form?.myselfDetails, profile]);

  const dependantItems = useMemo(() => {
    return (dependants ?? [])
      .filter((dependant) => form?.dependantDetails.every((item) => item.id !== dependant.id))
      .map((dependant) => {
        return {
          label: dependant.full_name,
          value: dependant.id
        };
      });
  }, [form, dependants]);

  const handleDependantChange = (newState) => {
    const exists = form?.dependantDetails.findIndex(
      (item) => item.id === Number(newState.selectedDependant)
    );
    const dependantDetails = [...form?.dependantDetails];
    if (exists > -1) {
      dependantDetails.splice(exists, 1);
    } else {
      const dependant = dependants.find(
        (dependant) => dependant.id === Number(newState.selectedDependant)
      );
      dependantDetails.push(formify(dependant));
    }
    handleChange({ dependantDetails, selectedDependant: '' });
  };

  const handleDependantRemove = (selectedDependant) => {
    const dependantDetails = form.dependantDetails.filter(
      (dependant) => dependant.id !== selectedDependant.id
    );
    handleChange({ dependantDetails, selectedDependant: '' });
  };

  const handleNextStep = () => {
    const entrants = [];
    if (form?.myselfDetails) {
      entrants.push(serverify(form?.myselfDetails));
    }
    entrants.push(...form?.otherParticipants.map((item) => serverify(item)));
    entrants.push(...form?.dependantDetails.map((item) => serverify(item)));

    const payload = {
      status: DRAFT_STATUS,
      entry_type: form?.requirement,
      entrant_ids: entrants.filter((item) => !!item.id).map((item) => item.id),
      entrants_attributes: entrants
    };
    onEntryChange(payload);
  };

  const addNewParticipant = () => {
    const otherParticipants = form.otherParticipants;
    otherParticipants.push(formify());
    handleChange({ otherParticipants });
  };

  const dependantFormsInvalid =
    !isEmpty(form?.dependantDetails) && form?.dependantDetails.some((item) => !item.valid);
  const myDetailsInvalid = !!form?.myselfDetails && !form?.myselfDetails.valid;
  const hasNoDependants = dependants?.length === 0;

  return (
    <article className="entrant-details">
      <h2 id="start">Start</h2>
      {context && <EventDescription event={context} />}

      <Card>
        {hasNoDependants ? (
          <FormRow>
            <BasicSelect
              name="requirement"
              label="Are you entering this event for yourself or someone else?"
              placeholder="Select who you are entering for"
              items={[items()[0]]}
              onChange={(newState) =>
                handleChange({
                  ...newState,
                  addNew: undefined,
                  addDependant: undefined,
                  myselfDetails: undefined,
                  otherParticipants: []
                })
              }
              value={form?.requirement}
            />
          </FormRow>
        ) : (
          <FormRow>
            <BasicSelect
              name="requirement"
              label="Are you entering this event for yourself or someone else?"
              placeholder="Select who you are entering for"
              items={items()}
              value={form?.requirement}
              onChange={(newState) =>
                handleChange({
                  ...newState,
                  addNew: undefined,
                  addDependant: undefined,
                  myselfDetails: undefined,
                  otherParticipants: []
                })
              }
            />
          </FormRow>
        )}
        {(form?.requirement === SOMEONE_ELSE || form?.requirement === BOTH) && (
          <FormRow>
            <BasicSelect
              name="selectedDependant"
              label="Please select the dependant(s) you wish to enter for:"
              placeholder="Select the dependant(s) you are entering for"
              items={dependantItems}
              onChange={handleDependantChange}
              defaultValue={form?.selectedDependant}
            />
          </FormRow>
        )}
        {!isEmpty(form.dependantDetails) && (
          <FormRow>
            <article className="entrant-details__pills">
              {form.dependantDetails.map((detail) => (
                <Pill
                  fitted
                  key={detail.id}
                  onClick={() => handleDependantRemove(detail)}
                  name={`${detail.firstName} ${detail.lastName}`}
                  selected
                />
              ))}
            </article>
          </FormRow>
        )}
      </Card>
      {form?.myselfDetails && (
        <MyDetails
          onAddParticipant={addNewParticipant}
          form={form?.myselfDetails}
          handleChange={handleMyDetailsChange}
        />
      )}
      {form?.dependantDetails.map((dependantForm, index) => {
        const handleDetailChange = (newState) => {
          const dependantDetails = form.dependantDetails;
          dependantDetails[index] = {
            ...dependantDetails[index],
            ...newState
          };

          handleChange({ dependantDetails });
        };

        const handleRemove = () => {
          const dependantDetails = form.dependantDetails.filter(
            (dependant) => dependant.id === form.id
          );
          handleChange({ dependantDetails });

          scrollIntoView('start');
        };

        return (
          <DependantDetails
            key={index}
            form={dependantForm}
            handleChange={handleDetailChange}
            handleRemove={handleRemove}
            profileId={profile?.id}
          />
        );
      })}
      {form?.otherParticipants.map((participantForm, index) => {
        const handleDetailChange = (newState) => {
          const otherParticipants = form.otherParticipants;
          otherParticipants[index] = {
            ...otherParticipants[index],
            ...newState
          };

          handleChange({ otherParticipants });
        };

        const handleRemove = () => {
          const otherParticipants = form.otherParticipants.filter(
            (_, innerIndex) => index !== innerIndex
          );
          handleChange({ otherParticipants });

          scrollIntoView('start');
        };

        return (
          <OtherParticipantDetails
            key={index}
            onAddParticipant={addNewParticipant}
            form={participantForm}
            handleChange={handleDetailChange}
            handleRemove={handleRemove}
            profileId={profile?.id}
          />
        );
      })}
      {form?.requirement && (
        <article className="entrant-details__agreements">
          <DetailsSection
            title="Agreements"
            subtitle="Please check the agreement boxes below to continue with your entry"
          >
            <Checkbox
              name="tsAndCs"
              checked={form?.tsAndCs}
              label="I Agree to the organisations Terms and Conditions and privacy policy"
              onChange={handleChange}
            />
            <Checkbox
              name="thirdPartyData"
              checked={form?.thirdPartyData}
              label="I understand that my personal data will be shared with the event organisers and any third party involved in the event"
              onChange={handleChange}
            />
          </DetailsSection>
        </article>
      )}
      <footer className="entrant-details__actions">
        <Button
          label="Next Step"
          disabled={!form?.valid || dependantFormsInvalid || myDetailsInvalid}
          onClick={handleNextStep}
        />
      </footer>
    </article>
  );
};

const participantRules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    email: ['isPresent', 'isEmail'],
    contactNumber: ['isNumeric'],
    dateOfBirth: ['isPresent'],
    idNumber: ['isPresent']
  }
};

const dependantRules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    email: ['isEmailIfPresent'],
    contactNumber: ['isNumeric'],
    dateOfBirth: ['isPresent'],
    idNumber: ['isPresent']
  },

  isEmailIfPresent(value) {
    if (!value || value.trimEnd() === '') {
      return false;
    }

    if (!isEmailValue(value)) {
      return 'If an email address is provided, it needs to be valid';
    }
  }
};

const genderItems = (handleChange) => [
  {
    text: 'Male',
    onClick: () => handleChange({ gender: 'Male' })
  },
  {
    text: 'Female',
    onClick: () => handleChange({ gender: 'Female' })
  },
  {
    text: 'Other',
    onClick: () => handleChange({ gender: 'Other' })
  }
];

const MyDetails = ({ form, handleChange }) => {
  const { onUpdateProfile } = useWizard();

  const [myForm, setMyForm] = useFormState(form, participantRules);
  const [editMode, setEditMode] = useState(false);

  const handleSave = () => {
    const payload = serverify(myForm);
    onUpdateProfile(payload);
    setEditMode(false);
  };

  const handleFormChange = (newState) => {
    setMyForm({ ...myForm, ...newState });
    handleChange({ ...myForm, ...newState });
  };

  return (
    <article className={getClassNames('my-details-section', { editMode })}>
      <DetailsSection
        title="My Personal Details"
        subtitle="Please make sure your personal and medical details below are correct and edit them if need be."
        icon={<EditIcon onClick={() => setEditMode(true)} />}
      >
        <Form>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="firstName"
              placeholder="First Name"
              label="First Name"
              value={myForm?.firstName}
              error={myForm?.messages?.firstName}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              value={myForm?.lastName}
              error={myForm?.messages?.lastName}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled
              name="email"
              placeholder="Email Address"
              label="Email Address"
              value={myForm?.email}
              error={myForm?.messages?.email}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="contactNumber"
              placeholder="Contact Number"
              label="Contact Number"
              value={myForm?.contactNumber}
              error={myForm?.messages?.contactNumber}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Select
              disabled={!editMode}
              name="gender"
              placeholder="Gender"
              label="Gender"
              items={genderItems(handleFormChange)}
              value={capitalise(myForm?.gender)}
            />
            <Datepicker
              yearDropdownItemNumber={60}
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="scroll"
              disabled={!editMode}
              name="dateOfBirth"
              placeholder="Date of Birth"
              label="Date of Birth"
              value={myForm?.dateOfBirth}
              error={myForm?.messages?.dateOfBirth}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="idNumber"
              placeholder="ID/Passport Number"
              label="ID/Passport Number"
              value={myForm?.idNumber}
              error={myForm?.messages?.idNumber}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="emergencyContactPerson"
              placeholder="Emergency Contact Person"
              label="Emergency Contact Person"
              value={myForm?.emergencyContactPerson}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="emergencyContactNumber"
              placeholder="Emergency Contact Number"
              label="Emergency Contact Number"
              value={myForm?.emergencyContactNumber}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="medicalAidName"
              placeholder="Medical Aid Name"
              label="Medical Aid Name"
              value={myForm?.medicalAidName}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="medicalAidNumber"
              placeholder="Medical Aid Number"
              label="Medical Aid Number"
              value={myForm?.medicalAidNumber}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="allergies"
              placeholder="Allergies"
              label="Allergies"
              value={myForm?.allergies}
              onChange={handleFormChange}
            />
          </FormRow>
        </Form>
      </DetailsSection>
      <footer className="my-details-section__actions">
        {editMode && <Button disabled={!myForm?.valid} label="Save Changes" onClick={handleSave} />}
      </footer>
    </article>
  );
};

const DependantDetails = ({ form, handleChange, handleRemove, profileId }) => {
  const dispatch = useDispatch();

  const [dependantForm, setDependantForm] = useFormState(form, dependantRules);
  const [editMode, setEditMode] = useState(false);

  const handleSave = () => {
    const payload = serverify(dependantForm);
    dispatch(updateDependant(profileId, dependantForm?.id, payload));
    setEditMode(false);
  };

  const handleFormChange = (newState) => {
    setDependantForm({ ...dependantForm, ...newState });
    handleChange({ ...dependantForm, ...newState });
  };

  return (
    <article className={getClassNames('my-details-section', { editMode })}>
      <DetailsSection
        title={`${dependantForm?.firstName} ${dependantForm?.lastName}'s Personal Details`}
        subtitle="Please make sure your personal and medical details below are correct and edit them if need be."
        icon={<EditIcon onClick={() => setEditMode(true)} />}
      >
        <Form>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="firstName"
              placeholder="First Name"
              label="First Name"
              value={dependantForm?.firstName}
              error={dependantForm?.messages?.firstName}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              value={dependantForm?.lastName}
              error={dependantForm?.messages?.lastName}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled
              name="email"
              placeholder="Email Address"
              label="Email Address"
              value={dependantForm?.email}
              error={dependantForm?.messages?.email}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="contactNumber"
              placeholder="Contact Number"
              label="Contact Number"
              value={dependantForm?.contactNumber}
              error={dependantForm?.messages?.contactNumber}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Select
              disabled={!editMode}
              name="gender"
              placeholder="Gender"
              label="Gender"
              items={genderItems(handleFormChange)}
              value={capitalise(dependantForm?.gender)}
            />
            <Datepicker
              yearDropdownItemNumber={60}
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="scroll"
              disabled={!editMode}
              name="dateOfBirth"
              placeholder="Date of Birth"
              label="Date of Birth"
              value={dependantForm?.dateOfBirth}
              error={dependantForm?.messages?.dateOfBirth}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="idNumber"
              placeholder="ID/Passport Number"
              label="ID/Passport Number"
              value={dependantForm?.idNumber}
              error={dependantForm?.messages?.idNumber}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="emergencyContactPerson"
              placeholder="Emergency Contact Person"
              label="Emergency Contact Person"
              value={dependantForm?.emergencyContactPerson}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="emergencyContactNumber"
              placeholder="Emergency Contact Number"
              label="Emergency Contact Number"
              value={dependantForm?.emergencyContactNumber}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="medicalAidName"
              placeholder="Medical Aid Name"
              label="Medical Aid Name"
              value={dependantForm?.medicalAidName}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editMode}
              name="medicalAidNumber"
              placeholder="Medical Aid Number"
              label="Medical Aid Number"
              value={dependantForm?.medicalAidNumber}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editMode}
              name="allergies"
              placeholder="Allergies"
              label="Allergies"
              value={dependantForm?.allergies}
              onChange={handleFormChange}
            />
          </FormRow>
        </Form>
      </DetailsSection>
      <footer className="my-details-section__actions">
        <ButtonGroup fluid spaced>
          {editMode && (
            <Button disabled={!dependantForm?.valid} label="Save Changes" onClick={handleSave} />
          )}
          <Button secondaryDanger label="Remove" onClick={handleRemove} />
        </ButtonGroup>
      </footer>
    </article>
  );
};

const OtherParticipantDetails = ({
  form,
  handleChange,
  profileId,
  onAddParticipant,
  handleRemove
}) => {
  const dispatch = useDispatch();

  const { dependants } = useWizard();

  const [participantForm, setParticipantForm] = useFormState(form, participantRules);

  const handleFormChange = (newState) => {
    setParticipantForm({ ...participantForm, ...newState });
    handleChange({ ...participantForm, ...newState });
  };

  useEffect(() => {
    const matchingDependant = dependants.find(
      (dependant) => dependant.email === participantForm?.email
    );
    if (matchingDependant) {
      handleFormChange({ id: matchingDependant.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependants, participantForm?.email]);

  const handleSaveDependant = () => {
    const payload = serverify(participantForm);
    dispatch(createDependant(profileId, payload));
  };

  return (
    <article className="participant-details-section">
      <DetailsSection
        title="Add New Participant"
        subtitle="Please enter the personal and medical details below for the participant."
      >
        <Form>
          <FormRow fluidSpaced>
            <Input
              name="firstName"
              placeholder="First Name"
              label="First Name"
              value={participantForm?.firstName}
              error={participantForm?.messages?.firstName}
              onChange={handleFormChange}
            />
            <Input
              name="lastName"
              placeholder="Last Name"
              label="Last Name"
              value={participantForm?.lastName}
              error={participantForm?.messages?.lastName}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              name="email"
              placeholder="Email Address"
              label="Email Address"
              value={participantForm?.email}
              error={participantForm?.messages?.email}
              onChange={handleFormChange}
            />
            <Input
              name="contactNumber"
              placeholder="Contact Number"
              label="Contact Number"
              value={participantForm?.contactNumber}
              error={participantForm?.messages?.contactNumber}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Select
              name="gender"
              placeholder="Gender"
              label="Gender"
              items={genderItems(handleFormChange)}
              value={capitalise(participantForm?.gender)}
            />
            <Datepicker
              yearDropdownItemNumber={60}
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="scroll"
              name="dateOfBirth"
              placeholder="Date of Birth"
              label="Date of Birth"
              value={participantForm?.dateOfBirth}
              error={participantForm?.messages?.dateOfBirth}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              name="idNumber"
              placeholder="ID/Passport Number"
              label="ID/Passport Number"
              value={participantForm?.idNumber}
              error={participantForm?.messages?.idNumber}
              onChange={handleFormChange}
            />
            <Input
              name="emergencyContactPerson"
              placeholder="Emergency Contact Person"
              label="Emergency Contact Person"
              value={participantForm?.emergencyContactPerson}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              name="emergencyContactNumber"
              placeholder="Emergency Contact Number"
              label="Emergency Contact Number"
              value={participantForm?.emergencyContactNumber}
              onChange={handleFormChange}
            />
            <Input
              name="medicalAidName"
              placeholder="Medical Aid Name"
              label="Medical Aid Name"
              value={participantForm?.medicalAidName}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              name="medicalAidNumber"
              placeholder="Medical Aid Number"
              label="Medical Aid Number"
              value={participantForm?.medicalAidNumber}
              onChange={handleFormChange}
            />
            <Input
              name="allergies"
              placeholder="Allergies"
              label="Allergies"
              value={participantForm?.allergies}
              onChange={handleFormChange}
            />
          </FormRow>
        </Form>
      </DetailsSection>
      <footer className="participant-details-section__actions">
        <AddAction title="Add another participant" onClick={onAddParticipant} />
        <ButtonGroup fluid spaced>
          <Button
            disabled={!participantForm?.valid}
            longText
            label="Save as a Dependant"
            onClick={handleSaveDependant}
          />
          <Button secondaryDanger label="Remove" onClick={handleRemove} />
        </ButtonGroup>
      </footer>
    </article>
  );
};

export default EntrantDetails;
