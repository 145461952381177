import { API_HOST } from './index';

import { getHttpGetOptions, getHttpPostOptions, getHttpPutOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

const getEventsEndpoint = () => `${API_HOST}/public/events`;
export const getLoadEventsRequest = () => [
  getEventsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getTrendingEndpoint = () => `${API_HOST}/public/events/trending`;
export const getLoadTrendingRequest = () => [
  getTrendingEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

const getEventEndpoint = (id) => `${API_HOST}/public/events/${id}`;
export const getLoadEventRequest = (id) => [
  getEventEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getEntriesEndpoint = (id) => `${API_HOST}/events/${id}/entries`;
export const getLoadEntriesRequest = (id) => [
  getEntriesEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getSaveEntriesRequest = (id, payload) => [
  getEntriesEndpoint(id),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getEntryEndpoint = (id, entryId) => `${API_HOST}/events/${id}/entries/${entryId}`;
export const getLoadEntryRequest = (id, entryId) => [
  getEntryEndpoint(id, entryId),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateEntryRequest = (id, entryId, payload) => [
  getEntryEndpoint(id, entryId),
  getHttpPutOptions(payload, getAuthHeaders())
];

const getCompleteEntryEndpoint = (id, entryId) => `${getEntryEndpoint(id, entryId)}/complete`;
export const getCompleteEntryRequest = (id, entryId, payload) => [
  getCompleteEntryEndpoint(id, entryId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getProofOfPaymentEndpoint = (id, entryId) => `${getEntryEndpoint(id, entryId)}/payment-proof`;
export const getProofOfPaymentRequest = (id, entryId, payload) => [
  getProofOfPaymentEndpoint(id, entryId),
  getHttpPostOptions(payload, getAuthHeaders())
];

const getEntryFormEndpoint = (id) => `${API_HOST}/events/${id}/entry-form`;
export const getLoadEntryFormRequest = (id) => [
  getEntryFormEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getMemberTypesEndpoint = (id) => `${API_HOST}/events/${id}/members`;
export const getLoadMemberTypesRequest = (id) => [
  getMemberTypesEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

const getClassesEndpoint = (id) => `${API_HOST}/events/${id}/classes`;
export const getLoadClassesRequest = (id) => [
  getClassesEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];
