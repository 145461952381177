import React from 'react';
import { Link } from 'react-router-dom';

import {
  Checkbox,
  Form,
  FormAction,
  FormRow,
  Input,
  PARTICIPANT_ROLE,
  PasswordInput
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './register.scss';

const rules = {
  validates: {
    email: ['isPresent', 'isEmail'],
    password: ['isPresent'],
    passwordConfirm: ['isPresent', 'arePasswordsEqual'],
    tsAndCs: ['isPresent']
  },

  arePasswordsEqual(value, form) {
    if (value !== form?.password) {
      return 'Passwords do not match';
    }
  }
};

const serverify = ({ email, password, passwordConfirm }) => ({
  email,
  password,
  password_confirm: passwordConfirm,
  role: PARTICIPANT_ROLE
});

const Register = ({ loading, onRegister }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleCheckboxChange = () => {
    setForm({ ...form, tsAndCs: !form?.tsAndCs });
  };

  const onSubmit = () => {
    const payload = serverify(form);
    onRegister(payload);
  };

  const termsLabel = (
    <small className="register__links">
      Accept&nbsp;
      <Link to="/terms" target="_blank" rel="noreferrer">
        Terms and Conditions
      </Link>
      &nbsp;and&nbsp;
      <Link to="/contact-us" target="_blank" rel="noreferrer">
        Privacy Policy
      </Link>
    </small>
  );

  return (
    <section className="register">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            type="email"
            value={form?.email}
            error={form?.messages?.email}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="password"
            label="Password"
            placeholder="Password"
            value={form?.password}
            error={form?.messages?.password}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="passwordConfirm"
            label="Confirm Password"
            placeholder="Confirm Password"
            value={form?.passwordConfirm}
            error={form?.messages?.passwordConfirm}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Checkbox name="tsAndCs" label={termsLabel} onChange={handleCheckboxChange} />
        </FormRow>
        <FormAction
          loading={loading}
          label="Create Account"
          onClick={onSubmit}
          disabled={!form?.valid}
        />
        <small className="register__login-link">
          Already have an account? <Link to="/login">Login Here.</Link>
        </small>
      </Form>
    </section>
  );
};

export default Register;
