import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getProfileInitialsForForm,
  ProfileImage,
  Tabs,
  toBase64
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { getClassNames } from '@neslotech/utils';

import { serverifySportDetails } from './sports/sports.helpers';

import { ReactComponent as ProfileIcon } from '../../icons/profile-square-icon.svg';
import { ReactComponent as DependantsIcon } from '../../icons/dependants-icon.svg';
import { ReactComponent as SportsIcon } from '../../icons/pulse-icon.svg';

import ProfileForm from './ProfileForm';

import './profile-details.scss';

const rules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    idNumber: ['isPresent'],
    contactNumber: ['isNumeric'],
    emergencyContactNumber: ['isNumeric']
  }
};

const formify = (profile) => ({
  image: profile?.image,
  firstName: profile?.first_name,
  lastName: profile?.last_name,
  email: profile?.email,
  contactNumber: profile?.phone_number,
  idNumber: profile?.id_number,
  gender: profile?.gender,
  dateOfBirth: profile?.date_of_birth ? new Date(profile?.date_of_birth) : new Date(),
  address: profile?.address,
  city: profile?.city,
  province: profile?.province,
  emergencyContactPerson: profile?.medical_information?.contact_person,
  emergencyContactNumber: profile?.medical_information?.contact_number,
  medicalAidName: profile?.medical_information?.medical_aid,
  medicalAidNumber: profile?.medical_information?.medical_aid_number,
  bloodType: profile?.medical_information?.blood_type,
  allergies: profile?.medical_information?.allergies,
  notes: profile?.medical_information?.notes,
  sports: profile?.sports
});

const serverify = (form) => ({
  first_name: form.firstName,
  last_name: form.lastName,
  phone_number: form.contactNumber,
  image: form.image,
  gender: form.gender,
  date_of_birth: form.dateOfBirth,
  id_number: form.idNumber,
  address: form.address,
  city: form.city,
  province: form.province,
  medical_information_attributes: {
    contact_person: form.emergencyContactPerson,
    contact_number: form.emergencyContactNumber,
    medical_aid: form.medicalAidName,
    medical_aid_number: form.medicalAidNumber,
    blood_type: form.bloodType,
    allergies: form.allergies,
    notes: form.notes
  },
  sport_details_attributes: serverifySportDetails(form.sports)
});

const personalDetailsSubtitle =
  'This information is typically used for your timing results and to get in contact with you if any event information changes.';

const medicalInformationSubtitle =
  'If you manage to get a few scrapes at an event, we might share this information with ' +
  'medical personnel to assist you in a most speedy recovery.';

const PROFILE_KEY = 'profile';
const DEPENDANTS_KEY = 'dependants';
const SPORTS_KEY = 'sports';

const tabs = (navigate) => [
  {
    key: PROFILE_KEY,
    label: 'My Profile',
    icon: <ProfileIcon />,
    onClick: () => navigate('/profile')
  },
  {
    key: DEPENDANTS_KEY,
    label: 'My Dependants',
    icon: <DependantsIcon />,
    onClick: () => navigate('/dependants')
  },
  {
    key: SPORTS_KEY,
    label: 'My Sports',
    icon: <SportsIcon />,
    onClick: () => navigate('/sports')
  }
];

const ProfileDetails = ({ profile, onSave }) => {
  const navigate = useNavigate();
  const [form, setForm] = useFormState(formify(profile), rules);

  const [editMode, setEditMode] = useState(false);

  const handleFileChange = async (file) => {
    const data = await toBase64(file);
    handleChange({ image: data });
  };

  const handleChange = (newState) => setForm({ ...form, ...newState });
  const handleSportChange = (newSportState) => {
    const updatedSports = form.sports.reduce((accum, sport) => {
      if (sport.id === newSportState.sport_id) {
        sport = { ...sport, detail: { ...sport.detail, ...newSportState } };
      }

      accum.push(sport);
      return accum;
    }, []);
    handleChange({ sports: updatedSports });
  };

  const handleSubmit = () => {
    setEditMode(false);
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className={getClassNames('profile-details', { editing: editMode })}>
      <header>
        <h4 className="profile-details__title">My Profile</h4>
      </header>
      <main className="profile-details__content">
        <section className="profile-details__context">
          <section className="profile-details__avatar">
            <ProfileImage
              handleFileChange={handleFileChange}
              editMode={editMode}
              imgSrc={form?.image}
              initials={getProfileInitialsForForm(form)}
            />
          </section>
          <section className="profile-details__tabs">
            {tabs && <Tabs activeTab={PROFILE_KEY} tabs={tabs(navigate)} vertical />}
          </section>
        </section>
        <section className="profile-details__form">
          <ProfileForm
            editable={editMode}
            setEditable={setEditMode}
            form={form}
            handleChange={handleChange}
            handleSportChange={handleSportChange}
            handleSubmit={handleSubmit}
            personalDetailsSubtitle={personalDetailsSubtitle}
            medicalInformationSubtitle={medicalInformationSubtitle}
          />
        </section>
      </main>
    </article>
  );
};

export default ProfileDetails;
