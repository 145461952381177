import React, { useEffect, useMemo, useState } from 'react';

import {
  Accordion,
  Button,
  FormRow,
  isMobile,
  RadioGroup,
  Table
} from '@neslotech/eventhub-ui-kit';
import { isEmpty, isValuePresent } from '@neslotech/utils';
import { useFormState } from '@neslotech/hooks';

import { useWizard } from '../../../hooks/useWizard';

import EventDescription from '../../events/details/EventDescription';
import BasicSelect from '../../select/BasicSelect';

import './class-selection.scss';

const classCols = [
  { Header: 'Class Name', accessor: 'name' },
  { Header: '1st Class', accessor: 'first_class_price' },
  { Header: '2nd Class', accessor: 'second_class_price' },
  { Header: '1st Class (Family Member)', accessor: 'first_class_family_price' },
  { Header: '2nd Class (Family Member)', accessor: 'second_class_family_price' }
];

const mobileClassCols = [
  { Header: 'Class', accessor: 'name', disableSortBy: true },
  { Header: '1st Class', accessor: 'first_class_price', disableSortBy: true },
  { Header: '2nd Class', accessor: 'second_class_price', disableSortBy: true }
];

const formifyClassSelections = (classSelections, classes) => {
  return (classSelections ?? []).reduce((accum, item) => {
    const existingClass = classes.find((klass) => klass.id === Number(item.competition_class_id));
    const existingForm = accum.find((form) => form.entrantId === item.entrant_id);
    if (existingForm) {
      if (item.first_class) {
        existingForm.firstClass = existingClass?.id;
        existingForm.firstClassVehicle = item.vehicle_id;
      } else {
        existingForm.secondClass = existingClass?.id;
        existingForm.secondClassVehicle = item.vehicle_id;
      }
    } else {
      if (item.first_class) {
        accum.push({
          entrantId: item.entrant_id,
          firstClassVehicle: item.vehicle_id,
          firstClass: existingClass?.id
        });
      } else {
        accum.push({
          entrantId: item.entrant_id,
          secondClassVehicle: item.vehicle_id,
          secondClass: existingClass?.id
        });
      }
    }

    return accum;
  }, []);
};

const formify = (row) => ({
  entrantId: row.id
});

const serverify = ({
  entrantId,
  firstClass,
  firstClassVehicle,
  secondClass,
  secondClassVehicle
}) => {
  const selections = [
    {
      competition_class_id: firstClass,
      entrant_id: entrantId,
      first_class: true,
      vehicle_id: firstClassVehicle
    }
  ];
  if (secondClass) {
    selections.push({
      competition_class_id: secondClass,
      entrant_id: entrantId,
      first_class: false,
      vehicle_id: secondClassVehicle
    });
  }

  return selections;
};

const ClassSelection = () => {
  const { context, classes, entry, onEntryChange, vehicles } = useWizard();

  const [classSelections, setClassSelections] = useState(
    formifyClassSelections(entry?.class_selections_attributes, classes)
  );

  useEffect(() => {
    if (!entry?.class_selections_attributes) {
      const mappedSelections = (entry?.entrants_attributes ?? []).map((entrant) =>
        formify(entrant)
      );
      setClassSelections(mappedSelections);
    }
  }, [entry?.class_selections_attributes, entry?.entrants_attributes]);

  const handlePreviousStep = () => {
    const selections = classSelections
      .reduce((accum, form) => [...accum, ...serverify(form)], [])
      .filter((selection) => !!selection.competition_class_id);
    const payload = {
      ...entry,
      class_selections_attributes: isEmpty(selections) ? undefined : selections
    };
    onEntryChange(payload, true);
  };

  const handleNextStep = () => {
    const selections = classSelections
      .reduce((accum, form) => [...accum, ...serverify(form)], [])
      .filter((selection) => !!selection.competition_class_id);
    const payload = {
      ...entry,
      class_selections_attributes: selections
    };
    onEntryChange(payload);
  };

  const invalidForm = classSelections.some((form) => !form.valid);
  return (
    <article className="class-selection">
      <h2>Class Selection</h2>
      {context && <EventDescription event={context} />}
      <section className="class-selection__table">
        <h4>Available Classes for Event:</h4>
        <Table cols={isMobile() ? mobileClassCols : classCols} rowData={classes} />
      </section>
      <section className="class-selection__forms">
        <h4>Select Classes for the following Participant(s)</h4>
        {!isEmpty(classSelections) &&
          entry.entrants_attributes.map((entrant) => {
            const handleChange = (newState) => {
              let updatedForms = classSelections.reduce((accum, form) => {
                if (form.entrantId === newState.entrantId) {
                  const hasFirstVehicle =
                    isEmpty(vehicles) || isValuePresent(newState?.firstClassVehicle);
                  const hasSecondVehicle =
                    isEmpty(vehicles) || !isValuePresent(newState?.secondClass)
                      ? true
                      : isValuePresent(newState?.secondClass) &&
                        isValuePresent(newState?.secondClassVehicle);

                  form = {
                    ...form,
                    ...newState,
                    valid: newState.valid && hasFirstVehicle && hasSecondVehicle
                  };
                }

                accum.push(form);
                return accum;
              }, []);
              setClassSelections(updatedForms);
            };

            return (
              <EntrantSelections
                key={entrant.id}
                entrant={entrant}
                selectionForm={classSelections.find((item) => item.entrantId === entrant.id)}
                handleEntrantFormChange={handleChange}
                classes={classes}
              />
            );
          })}
      </section>
      <footer className="class-selection__actions">
        <Button hollow label="Previous Step" onClick={handlePreviousStep} />
        <Button label="Next Step" disabled={invalidForm} onClick={handleNextStep} />
      </footer>
    </article>
  );
};

const rules = {
  validates: {
    firstClass: ['isPresent']
  }
};

const EntrantSelections = ({ classes, entrant, selectionForm, handleEntrantFormChange }) => {
  const { sport } = useWizard();

  const [form, setForm] = useFormState(selectionForm, rules);
  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
    handleEntrantFormChange({ ...form, ...newState });
  };

  useEffect(() => {
    handleEntrantFormChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.valid]);

  const mappedVehicles = useMemo(() => {
    const matchingSport = (entrant.sports ?? []).find((item) => item.id === sport.id);
    if (!matchingSport) {
      return [];
    }

    return (matchingSport.detail.vehicles ?? []).map((vehicle) => ({
      label: vehicle.name,
      value: vehicle.id
    }));
  }, [entrant.sports, sport?.id]);

  const mappedClasses = useMemo(() => {
    return classes.map((klass) => ({ label: klass.name, value: klass.id }));
  }, [classes]);

  return (
    <Accordion title={`${entrant.first_name} ${entrant.last_name}`} open>
      <FormRow singleRow>
        <BasicSelect
          label={`Select ${entrant.first_name}'s first class`}
          placeholder="Select first class"
          name="firstClass"
          value={form?.firstClass}
          error={form?.messages?.firstClass}
          items={mappedClasses}
          onChange={handleChange}
        />
      </FormRow>
      {!isEmpty(mappedVehicles) && (
        <FormRow singleRow>
          <RadioGroup
            fluid
            name="firstClassVehicle"
            items={mappedVehicles}
            onChange={handleChange}
            value={form?.firstClassVehicle}
            error={!isValuePresent(form?.firstClassVehicle) && 'This is required'}
          />
        </FormRow>
      )}
      <FormRow singleRow>
        <BasicSelect
          label={`Select ${entrant.first_name}'s second class (optional)`}
          placeholder="Select second class"
          name="secondClass"
          value={form?.secondClass}
          onChange={handleChange}
          items={mappedClasses.filter((item) =>
            form?.firstClass ? form?.firstClass !== item.value : true
          )}
        />
      </FormRow>
      {!isEmpty(mappedVehicles) && (
        <FormRow singleRow>
          <RadioGroup
            fluid
            name="secondClassVehicle"
            items={mappedVehicles}
            onChange={handleChange}
            value={form?.secondClassVehicle}
            error={
              isValuePresent(form?.secondClass) &&
              !isValuePresent(form?.secondClassVehicle) &&
              'This is required'
            }
          />
        </FormRow>
      )}
    </Accordion>
  );
};

export default ClassSelection;
