import { SET_DEPENDANT, SET_DEPENDANTS } from '../../actions/dependant/dependant.action';

export const initialState = {
  dependants: [],
  dependant: undefined
};

export function dependantReducer(state = initialState, action) {
  if (action.type === SET_DEPENDANTS) {
    const { dependants } = action;
    return {
      ...state,
      dependants
    };
  } else if (action.type === SET_DEPENDANT) {
    const { dependant } = action;
    return {
      ...state,
      dependant
    };
  } else {
    return state;
  }
}
