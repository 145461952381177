import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Accordion, AddAction, Button, Input, isMobile, Table } from '@neslotech/eventhub-ui-kit';
import { generateId, isEmpty } from '@neslotech/utils';
import { useFormState } from '@neslotech/hooks';

import { useWizard } from '../../../hooks/useWizard';

import { ReactComponent as RemoveIcon } from '../../../icons/x-icon.svg';

import EventDescription from '../../events/details/EventDescription';
import FormCanvas from './FormCanvas';
import BasicSelect from '../../select/BasicSelect';
import CrewMembersMobile from './CrewMembers.mobile';

import './entry-forms.scss';

const formCols = [
  {
    accessor: 'full_name',
    Header: 'Participant Name'
  },
  {
    accessor: 'email',
    Header: 'Email Address'
  },
  {
    accessor: 'id_number',
    Header: 'ID/Passport Number'
  },
  {
    accessor: 'date_of_birth',
    Header: 'Date of Birth'
  },
  {
    accessor: 'actions',
    Header: '',
    disableSortBy: true
  }
];

const mobileFormCols = [
  {
    accessor: 'full_name',
    Header: 'Participant',
    disableSortBy: true
  },
  {
    accessor: 'id_number',
    Header: 'ID/Passport',
    disableSortBy: true
  }
];

const formifyEntryForms = (entryForms, memberTypes) =>
  (entryForms ?? []).map(({ entrant_id, members, ...rest }) => ({
    entrantId: entrant_id,
    members: members.map((member) => {
      const type = (memberTypes ?? []).find((type) => type.id === member.member_type_id);
      return {
        memberTypeId: member.member_type_id,
        memberType: type?.name,
        price: type?.price,
        fullName: member.full_name,
        contactNumber: member.phone_number,
        email: member.email,
        idNumber: member.id_number
      };
    }),
    ...rest
  }));

const formify = (row) => ({
  entrantId: row.id
});

const serverify = ({ entrantId, members, messages, valid, ...rest }) => ({
  entrant_id: entrantId,
  members: (members ?? [])
    .filter((member) => !!member.memberTypeId)
    .map((member) => ({
      entrant_id: entrantId,
      member_type_id: member.memberTypeId,
      full_name: member.fullName,
      phone_number: member.contactNumber,
      email: member.email,
      id_number: member.idNumber
    })),
  ...rest
});

const EntryForms = () => {
  const { context, entry, entryForm, memberTypes, onEntryChange } = useWizard();

  const [forms, setForms] = useState(formifyEntryForms(entry?.entry_forms, memberTypes));

  const mappedRows = useMemo(() => {
    return (entry?.entrants_attributes ?? []).map((item) => ({
      ...item,
      full_name: `${item.first_name} ${item.last_name}`,
      date_of_birth: item.date_of_birth?.toDateString(),
      actions: (
        <>
          <Button label="Remove" tertiary />
        </>
      )
    }));
  }, [entry?.entrants_attributes]);

  useEffect(() => {
    if (!entry.entry_forms) {
      const mappedForms = mappedRows.map((row) => formify(row));
      setForms(mappedForms);
    }
  }, [mappedRows, entry.entry_forms]);

  const handlePreviousStep = () => {
    const entryForms = forms.map((form) => serverify(form));
    const payload = {
      ...entry,
      entry_forms: entryForms
    };
    onEntryChange(payload, true);
  };

  const handleNextStep = () => {
    const entryForms = forms.map((form) => serverify(form));
    const payload = {
      ...entry,
      entry_forms: entryForms
    };
    onEntryChange(payload);
  };

  const invalidForm = forms.some((form) => {
    // On entry of the step, the form array does not get validated until a value has changed.
    // if there are keys on the form that are other than the default `entrantId`, the form
    // must be valid else navigation to the next step was not possible.
    // If a field is changed, validation will be triggered, and we can rely on the valid flag again.
    if (typeof form.valid === 'undefined') {
      return Object.keys(form).length === 1;
    }

    return !form.valid;
  });

  return (
    <article className="entry-forms">
      <h2>Entry Form</h2>
      {context && <EventDescription event={context} />}

      <section className="entry-forms__table">
        <p>Complete entry form(s) for the following participant(s):</p>
        <Table cols={isMobile() ? mobileFormCols : formCols} rowData={mappedRows} />
      </section>
      <section className="entry-forms__forms">
        <h4>Complete Entry Form(s)</h4>
        {!isEmpty(forms) &&
          mappedRows.map((entrant) => {
            const handleChange = (newState) => {
              let updatedForms = forms.reduce((accum, form) => {
                if (form.entrantId === newState.entrantId) {
                  form = {
                    ...form,
                    ...newState
                  };
                }

                accum.push(form);
                return accum;
              }, []);
              setForms(updatedForms);
            };

            return (
              <EntrantForm
                key={entrant.id}
                entrant={entrant}
                entryForm={entryForm}
                memberTypes={memberTypes}
                entrantForm={forms.find((item) => item.entrantId === entrant.id)}
                handleEntrantFormChange={handleChange}
              />
            );
          })}
      </section>
      <footer className="entry-forms__actions">
        <Button hollow label="Previous Step" onClick={handlePreviousStep} />
        <Button label="Next Step" disabled={invalidForm} onClick={handleNextStep} />
      </footer>
    </article>
  );
};

const EntrantForm = ({ entryForm, entrant, memberTypes, entrantForm, handleEntrantFormChange }) => {
  const rules = useMemo(() => {
    return entryForm.reduce(
      (accum, section) => {
        section.elements.forEach((element) => {
          accum.validates[element.id] = element.required ? ['isPresent'] : [];
        });

        return accum;
      },
      { validates: {} }
    );
  }, [entryForm]);

  const [form, setForm] = useFormState(entrantForm, rules);

  useEffect(() => {
    handleEntrantFormChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.valid]);

  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
    handleEntrantFormChange({ ...form, ...newState });
  };

  return (
    <Accordion title={entrant.full_name}>
      <FormCanvas entryForm={entryForm} form={form} handleChange={handleChange} />
      {!isEmpty(memberTypes) && isMobile() && (
        <CrewMembersMobile
          entrant={entrant}
          memberTypes={memberTypes}
          entrantForm={form}
          handleEntrantFormChange={handleChange}
        />
      )}
      {!isEmpty(memberTypes) && !isMobile() && (
        <CrewMembers
          entrant={entrant}
          memberTypes={memberTypes}
          entrantForm={form}
          handleEntrantFormChange={handleChange}
        />
      )}
    </Accordion>
  );
};

const memberCols = [
  {
    accessor: 'icon',
    Header: '',
    disableSortBy: true
  },
  {
    accessor: 'memberType',
    Header: 'Member Type',
    disableSortBy: isMobile()
  },
  {
    accessor: 'price',
    Header: 'Price',
    disableSortBy: isMobile()
  },
  {
    accessor: 'fullName',
    Header: 'Full Name',
    disableSortBy: isMobile()
  },
  {
    accessor: 'contactNumber',
    Header: 'Contact Number',
    disableSortBy: isMobile()
  },
  {
    accessor: 'email',
    Header: 'Email Address',
    disableSortBy: isMobile()
  },
  {
    accessor: 'idNumber',
    Header: 'ID Number',
    disableSortBy: isMobile()
  }
];

const EMPTY_CREW_MEMBER = () => ({
  memberType: '',
  price: '',
  fullName: '',
  contactNumber: '',
  email: '',
  idNumber: '',
  _id: generateId()
});

const rules = {
  validates: {
    members: ['isPresent']
  }
};

const formifyMembers = (entrantForm) => ({
  members: entrantForm.members ?? [EMPTY_CREW_MEMBER()]
});

const CrewMembers = ({ entrant, memberTypes, entrantForm, handleEntrantFormChange }) => {
  const [form, setForm] = useFormState(formifyMembers(entrantForm), rules);
  const handleChange = useCallback(
    (newState) => {
      setForm({ ...form, ...newState });
      handleEntrantFormChange({ ...entrantForm, ...newState });
    },
    [form, entrantForm, setForm, handleEntrantFormChange]
  );

  const mappedData = useMemo(() => {
    const handleValueChange = (index, value) => {
      const members = form?.members ?? [];
      let type = form?.members[index] ?? {};
      type = {
        ...type,
        ...value
      };
      members[index] = type;

      handleChange({ members });
    };

    return (form?.members ?? [])
      .filter((member) => !member._destroy)
      .map((member, index) => ({
        memberType: (
          <BasicSelect
            items={memberTypes.map((type) => ({
              value: type.id,
              label: type.name
            }))}
            borderless
            name="memberTypeId"
            placeholder="Member Type"
            value={member.memberTypeId}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        price: (
          <Input
            disabled
            borderless
            name="price"
            placeholder="Price"
            value={memberTypes.find((type) => type.id === Number(member.memberTypeId))?.price}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        fullName: (
          <Input
            borderless
            name="fullName"
            placeholder="Full Name"
            value={member.fullName}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        contactNumber: (
          <Input
            borderless
            name="contactNumber"
            placeholder="Contact Number"
            value={member.contactNumber}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        email: (
          <Input
            borderless
            type="email"
            name="email"
            placeholder="Email Address"
            value={member.email}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        idNumber: (
          <Input
            borderless
            name="idNumber"
            placeholder="ID Number"
            value={member.idNumber}
            autoComplete="off"
            onChange={(val) => handleValueChange(index, val)}
          />
        ),
        icon: <RemoveIcon onClick={() => removeRow(member)} />
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form?.members, memberTypes, handleChange]);

  const addNewRow = () => {
    const members = form?.members ?? [];
    members.push(EMPTY_CREW_MEMBER());
    handleChange({ members });
  };

  const removeRow = (type) => {
    const members = (form?.members ?? []).reduce((accum, member) => {
      if (member._id === type._id) {
        member._destroy = '1';
      }

      return [...accum, member];
    }, []);
    handleChange({ members });
  };

  return (
    <article className="crew-members">
      <h4>Add New Crew Member</h4>
      <p>
        Please add the details of the member(s) that will be accompanying {entrant.full_name} to the
        event.
      </p>

      <section className="crew-members__table">
        <Table cols={memberCols} rowData={mappedData} />
      </section>
      <footer className="crew-members__actions">
        <AddAction title="Add New Member" onClick={addNewRow} />
      </footer>
    </article>
  );
};

export default EntryForms;
