import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  CREATE_DEPENDANT,
  LOAD_DEPENDANT,
  LOAD_DEPENDANTS,
  loadDependant,
  loadDependants,
  REMOVE_DEPENDANT,
  REMOVE_SPORT,
  SET_DEPENDANT,
  SET_DEPENDANTS,
  UPDATE_DEPENDANT
} from '../../actions/dependant/dependant.action';

import {
  getCreateDependantRequest,
  getLoadDependantRequest,
  getLoadDependantsRequest,
  getRemoveDependantRequest,
  getRemoveSportRequest,
  getUpdateDependantRequest
} from '../../tools/api/dependants.endpoints';

export function* performLoadDependants({ id }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadDependantsRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_DEPENDANTS, dependants: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForLoadDependantsRequest() {
  yield takeLatest(LOAD_DEPENDANTS, performLoadDependants);
}

export function* performCreateDependant({ id, dependant, navigate }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getCreateDependantRequest(id, dependant);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your dependant has been saved successfully.', SNACK_SUCCESS));

    yield put(loadDependants(id));
    if (navigate) {
      yield call(navigate, '/dependants');
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForCreateDependantRequest() {
  yield takeLatest(CREATE_DEPENDANT, performCreateDependant);
}

export function* performLoadDependant({ id, dependantId, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadDependantRequest(id, dependantId);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_DEPENDANT, dependant: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadDependantRequest() {
  yield takeLatest(LOAD_DEPENDANT, performLoadDependant);
}

export function* performUpdateDependant({
  id,
  dependantId,
  payload,
  onSuccess = noOp,
  onComplete = noOp
}) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateDependantRequest(id, dependantId, payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your dependants details were updated successfully.', SNACK_SUCCESS));

    yield put({ type: SET_DEPENDANT, dependant: data });

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateDependantRequest() {
  yield takeLatest(UPDATE_DEPENDANT, performUpdateDependant);
}

export function* performRemoveDependant({ id, dependantId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveDependantRequest(id, dependantId);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your dependant has been removed successfully.', SNACK_SUCCESS));

    yield put(loadDependants(id));
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForRemoveDependantRequest() {
  yield takeLatest(REMOVE_DEPENDANT, performRemoveDependant);
}

export function* performRemoveSport({ id, dependantId, sportId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveSportRequest(dependantId, sportId);

    // make the request, retrieve the response
    yield call(axios, endpoint, requestOptions);

    // load profile
    yield put(loadDependant(id, dependantId));
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to remove sport.', SNACK_CRITICAL));
  }
}

export function* watchForRemoveSportRequest() {
  yield takeLatest(REMOVE_SPORT, performRemoveSport);
}

export default function* dependantSaga() {
  yield all([
    watchForLoadDependantsRequest(),
    watchForCreateDependantRequest(),
    watchForLoadDependantRequest(),
    watchForUpdateDependantRequest(),
    watchForRemoveDependantRequest(),
    watchForRemoveSportRequest()
  ]);
}
