import React from 'react';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '@neslotech/utils';

import PublicEventCard from '../card/PublicEventCard';
import EmptyMessage from '../empty/EmptyMessage';

import './events-list.scss';

const emptyMessage =
  'There we no events or competitions matching your search. Please try clear your filters and search again';
const EventsList = ({ events }) => {
  const navigate = useNavigate();

  return (
    <article className="events-list">
      {events.map((item) => (
        <PublicEventCard
          key={`${item.type}_${item.id}`}
          onClick={() => navigate(`${item.id}?type=${item.type}`)}
          entity={item}
          type={item.type}
        />
      ))}
      {isEmpty(events) && <EmptyMessage type="result" message={emptyMessage} />}
    </article>
  );
};

export default EventsList;
