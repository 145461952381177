import { API_HOST } from './index';

import { getHttpGetOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

const getSportsEndpoint = () => `${API_HOST}/sports`;
export const getLoadSportsRequest = () => [
  getSportsEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];
