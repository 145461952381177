import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { resetPassword, validateResetToken } from '../../../actions/auth/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import ResetPassword from '../../../components/auth/reset-password/ResetPassword';

const ResetPasswordContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(validateResetToken(token, navigate));
  }, [dispatch, token, navigate]);

  const onResetPassword = (payload) => {
    setLoading(true);
    dispatch(resetPassword(token, payload, navigate, () => setLoading(false)));
  };

  return (
    <AuthLayout title="Create New Password" logo={false}>
      <ResetPassword loading={loading} onResetPassword={onResetPassword} />
    </AuthLayout>
  );
};

export default ResetPasswordContainer;
