import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  REMOVE_SPORT,
  RETRIEVE_PROFILE,
  retrieveProfile,
  SET_PROFILE,
  UPDATE_PROFILE,
  UPDATE_PUBLIC_PROFILE
} from '../../actions/profile/profile.action';

import {
  getLoadProfileRequest,
  getRemoveSportRequest,
  getUpdateProfileRequest,
  getUpdatePublicProfileRequest
} from '../../tools/api/profile.endpoints';

export function* performRetrieveProfile({ onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadProfileRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_PROFILE, profile: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForRetrieveProfileRequest() {
  yield takeLatest(RETRIEVE_PROFILE, performRetrieveProfile);
}

export function* performUpdateProfile({ profile, onSuccess = noOp, onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateProfileRequest(profile);

    // make the request, retrieve the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Your profile has been updated.', SNACK_SUCCESS));

    // set profile on the state
    yield put({ type: SET_PROFILE, profile: data });

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to update my profile.', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateProfileRequest() {
  yield takeLatest(UPDATE_PROFILE, performUpdateProfile);
}

export function* performUpdatePublicProfile({ token, profile, onSuccess, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdatePublicProfileRequest(token, profile);

    // make the request, retrieve the response
    yield call(axios, endpoint, requestOptions);

    // load profile
    yield put(retrieveProfile());

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(
      addSystemNotice(response?.data?.error ?? 'Failed to update my profile.', SNACK_CRITICAL)
    );
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForUpdatePublicProfileRequest() {
  yield takeLatest(UPDATE_PUBLIC_PROFILE, performUpdatePublicProfile);
}

export function* performRemoveSport({ id, sportId }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getRemoveSportRequest(id, sportId);

    // make the request, retrieve the response
    yield call(axios, endpoint, requestOptions);

    // load profile
    yield put(retrieveProfile());
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to remove sport.', SNACK_CRITICAL));
  }
}

export function* watchForRemoveSportRequest() {
  yield takeLatest(REMOVE_SPORT, performRemoveSport);
}

export default function* profileSaga() {
  yield all([
    watchForRetrieveProfileRequest(),
    watchForUpdateProfileRequest(),
    watchForUpdatePublicProfileRequest(),
    watchForRemoveSportRequest()
  ]);
}
