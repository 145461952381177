import React from 'react';

import PublicLayout from '../../../../components/layout/public/PublicLayout';
import EntryWizard from '../../../../components/wizard/EntryWizard';
import WizardProvider from '../../../../providers/Wizard.provider';

const EnterEventContainer = () => (
  <PublicLayout full>
    <WizardProvider>
      <EntryWizard />
    </WizardProvider>
  </PublicLayout>
);

export default EnterEventContainer;
