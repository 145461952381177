import React from 'react';

import { DetailsSection } from '@neslotech/eventhub-ui-kit';

import SportSelection from '../SportSelection';

const AddSport = ({ sports, onSave }) => (
  <DetailsSection
    title="Add a new sport"
    subtitle="Select a sport from the list that you would like to add to your profile"
    noCard
  >
    <article className="add-sport">
      <SportSelection sports={sports} skippable={false} onSave={onSave} />
    </article>
  </DetailsSection>
);

export default AddSport;
