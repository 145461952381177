import React from 'react';

import { Button } from '@neslotech/eventhub-ui-kit';

import './waiting-banner.scss';

const WaitingBanner = () => (
  <article className="waiting-banner">
    <h2>What are you waiting for?</h2>
    <Button
      longText
      label="Become an Organiser"
      onClick={() => (window.location.href = 'https://organiser.event-hub.co.za')}
    />
  </article>
);

export default WaitingBanner;
