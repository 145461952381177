import React, { createRef, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Accordion,
  Button,
  ButtonGroup,
  FileUploadGroup,
  Tabs,
  toBase64
} from '@neslotech/eventhub-ui-kit';

import { useWizard } from '../../../hooks/useWizard';

// TODO: include once payment methods are available
// import { ReactComponent as CardIcon } from '../../../icons/card-icon.svg';
// import { ReactComponent as OzowIcon } from '../../../icons/ozow-icon.svg';
// import { ReactComponent as PayfastIcon } from '../../../icons/payfast-icon.svg';
import { ReactComponent as EftIcon } from '../../../icons/eft-icon.svg';
import { ReactComponent as EftImage } from '../../../images/eft-image.svg';

import EventDescription from '../../events/details/EventDescription';

import './payment.scss';

const CARD = 'card';
const OZOW = 'ozow';
const PAYFAST = 'payfast';
const EFT = 'eft';

const tabs = (setActiveMethod) => [
  // TODO: implement other payment methods
  // {
  //   key: CARD,
  //   label: 'Credit/Debit Card',
  //   icon: <CardIcon />,
  //   onClick: () =>
  //     setActiveMethod({ key: CARD, label: 'Credit/Debit Card', component: CardPayment })
  // },
  // {
  //   key: OZOW,
  //   label: 'EFT With Ozow',
  //   icon: <OzowIcon />,
  //   onClick: () => setActiveMethod({ key: OZOW, label: 'EFT With Ozow', component: OzowPayment })
  // },
  // {
  //   key: PAYFAST,
  //   label: 'EFT With Payfast',
  //   icon: <PayfastIcon />,
  //   onClick: () =>
  //     setActiveMethod({ key: PAYFAST, label: 'EFT With Payfast', component: PayfastPayment })
  // },
  {
    key: EFT,
    label: 'EFT',
    icon: <EftIcon />,
    onClick: () => setActiveMethod({ key: EFT, label: 'EFT', component: EftPayment })
  }
];

// TODO: include once payment methods are available
// const rules = {
//   validates: {
//     nameOnCard: ['isPresent'],
//     cardNumber: ['isPresent'],
//     cardType: ['isPresent'],
//     expiryMonth: ['isPresent'],
//     expiryYear: ['isPresent'],
//     cvc: ['isPresent', 'is3Digits']
//   },
//
//   is3Digits(value) {
//     if (value.length !== 3) {
//       return 'Must be 3 digits in length.';
//     }
//   }
// };
//
// const CardPayment = () => {
//   const [form, setForm] = useFormState(undefined, rules);
//   const handleChange = (newState) => setForm({ ...form, ...newState });
//
//   return (
//     <article className="card-payment">
//       <main>
//         <Form>
//           <FormRow>
//             <Input
//               name="nameOnCard"
//               placeholder="Enter Name on Card"
//               label="Name on card"
//               value={form?.nameOnCard}
//               error={form?.messages?.nameOnCard}
//               onChange={handleChange}
//             />
//           </FormRow>
//           <FormRow>
//             <Input
//               name="cardNumber"
//               placeholder="Enter Card Number"
//               label="Card Number"
//               value={form?.cardNumber}
//               error={form?.messages?.cardNumber}
//               onChange={handleChange}
//             />
//           </FormRow>
//           <FormRow fluidSpaced>
//             <Input
//               name="cardType"
//               disabled
//               placeholder="Card type"
//               label="Card Type"
//               value={form?.cardType}
//               error={form?.messages?.cardType}
//               onChange={handleChange}
//             />
//             <section className="card-payment__types">
//               <article
//                 className="card-payment__type"
//                 onClick={() => handleChange({ cardType: 'Visa' })}
//               >
//                 <VisaImage />
//               </article>
//               <article
//                 className="card-payment__type"
//                 onClick={() => handleChange({ cardType: 'Mastercard' })}
//               >
//                 <MastercardImage />
//               </article>
//             </section>
//           </FormRow>
//           <FormRow fluidSpaced>
//             <Input
//               min="1"
//               max="12"
//               name="expiryMonth"
//               type="number"
//               placeholder="What Month?"
//               label="Expiry Date"
//               value={form?.expiryMonth}
//               error={form?.messages?.expiryMonth}
//               onChange={handleChange}
//             />
//             <Input
//               min="1900"
//               max="2999"
//               name="expiryYear"
//               type="number"
//               placeholder="What Year?"
//               label=""
//               value={form?.expiryYear}
//               error={form?.messages?.expiryYear}
//               onChange={handleChange}
//             />
//           </FormRow>
//           <FormRow singleRow>
//             <Input
//               name="cvc"
//               placeholder="CVC"
//               label="CVC Number"
//               onChange={handleChange}
//               value={form?.cvc}
//               error={form?.messages?.cvc}
//             />
//           </FormRow>
//         </Form>
//       </main>
//     </article>
//   );
// };
//
// const OzowPayment = () => (
//   <article className="ozow-payment">
//     <header>
//       <OzowImage />
//     </header>
//     <main>
//       <p>Ozow lets you pay for your order by EFT instantly and securely.</p>
//
//       <p>How does it work?</p>
//
//       <ol>
//         <li>Click on "Pay with Ozow"</li>
//         <li>Select your bank and log in</li>
//         <li>Select the bank account you’d like to use and click "Pay"</li>
//         <li>
//           Your bank will send you an OTP (One Time Pin) or mobile authentication message to confirm
//         </li>
//         <li>Enter the OTP or accept authentication message to finalise payment</li>
//         <li>Once complete we’ll confirm your order.</li>
//       </ol>
//
//       <p>
//         Ozow supports ABSA, Capitec, FNB, Nedbank, Standard Bank, Bidvest Bank, Tymebank, Africa
//         Bank and Investec payments.
//       </p>
//     </main>
//   </article>
// );
//
// const PayfastPayment = () => (
//   <article className="payfast-payment">
//     <header>
//       <PayfastImage />
//     </header>
//     <main>
//       <p>PayFast allows you to pay for your order by EFT instantly and securely.</p>
//       <p>It’s available to FNB, Standard Bank, ABSA, Capitec, Nedbank and Investec customers.</p>
//
//       <p>How does it work?</p>
//
//       <ol>
//         <li>Click on "Pay with Payfast"</li>
//         <li>Select your bank and log in</li>
//         <li>Your bank will verify your bank log in request</li>
//         <li>Choose the bank account you’d like to use and click "Pay"</li>
//       </ol>
//
//       <p>Once complete we’ll confirm your order.</p>
//     </main>
//   </article>
// );

const EftPayment = ({ total, reference, onComplete }) => (
  <article className="eft-payment">
    <header>
      <EftImage />
    </header>
    <main>
      <p>
        Please send proof of payment to payments@event-hub.co.za or admin@gxccracing.co.za within 24
        hours. Please ensure you use the generated <span>Entry reference number</span> when paying
        so that your payment can be verified.
      </p>
      <p>Banking Details</p>
      <ul>
        <li>
          <span>Bank:</span> First National Bank
        </li>
        <li>
          <span>Branch Code:</span> 250655
        </li>
        <li>
          <span>Account Number:</span> 62931438916
        </li>
        <li>
          <span>Account Type:</span> Savings
        </li>
        <li>
          <span>Reference:</span> {reference}
        </li>
        <li>
          <span>Amount:</span> {total}
        </li>
      </ul>
      <p>Please NOTE that we DO NOT accept international FOREX payments.</p>
      <em>
        All entries with the incorrect reference will be charged an admin fee to manually allocate
        the payment.
      </em>
    </main>
    <footer className="eft-payment__actions">
      <Button label="Complete Entry" onClick={onComplete} />
    </footer>
  </article>
);

const Payment = () => {
  const { context, stateEntry, onCompleteEntry } = useWizard();

  const [activeMethod, setActiveMethod] = useState({
    key: EFT,
    label: 'EFT',
    component: EftPayment
  });
  const [showUpload, setShowUpload] = useState(false);

  const Component = useMemo(() => {
    return activeMethod.component;
  }, [activeMethod]);

  const handleComplete = () => {
    onCompleteEntry({ payment_method: activeMethod.key }, () => {
      if (activeMethod.key === EFT) {
        setShowUpload(true);
      }
    });
  };

  return (
    <article className="payment-method">
      <h2>Payment Method</h2>
      {context && <EventDescription description={false} event={context} />}
      <p className="payment-method__reference">
        Entry reference number: {stateEntry?.reference_number}
      </p>
      <h4>Which of our safe payment options would you like to use?</h4>
      <section className="payment-method__methods">
        <Tabs activeTab={activeMethod.key} tabs={tabs(setActiveMethod)} />
        <p>
          To finalise your order, you may be guided to your bank's 3D secure process to authenticate
          your details.
        </p>
        <section className="payment-method__details">
          {showUpload && <UploadProofOfPayment />}
          {!showUpload && (
            <Accordion title={activeMethod.label} open>
              <Component
                reference={stateEntry?.reference_number}
                total={stateEntry?.pricing_model?.total_cost}
                onComplete={handleComplete}
              />
            </Accordion>
          )}
          <EntrySummary entry={stateEntry} activeMethod={activeMethod} />
        </section>
      </section>
    </article>
  );
};

const UploadProofOfPayment = () => {
  const { onUploadProofOfPayment } = useWizard();

  const fileRef = createRef();
  const [proofOfPayment, setProofOfPayment] = useState();

  const handleFileChange = async (file) => {
    const data = await toBase64(file);
    setProofOfPayment({ proof_of_payment: data });
  };

  const handleUploadProofOfPayment = () => {
    onUploadProofOfPayment(proofOfPayment);
  };

  const description = (
    <section className="payment-method__upload">
      <p>Upload your proof of payment to complete your entry</p>
      <Button label="Select File" onClick={() => fileRef.current.click()} />
    </section>
  );

  return (
    <Accordion title="Upload Proof of Payment" open>
      <FileUploadGroup
        onChange={handleFileChange}
        onClick={() => fileRef.current.click()}
        fileTypes={['application/pdf']}
        fileRef={fileRef}
        description={
          proofOfPayment ? 'Upload your proof of payment to complete your entry' : description
        }
        label="Upload Proof of Payment"
      />
      <footer className="payment-method__actions">
        {proofOfPayment && (
          <Button longText label="Upload Proof of Payment" onClick={handleUploadProofOfPayment} />
        )}
      </footer>
    </Accordion>
  );
};

const EntrySummary = ({ entry, activeMethod }) => {
  const navigate = useNavigate();

  const actionTitle = useMemo(() => {
    switch (activeMethod.key) {
      case CARD:
        return 'Pay with Card';
      case OZOW:
        return 'Pay with Ozow';
      case PAYFAST:
        return 'Pay with Payfast';
      default:
        return null;
    }
  }, [activeMethod]);

  const mappedClasses = useMemo(() => {
    return (
      (entry?.pricing_model?.entrant_pricing_list ?? [])
        // collect all classes
        .reduce((accum, item) => {
          accum.push(...item.class_pricing);
          return accum;
        }, [])
        // flatten object graph to make it easier to sum
        .map((item) => ({
          name: item.competition_class.name,
          price: Number(item.price.replace('R', ''))
        }))
        // sort into groups of summed prices and unique names
        .reduce((accum, item) => {
          const matchingClass = accum.find((klass) => klass.name === item.name);
          if (matchingClass) {
            matchingClass.price += item.price;
          } else {
            accum.push(item);
          }

          return accum;
        }, [])
    );
  }, [entry]);

  const mappedMembers = useMemo(() => {
    return (
      (entry?.pricing_model?.entrant_pricing_list ?? [])
        // collect all classes
        .reduce((accum, item) => {
          accum.push(...item.member_pricing);
          return accum;
        }, [])
        // flatten object graph to make it easier to sum
        .map((item) => ({
          name: item.member_type.name,
          price: Number(item.price.replace('R', ''))
        }))
        // sort into groups of summed prices and unique names
        .reduce((accum, item) => {
          const matchingMember = accum.find((klass) => klass.name === item.name);
          if (matchingMember) {
            matchingMember.price += item.price;
          } else {
            accum.push(item);
          }

          return accum;
        }, [])
    );
  }, [entry]);

  return (
    <article className="entry-summary">
      <h4>Your Entry</h4>
      <header>
        <span>Class</span>
        <span>Price</span>
      </header>
      <ul>
        {mappedClasses.map((item) => (
          <li key={item.name}>
            <span>{item.name}</span>
            <span>R{item.price.toFixed(2)}</span>
          </li>
        ))}
        {mappedMembers.map((item) => (
          <li key={item.name}>
            <span>{item.name}</span>
            <span>R{item.price.toFixed(2)}</span>
          </li>
        ))}
        <hr />
        <li className="entry-summary__total-item">
          <span>Subtotal</span>
          <span>{entry?.pricing_model?.subtotal_cost}</span>
        </li>
        <li className="entry-summary__total-item">
          <span>Total</span>
          <span>{entry?.pricing_model?.total_cost}</span>
        </li>
      </ul>
      {actionTitle && (
        <footer>
          <ButtonGroup>
            <Button label={actionTitle} />
            <Button label="Cancel" secondary onClick={() => navigate('/events')} />
          </ButtonGroup>
        </footer>
      )}
    </article>
  );
};

export default Payment;
