import {
  SET_CLASSES,
  SET_ENTRIES,
  SET_ENTRY,
  SET_ENTRY_FORM,
  SET_EVENT,
  SET_EVENTS,
  SET_MEMBER_TYPES,
  SET_TRENDING
} from '../../actions/event/event.action';

export const initialState = {
  events: [],
  entries: [],
  event: undefined,
  entry: undefined,
  entryForm: undefined,
  classes: undefined
};

export function eventReducer(state = initialState, action) {
  switch (action.type) {
    case SET_EVENTS:
      const { events } = action;
      return {
        ...state,
        events
      };
    case SET_TRENDING:
      const { trending } = action;
      return {
        ...state,
        trending
      };
    case SET_EVENT:
      const { event } = action;
      return {
        ...state,
        event
      };
    case SET_ENTRY:
      const { entry } = action;
      return {
        ...state,
        entry
      };
    case SET_ENTRY_FORM:
      const { entryForm } = action;
      return {
        ...state,
        entryForm
      };
    case SET_MEMBER_TYPES:
      const { memberTypes } = action;
      return {
        ...state,
        memberTypes
      };
    case SET_CLASSES:
      const { classes } = action;
      return {
        ...state,
        classes
      };
    case SET_ENTRIES:
      const { entries } = action;
      return {
        ...state,
        entries
      };
    default:
      return state;
  }
}
