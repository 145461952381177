import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import 'react-datepicker/dist/react-datepicker.css';

import authSaga from './sagas/auth/auth.saga';
import profileSaga from './sagas/profile/profile.saga';
import dependantSaga from './sagas/dependant/dependant.saga';
import competitionSaga from './sagas/competition/competition.saga';
import eventSaga from './sagas/event/event.saga';
import sportSaga from './sagas/sport/sport.saga';

import App from './App';
import reportWebVitals from './reportWebVitals';
import rootReducer from './reducers';

import './index.scss';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(authSaga);
sagaMiddleware.run(profileSaga);
sagaMiddleware.run(dependantSaga);
sagaMiddleware.run(competitionSaga);
sagaMiddleware.run(eventSaga);
sagaMiddleware.run(sportSaga);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
