import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Button,
  DetailsSection,
  DocumentDetail,
  isMobile,
  Loader,
  SearchInput,
  Table
} from '@neslotech/eventhub-ui-kit';
import { isEmpty } from '@neslotech/utils';

import { useAuth } from '../../../hooks/useAuth';

import defaultImage from '../../../images/details-default-image.svg';
import { ReactComponent as InfoIcon } from '../../../icons/information-icon.svg';

import EventDescription from './EventDescription';

import './event-details.scss';

const EventDetails = ({ loadingEntries, event, entries }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { token } = useAuth();

  const type = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('type');
  }, [search]);

  const handleViewDocument = (document) => {
    window.open(document.src, '_blank');
  };

  return (
    <article className="event-details">
      {!isMobile() && (
        <section className="event-details__context">
          <img alt={event.name} src={event.image ?? defaultImage} />
          <footer>
            <Button
              disabled={!token || !event.can_enter}
              longText
              label={`Enter ${type}`}
              onClick={() => navigate(`enter?type=${type}`)}
            />
            {!token && event.can_enter && (
              <article>
                <InfoIcon />
                <p>
                  You cannot enter for this event if you have not registered as a user. If you have
                  already registered then login to enter this event.
                </p>
              </article>
            )}
          </footer>
        </section>
      )}
      <section className="event-details__detail">
        <EventDescription event={event} />
        {!isEmpty(event.additional_logos) && (
          <section className="event-details__logos">
            {event.additional_logos.map((logo) => (
              <img key={logo.id} src={logo.src} alt={logo.name} />
            ))}
          </section>
        )}
        {!isEmpty(event.supporting_documents) && (
          <DetailsSection
            title="Supporting Documents"
            subtitle="Please read and understand the following documents before entering."
            noCard
          >
            <section className="event-details__documents">
              {event.supporting_documents.map((document) => (
                <DocumentDetail
                  key={document.id}
                  name={document.name}
                  onView={() => handleViewDocument(document)}
                />
              ))}
            </section>
          </DetailsSection>
        )}
        {isMobile() && (
          <section className="event-details__context">
            <img alt={event.name} src={event.image ?? defaultImage} />
            <footer>
              <Button
                disabled={!token || !event.can_enter}
                longText
                label={`Enter ${type}`}
                onClick={() => navigate(`enter?type=${type}`)}
              />
              {!token && event.can_enter && (
                <article>
                  <InfoIcon />
                  <p>
                    You cannot enter for this event if you have not registered as a user. If you
                    have already registered then login to enter this event.
                  </p>
                </article>
              )}
            </footer>
          </section>
        )}
        <ClassesAndPricing type={type} event={event} />
        {event.visible_entries && (
          <ParticipantsAttending loading={loadingEntries} event={event} entries={entries} />
        )}
      </section>
    </article>
  );
};

const eventClassesCols = [
  { Header: 'Class Name', accessor: 'name' },
  { Header: '1st Class', accessor: 'first_class_price' },
  { Header: '2nd Class', accessor: 'second_class_price' },
  { Header: '1st Class (Family Member)', accessor: 'first_class_family_price' },
  { Header: '2nd Class (Family Member)', accessor: 'second_class_family_price' }
];

const mobileEventClassesCols = [
  { Header: 'Class', accessor: 'name', disableSortBy: true },
  { Header: '1st Class', accessor: 'first_class_price', disableSortBy: true },
  { Header: '2nd Class', accessor: 'second_class_price', disableSortBy: true }
];

const classesCols = [
  { Header: 'Class Name', accessor: 'name' },
  { Header: '1st Class', accessor: 'first_class_price' },
  { Header: '2nd Class', accessor: 'second_class_price' },
  { Header: '1st Class (Family Member)', accessor: 'first_class_family_price' },
  { Header: '2nd Class (Family Member)', accessor: 'second_class_family_price' }
];

const mobileClassesCols = [
  { Header: 'Class', accessor: 'name', disableSortBy: true },
  { Header: '1st Class', accessor: 'first_class_price', disableSortBy: true },
  { Header: '2nd Class', accessor: 'second_class_price', disableSortBy: true }
];

const ClassesAndPricing = ({ type, event }) => {
  const cols = useMemo(() => {
    return type === 'Competition'
      ? isMobile()
        ? mobileClassesCols
        : classesCols
      : isMobile()
      ? mobileEventClassesCols
      : eventClassesCols;
  }, [type]);

  return (
    <article className="event-details__classes">
      <h5>Classes and Pricing</h5>
      <Table cols={cols} rowData={event.classes} />
    </article>
  );
};

const attendingCols = [
  {
    accessor: 'name',
    Header: 'Participant'
  },
  {
    accessor: 'class',
    Header: 'Class'
  },
  {
    accessor: 'entry_date',
    Header: 'Date Entered'
  }
];

const mobileAttendingCols = [
  {
    accessor: 'name',
    Header: 'Participant',
    disableSortBy: true
  },
  {
    accessor: 'class',
    Header: 'Class',
    disableSortBy: true
  }
];

const ParticipantsAttending = ({ loading, entries }) => {
  const [search, setSearch] = useState('');

  const rowData = useMemo(() => {
    return (entries ?? [])
      .map((entry) => ({
        entrants: entry.entrants,
        entry_date: entry.entry_date,
        class_selections: entry.class_selections
      }))
      .reduce((accum, entry) => {
        const entrants = entry.entrants.map((entrant) => {
          const className = entry.class_selections
            .filter((selection) => selection.entrant_id === entrant.id)
            .map((selection) => selection.competition_class_name)
            .join(', ');

          return {
            name: entrant.full_name,
            entry_date: entry.entry_date,
            class: className
          };
        });

        return [...accum, ...entrants];
      }, []);
  }, [entries]);

  const filteredRows = useMemo(() => {
    if (!search) {
      return rowData;
    }

    return rowData.filter((item) => item.name.includes(search));
  }, [rowData, search]);

  return (
    <article className="event-details__attending">
      <h5>Participants Attending</h5>
      <SearchInput placeholder="Search for a participant..." value={search} onChange={setSearch} />
      {loading && <Loader filled />}
      {!loading && (
        <Table cols={isMobile() ? mobileAttendingCols : attendingCols} rowData={filteredRows} />
      )}
    </article>
  );
};

export default EventDetails;
