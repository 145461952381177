import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { scrollIntoView } from '@neslotech/utils';

import {
  completeEntry as completeCompetitionEntry,
  loadClasses as loadCompetitionClasses,
  loadCompetition,
  loadEntry as loadCompetitionEntry,
  loadEntryForm as loadCompetitionEntryForm,
  loadMemberTypes as loadCompetitionMemberTypes,
  saveEntry as saveCompetitionEntry,
  updateEntry as updateCompetitionEntry,
  uploadProofOfPayment as uploadCompetitionProofOfPayment
} from '../actions/competition/competition.action';
import {
  completeEntry as completeEventEntry,
  loadClasses as loadEventClasses,
  loadEntry as loadEventEntry,
  loadEntryForm as loadEventEntryForm,
  loadEvent,
  loadMemberTypes as loadEventMemberTypes,
  saveEntry as saveEventEntry,
  updateEntry as updateEventEntry,
  uploadProofOfPayment as uploadEventProofOfPayment
} from '../actions/event/event.action';
import { retrieveProfile, updateProfile } from '../actions/profile/profile.action';
import { loadDependants } from '../actions/dependant/dependant.action';
import { loadSports } from '../actions/sport/sport.actions';

import { WizardContext } from '../contexts/Wizard.context';

import EntrantDetails from '../components/entry/details/EntrantDetails';
import EntryForms from '../components/entry/entry-forms/EntryForms';
import ClassSelection from '../components/entry/class-selection/ClassSelection';
import Overview from '../components/entry/overview/Overview';
import Payment from '../components/entry/payment/Payment';

const stepsWithForms = [
  {
    index: 1,
    title: 'Start',
    component: EntrantDetails
  },
  {
    index: 2,
    title: 'Entry Forms',
    component: EntryForms
  },
  {
    index: 3,
    title: 'Class Selection',
    component: ClassSelection
  },
  {
    index: 4,
    title: 'Overview',
    component: Overview
  },
  {
    index: 5,
    title: 'Payment',
    component: Payment
  }
];

const stepsWithoutForms = [
  {
    index: 1,
    title: 'Start',
    component: EntrantDetails
  },
  {
    index: 2,
    title: 'Class Selection',
    component: ClassSelection
  },
  {
    index: 3,
    title: 'Overview',
    component: Overview
  },
  {
    index: 4,
    title: 'Payment',
    component: Payment
  }
];

const WizardProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();

  const competition = useSelector(({ competition_store }) => competition_store.competition);
  const competitionEntryForm = useSelector(({ competition_store }) => competition_store.entryForm);
  const competitionMemberTypes = useSelector(
    ({ competition_store }) => competition_store.memberTypes
  );
  const competitionClasses = useSelector(({ competition_store }) => competition_store.classes);
  const competitionEntry = useSelector(({ competition_store }) => competition_store.entry);
  const competitionStateLoading = useSelector(({ competition_store }) => competition_store.loading);
  const event = useSelector(({ event_store }) => event_store.event);
  const eventEntryForm = useSelector(({ event_store }) => event_store.entryForm);
  const eventMemberTypes = useSelector(({ event_store }) => event_store.memberTypes);
  const eventClasses = useSelector(({ event_store }) => event_store.classes);
  const eventEntry = useSelector(({ event_store }) => event_store.entry);
  const eventStateLoading = useSelector(({ competition_store }) => competition_store.loading);
  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const dependants = useSelector(({ dependant_store }) => dependant_store.dependants);
  const sports = useSelector(({ sport_store }) => sport_store.sports);

  const [loading, setLoading] = useState(competitionStateLoading ?? eventStateLoading ?? true);
  const [activeStep, setActiveStep] = useState(1);
  const [entry, setEntry] = useState();

  useEffect(() => {
    dispatch(retrieveProfile());
    dispatch(loadSports());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile?.id) {
      dispatch(loadDependants(profile.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.id]);

  const type = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('type');
  }, [search]);

  const entity = useMemo(() => {
    return type === 'Competition' ? competition : event;
  }, [type, competition, event]);

  const entryForm = useMemo(() => {
    return type === 'Competition' ? competitionEntryForm : eventEntryForm;
  }, [type, competitionEntryForm, eventEntryForm]);

  const memberTypes = useMemo(() => {
    return type === 'Competition' ? competitionMemberTypes : eventMemberTypes;
  }, [type, competitionMemberTypes, eventMemberTypes]);

  const classes = useMemo(() => {
    return type === 'Competition' ? competitionClasses : eventClasses;
  }, [type, competitionClasses, eventClasses]);

  const stateEntry = useMemo(() => {
    return type === 'Competition' ? competitionEntry : eventEntry;
  }, [type, eventEntry, competitionEntry]);

  const sport = useMemo(() => {
    return (sports ?? []).find((sport) => sport.id === entity?.sport_id);
  }, [entity?.sport_id, sports]);

  useEffect(() => {
    const updatedEntry = { ...entry };
    (stateEntry?.entrants ?? []).forEach((entrant) => {
      const matchingEntrant = (updatedEntry?.entrants_attributes ?? []).find(
        (item) => item.email === entrant.email && !item.id
      );
      if (matchingEntrant) {
        updatedEntry?.entrant_ids.push(entrant.id);
        matchingEntrant.id = entrant.id;
        setEntry(updatedEntry);
      }
    });
  }, [entry, stateEntry]);

  useEffect(() => {
    if (type === 'Competition') {
      dispatch(loadCompetition(id, () => setLoading(false)));
      dispatch(loadCompetitionEntryForm(id));
      dispatch(loadCompetitionMemberTypes(id));
      dispatch(loadCompetitionClasses(id));
    } else {
      dispatch(loadEvent(id, () => setLoading(false)));
      dispatch(loadEventEntryForm(id));
      dispatch(loadEventMemberTypes(id));
      dispatch(loadEventClasses(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  const steps = useMemo(() => {
    if (type === 'Competition') {
      return competitionEntryForm ? stepsWithForms : stepsWithoutForms;
    } else {
      return eventEntryForm ? stepsWithForms : stepsWithoutForms;
    }
  }, [type, competitionEntryForm, eventEntryForm]);

  const handleUpdateProfile = (payload) => {
    dispatch(updateProfile(payload));
  };

  const handleEntryChange = (newState, previous = false) => {
    setEntry({ ...entry, ...newState });
    handleSaveEntry({ ...entry, ...newState }, previous);
  };

  const handleSaveEntry = (payload, previous) => {
    if (type === 'Competition') {
      if (entry?.id) {
        dispatch(
          updateCompetitionEntry(id, entry.id, payload, () =>
            handleStepChange(activeStep + (previous ? -1 : 1))
          )
        );
      } else {
        dispatch(
          saveCompetitionEntry(id, payload, (id) => {
            handleStepChange(activeStep + (previous ? -1 : 1));
            setEntry({ ...payload, id });
          })
        );
      }
    } else {
      if (entry?.id) {
        dispatch(
          updateEventEntry(id, entry.id, payload, () =>
            handleStepChange(activeStep + (previous ? -1 : 1))
          )
        );
      } else {
        dispatch(
          saveEventEntry(id, payload, (id) => {
            handleStepChange(activeStep + (previous ? -1 : 1));
            setEntry({ ...payload, id });
          })
        );
      }
    }
  };

  const handleCompleteEntry = (payload, onSuccess) => {
    if (type === 'Competition') {
      dispatch(completeCompetitionEntry(id, entry?.id, payload, onSuccess));
    } else {
      dispatch(completeEventEntry(id, entry?.id, payload, onSuccess));
    }
  };

  const handleUploadProofOfPayment = (payload) => {
    if (type === 'Competition') {
      dispatch(
        uploadCompetitionProofOfPayment(id, entry?.id, payload, () =>
          navigate(`/events/${id}?type=${type}`)
        )
      );
    } else {
      dispatch(
        uploadEventProofOfPayment(id, entry?.id, payload, () =>
          navigate(`/events/${id}?type=${type}`)
        )
      );
    }
  };

  const handleStepChange = (value) => {
    scrollIntoView('root');
    setActiveStep(value);

    if (!entry?.id) {
      return;
    }

    if (type === 'Competition') {
      dispatch(loadCompetitionEntry(entity.id, entry.id, () => setLoading(false)));
    } else {
      dispatch(loadEventEntry(entity.id, entry.id, () => setLoading(false)));
    }
  };

  const value = {
    loading,
    profile,
    dependants,
    entry,
    memberTypes,
    context: entity,
    steps,
    entryForm,
    classes,
    activeStep,
    type,
    stateEntry,
    sport,
    onStepChange: handleStepChange,
    onUpdateProfile: handleUpdateProfile,
    onEntryChange: handleEntryChange,
    onSaveEntry: handleSaveEntry,
    onCompleteEntry: handleCompleteEntry,
    onUploadProofOfPayment: handleUploadProofOfPayment
  };

  return <WizardContext.Provider value={value}>{children}</WizardContext.Provider>;
};

export default WizardProvider;
