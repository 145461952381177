import React from 'react';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import SectionLayout from '../../../components/layout/section/SectionLayout';
import ServiceOfferings from '../../../components/landing-page/service-offerings/ServiceOfferings';

const ServiceOfferingsContainer = () => (
  <PublicLayout>
    <SectionLayout title="Service Offerings">
      <ServiceOfferings />
    </SectionLayout>
  </PublicLayout>
);

export default ServiceOfferingsContainer;
