import React from 'react';

import { Button } from '@neslotech/eventhub-ui-kit';

import SportsImage from '../../../images/sports-image.png';

import './sport-search.scss';

const SportSearch = () => (
  <article className="sport-search">
    <section className="sport-search__details">
      <h1 className="sport-search__title">Find your next adventure</h1>
      <section className="sport-search__sports">
        <Button disabled hollow label="Motocross" />
        <Button disabled hollow label="Hockey" />
        <Button disabled hollow label="Road Running" />
        <Button disabled hollow label="Tennis" />
        <Button disabled hollow label="Go-Karting" />
      </section>
    </section>
    <section className="sport-search__image">
      <img alt="Sports" src={SportsImage} />
    </section>
  </article>
);

export default SportSearch;
