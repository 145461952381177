import React from 'react';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import SectionLayout from '../../../components/layout/section/SectionLayout';
import PrivacyPolicy from '../../../components/privacy-policy/PrivacyPolicy';

const PrivacyPolicyContainer = () => (
  <PublicLayout>
    <SectionLayout title="Privacy Policy">
      <PrivacyPolicy />
    </SectionLayout>
  </PublicLayout>
);

export default PrivacyPolicyContainer;
