import React, { useEffect } from 'react';

import { AddAction, DetailsSection, Form, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { generateId } from '@neslotech/utils';

import Motorbike from '../vehicles/Motorbike';

import '../sports-information.scss';

const EMPTY_MOTORBIKE = () => ({
  _id: generateId(),
  type: 'Vehicles::Motorbike'
});

const formify = (sport) => ({
  sport_id: sport.id,
  type: 'Sports::MotocrossDetail',
  licenseNumber: sport.detail?.license_number,
  sponsors: sport.detail?.sponsors,
  teamName: sport.detail?.team_name,
  vehicles: sport.detail?.vehicles ?? [EMPTY_MOTORBIKE()]
});

const rules = {
  validates: {
    licenseNumber: ['isPresent']
  }
};

const MotocrossForm = ({ editable, sport, handleChange }) => {
  const [form, setForm] = useFormState(formify(sport), rules);
  const handleFormChange = (newState) => {
    setForm({ ...form, ...newState });
    handleChange({ ...form, ...newState });
  };
  const handleVehicleChange = (newVehicleState) => {
    const updatedVehicles = form.vehicles.reduce((accum, vehicle) => {
      if (vehicle._id === newVehicleState._id) {
        vehicle = { ...vehicle, ...newVehicleState };
      }

      accum.push(vehicle);
      return accum;
    }, []);
    handleFormChange({ vehicles: updatedVehicles });
  };

  // combats an issue where the valid flag is set after the change has been propagated
  useEffect(() => {
    handleChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.valid]);

  const addNew = () => {
    handleFormChange({ vehicles: [...form.vehicles, EMPTY_MOTORBIKE()] });
  };

  return (
    <article className="sports-information__form">
      <DetailsSection title="MSA Details" noCard>
        <Form>
          <FormRow singleRow>
            <Input
              disabled={!editable}
              name="licenseNumber"
              placeholder="MSA License Number"
              label="MSA License Number"
              value={form?.licenseNumber}
              error={form?.messages?.licenseNumber}
              onChange={handleFormChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editable}
              name="sponsors"
              placeholder="Sponsors"
              label="Sponsors (separate each by a comma)"
              value={form?.sponsors}
              onChange={handleFormChange}
            />
            <Input
              disabled={!editable}
              name="teamName"
              placeholder="Team Name"
              label="Team Name"
              value={form?.teamName}
              onChange={handleFormChange}
            />
          </FormRow>
        </Form>
      </DetailsSection>
      {form?.vehicles.map((vehicle) => (
        <Motorbike
          editable={editable}
          key={vehicle._id ?? vehicle.id}
          vehicle={vehicle}
          handleChange={handleVehicleChange}
        />
      ))}
      {editable && <AddAction title="Add Another Bike" onClick={addNew} />}
    </article>
  );
};

export default MotocrossForm;
