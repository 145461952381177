import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL } from '@neslotech/utils';

import { addSystemNotice } from '../../actions/system/system.actions';
import {
  COMPLETE_ENTRY,
  LOAD_CLASSES,
  LOAD_COMPETITION,
  LOAD_COMPETITIONS,
  LOAD_ENTRIES,
  LOAD_ENTRY,
  LOAD_ENTRY_FORM,
  LOAD_MEMBER_TYPES,
  LOAD_TRENDING,
  SAVE_ENTRY,
  SET_CLASSES,
  SET_COMPETITION,
  SET_COMPETITIONS,
  SET_ENTRIES,
  SET_ENTRY,
  SET_ENTRY_FORM,
  SET_MEMBER_TYPES,
  SET_TRENDING,
  UPDATE_ENTRY,
  UPLOAD_PROOF_OF_PAYMENT
} from '../../actions/competition/competition.action';

import {
  getCompleteEntryRequest,
  getLoadClassesRequest,
  getLoadCompetitionRequest,
  getLoadCompetitionsRequest,
  getLoadEntriesRequest,
  getLoadEntryFormRequest,
  getLoadEntryRequest,
  getLoadMemberTypesRequest,
  getLoadTrendingRequest,
  getProofOfPaymentRequest,
  getSaveEntriesRequest,
  getUpdateEntryRequest
} from '../../tools/api/competitions.endpoints';

export function* performLoadCompetitions({ onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITIONS, competitions: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadCompetitionsRequest() {
  yield takeLatest(LOAD_COMPETITIONS, performLoadCompetitions);
}

export function* performLoadTrending() {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadTrendingRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_TRENDING, trending: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForLoadTrendingRequest() {
  yield takeLatest(LOAD_TRENDING, performLoadTrending);
}

export function* performLoadCompetition({ id, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadCompetitionRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_COMPETITION, competition: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadCompetitionRequest() {
  yield takeLatest(LOAD_COMPETITION, performLoadCompetition);
}

export function* performLoadEntry({ id, entryId, onComplete }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEntryRequest(id, entryId);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRY, entry: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  } finally {
    if (onComplete) {
      yield call(onComplete);
    }
  }
}

export function* watchForLoadEntryRequest() {
  yield takeLatest(LOAD_ENTRY, performLoadEntry);
}

export function* performSaveEntry({ id, payload, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getSaveEntriesRequest(id, payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRY, entry: data });

    if (onSuccess) {
      yield call(onSuccess, data.id);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForSaveEntryRequest() {
  yield takeLatest(SAVE_ENTRY, performSaveEntry);
}

export function* performUpdateEntry({ id, entryId, payload, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getUpdateEntryRequest(id, entryId, payload);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRY, entry: data });

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForUpdateEntryRequest() {
  yield takeLatest(UPDATE_ENTRY, performUpdateEntry);
}

export function* performLoadEntryForm({ id }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEntryFormRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRY_FORM, entryForm: data.entry_form });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForLoadEntryFormRequest() {
  yield takeLatest(LOAD_ENTRY_FORM, performLoadEntryForm);
}

export function* performLoadMemberTypes({ id }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadMemberTypesRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_MEMBER_TYPES, memberTypes: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForLoadMemberTypesRequest() {
  yield takeLatest(LOAD_MEMBER_TYPES, performLoadMemberTypes);
}

export function* performLoadClasses({ id }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadClassesRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_CLASSES, classes: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForLoadClassesRequest() {
  yield takeLatest(LOAD_CLASSES, performLoadClasses);
}

export function* performLoadEntries({ id, onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadEntriesRequest(id);

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    yield put({ type: SET_ENTRIES, entries: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadEntriesRequest() {
  yield takeLatest(LOAD_ENTRIES, performLoadEntries);
}

export function* performCompleteEntry({ id, entryId, payload, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getCompleteEntryRequest(id, entryId, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForCompleteEntryRequest() {
  yield takeLatest(COMPLETE_ENTRY, performCompleteEntry);
}

export function* performUploadProofOfPaymentEntry({ id, entryId, payload, onSuccess }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getProofOfPaymentRequest(id, entryId, payload);

    // make the request, no need to check the response
    yield call(axios, endpoint, requestOptions);

    yield put(addSystemNotice('Uploaded proof of payment successfully!'));

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch ({ response }) {
    yield put(addSystemNotice(response.data.error, SNACK_CRITICAL));
  }
}

export function* watchForUploadProofOfPaymentRequest() {
  yield takeLatest(UPLOAD_PROOF_OF_PAYMENT, performUploadProofOfPaymentEntry);
}

export default function* competitionSaga() {
  yield all([
    watchForLoadCompetitionsRequest(),
    watchForLoadTrendingRequest(),
    watchForLoadCompetitionRequest(),
    watchForSaveEntryRequest(),
    watchForUpdateEntryRequest(),
    watchForLoadEntryFormRequest(),
    watchForLoadMemberTypesRequest(),
    watchForLoadClassesRequest(),
    watchForLoadEntryRequest(),
    watchForLoadEntriesRequest(),
    watchForCompleteEntryRequest(),
    watchForUploadProofOfPaymentRequest()
  ]);
}
