import React from 'react';

import { Accordion, Button, Checkbox, Datepicker, SearchInput } from '@neslotech/eventhub-ui-kit';

import { useFilters } from '../../../hooks/useFilters';

import { ReactComponent as FilterIcon } from '../../../icons/filter-icon.svg';
import { ReactComponent as TypeIcon } from '../../../icons/type-icon.svg';
import { ReactComponent as DateIcon } from '../../../icons/date-icon.svg';

import './public-filter-layout.scss';

const FilterTitle = ({ Icon, title }) => (
  <article className="public-filter-layout__filter-item">
    <Icon />
    <h6 className="public-filter-layout__filter-title">{title}</h6>
  </article>
);

const PublicFilterLayout = ({ children }) => {
  const { onFilterChange, filters, onSearchChange, search, onClearFilters } = useFilters();

  return (
    <article className="public-filter-layout">
      <aside className="public-filter-layout__filters">
        <header>
          <FilterIcon />
          <h6>Filter</h6>
        </header>
        <Accordion title={<FilterTitle title="Type" Icon={TypeIcon} />} alternate>
          <section className="public-filter-layout__filter-detail">
            <Checkbox
              name="events"
              label="Events"
              checked={filters?.events}
              onChange={onFilterChange}
            />
            <Checkbox
              name="competitions"
              label="Competitions"
              checked={filters?.competitions}
              onChange={onFilterChange}
            />
            <Checkbox
              name="national"
              label="National"
              checked={filters?.national}
              onChange={onFilterChange}
            />
            <Checkbox
              name="regional"
              label="Regional"
              checked={filters?.regional}
              onChange={onFilterChange}
            />
          </section>
        </Accordion>
        {/* TODO: include when locations have been implemented */}
        {/*<Accordion title={<FilterTitle title="Location" Icon={LocationIcon} />} alternate>*/}
        {/*  <section className="public-filter-layout__filter-detail">*/}
        {/*    <Checkbox label="Gauteng" />*/}
        {/*    <Checkbox label="Eastern Cape" />*/}
        {/*    <Checkbox label="Free State" />*/}
        {/*    <Checkbox label="Kwazulu-Natal" />*/}
        {/*    <Checkbox label="Western Cape" />*/}
        {/*  </section>*/}
        {/*</Accordion>*/}
        <Accordion title={<FilterTitle title="Date" Icon={DateIcon} />} alternate>
          <section className="public-filter-layout__filter-detail">
            <Datepicker
              placeholder="Select a date to filter by"
              name="date"
              label="Selected Date"
              value={filters?.date}
              onChange={onFilterChange}
            />
          </section>
        </Accordion>
        <footer className="public-filter-layout__filters-action">
          <Button label="Clear Filters" onClick={onClearFilters} />
        </footer>
      </aside>
      <main className="public-filter-layout__content">
        <header>
          <SearchInput value={search} placeholder="Search..." onChange={onSearchChange} />
        </header>
        {children}
      </main>
    </article>
  );
};

export default PublicFilterLayout;
