import React from 'react';

import { getProfileInitialsForForm, ProfileImage, toBase64 } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import { serverifySportDetails } from '../../profile/sports/sports.helpers';

import ProfileForm from '../../profile/ProfileForm';

import './complete-profile.scss';

const subtitle =
  'If you manage to get a few scrapes at an event, we might share this information ' +
  'with medical personnel to assist you in a most speedy recovery.';

const rules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    idNumber: ['isPresent'],
    contactNumber: ['isNumeric'],
    emergencyContactNumber: ['isNumeric']
  }
};

const serverify = (form) => ({
  first_name: form.firstName,
  last_name: form.lastName,
  phone_number: form.contactNumber,
  image: form.image,
  gender: form.gender,
  date_of_birth: form.dateOfBirth,
  id_number: form.idNumber,
  address: form.address,
  city: form.city,
  province: form.province,
  medical_information_attributes: {
    contact_person: form.emergencyContactPerson,
    contact_number: form.emergencyContactNumber,
    medical_aid: form.medicalAidName,
    medical_aid_number: form.medicalAidNumber,
    blood_type: form.bloodType,
    allergies: form.allergies,
    notes: form.notes
  },
  sport_details_attributes: serverifySportDetails(form.sports)
});

const formify = (profile) => ({
  sports: profile?.sports ?? [],
  email: profile?.email
});

const CompleteProfile = ({ profile, onSave }) => {
  const [form, setForm] = useFormState(formify(profile), rules);

  const handleFileChange = async (file) => {
    const data = await toBase64(file);
    handleChange({ image: data });
  };

  const handleChange = (newState) => setForm({ ...form, ...newState });
  const handleSportChange = (newSportState) => {
    const updatedSports = form.sports.reduce((accum, sport) => {
      if (sport.id === newSportState.sport_id) {
        sport = { ...sport, detail: { ...sport.detail, ...newSportState } };
      }

      accum.push(sport);
      return accum;
    }, []);
    handleChange({ sports: updatedSports });
  };

  const handleSubmit = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className="complete-profile">
      <header>
        <h4 className="complete-profile__title">Complete Profile</h4>
        <p className="complete-profile__subtitle">Please take a moment to complete your profile.</p>
        <p className="complete-profile__subtitle">
          You can change any of the following information in your profile later on by clicking on
          your profile image in the top right corner and then clicking “My Profile”. If YOU want to
          enter an event, please complete your profile using YOUR details.
        </p>
        <p className="complete-profile__subtitle">
          NOTE: if you want to enter an event for children/dependants, (fill in YOUR details as is)
          and then add the dependants in the next step OR by clicking on your profile image in the
          top right corner and then clicking “My Dependants”.
        </p>
      </header>
      <main className="complete-profile__content">
        <section className="complete-profile__context">
          <section className="complete-profile__avatar">
            <ProfileImage
              imgSrc={form?.image}
              initials={getProfileInitialsForForm(form)}
              editMode
              handleFileChange={handleFileChange}
            />
          </section>
        </section>
        <section className="complete-profile__form">
          <ProfileForm
            form={form}
            handleChange={handleChange}
            handleSportChange={handleSportChange}
            handleSubmit={handleSubmit}
            medicalInformationSubtitle={subtitle}
            editable
          />
        </section>
      </main>
    </article>
  );
};

export default CompleteProfile;
