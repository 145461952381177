import React, { useMemo } from 'react';

import {
  CHECKBOX,
  CheckboxGroup,
  DATEPICKER,
  Datepicker,
  DetailsSection,
  DROPDOWN,
  FILE_UPLOAD,
  FileUploadGroup,
  Form,
  FormRow,
  INPUT,
  Input,
  MULTI_DROPDOWN,
  RADIO_GROUP,
  RadioGroup,
  Select,
  TEXT_AREA,
  TextArea,
  TIMEPICKER,
  Timepicker,
  YEARPICKER,
  convertTimeToDate
} from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import './form-canvas.scss';

const FormElement = ({ element, onFocus, name, handleChange, value, form }) => {
  return useMemo(() => {
    switch (element.type) {
      case INPUT:
        return (
          <Input
            onFocus={onFocus}
            placeholder={element.placeholder}
            label={element.label}
            value={value}
            name={name}
            onChange={handleChange}
            error={form?.messages[element.id]}
          />
        );
      case TEXT_AREA:
        return (
          <TextArea
            boxed={!element.informational}
            disabled={element.informational}
            informational={element.informational}
            onFocus={onFocus}
            placeholder={element.placeholder}
            label={element.label}
            subtitle={element.subtitle}
            name={name}
            value={value ?? element.value}
            onChange={handleChange}
            error={form?.messages[element.id]}
          />
        );
      case MULTI_DROPDOWN:
      case DROPDOWN:
        return (
          <Select
            name={name}
            items={element.items.map((item) => ({
              key: item,
              text: item,
              onClick: () => handleChange({ [name]: item })
            }))}
            onFocus={onFocus}
            placeholder={element.placeholder}
            label={element.label}
            value={value}
            error={form?.messages[element.id]}
          />
        );
      case TIMEPICKER:
        return (
          <Timepicker
            onFocus={onFocus}
            label={element.label}
            placeholder={element.placeholder}
            name={name}
            initialValue={convertTimeToDate(value)}
            onChange={handleChange}
            error={form?.messages[element.id]}
          />
        );
      case DATEPICKER:
        return (
          <Datepicker
            onFocus={onFocus}
            label={element.label}
            placeholder={element.placeholder}
            name={name}
            value={value}
            onChange={handleChange}
            error={form?.messages[element.id]}
          />
        );
      case CHECKBOX:
        return (
          <CheckboxGroup
            name={name}
            onFocus={onFocus}
            label={element.label}
            description={element.description}
            onChange={handleChange}
            items={element.items}
            error={form?.messages[element.id]}
          />
        );
      case FILE_UPLOAD:
        return (
          <FileUploadGroup
            fileTypes={element.fileTypes}
            description={element.description}
            label={element.label}
            error={form?.messages[element.id]}
          />
        );
      case RADIO_GROUP:
        const items = element.items.map((item) => ({
          label: item,
          value: item
        }));
        return (
          <RadioGroup
            items={items}
            label={element.label}
            fluid
            name={name}
            description={element.description}
            value={value}
            onChange={handleChange}
            error={form?.messages[element.id]}
          />
        );
      case YEARPICKER:
        return (
          <Datepicker
            yearOnly
            name={name}
            value={value}
            onChange={handleChange}
            onFocus={onFocus}
            label={element.label}
            placeholder={element.placeholder}
          />
        );
      default:
        throw new Error(`The type of element provided does not exist: ${element.type}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, value, handleChange]);
};

const SectionBuilder = ({ section, form, handleChange }) => {
  const formRows = useMemo(() => {
    return section.elements.reduce((accum, element, currentIndex) => {
      const exists = Object.keys(accum).some((key) => key.includes(element.id));
      if (exists) {
        return accum;
      }

      if (element.filled) {
        accum = {
          ...accum,
          [element.id]: (
            <FormRow>
              <section className="form-canvas__element">
                <FormElement
                  key={element.id}
                  element={element}
                  name={element.id}
                  value={form ? form[element.id] : undefined}
                  handleChange={handleChange}
                  form={form}
                />
              </section>
            </FormRow>
          )
        };
        return accum;
      }

      const nextElement = section.elements[currentIndex + 1];
      if (nextElement && !nextElement.filled) {
        accum = {
          ...accum,
          [`${element.id}_${nextElement.id}`]: (
            <FormRow fluidSpaced>
              <section className="form-canvas__element">
                <FormElement
                  key={element.id}
                  element={element}
                  name={element.id}
                  value={form ? form[element.id] : undefined}
                  handleChange={handleChange}
                  form={form}
                />
              </section>
              <section className="form-canvas__element">
                <FormElement
                  key={nextElement.id}
                  element={nextElement}
                  name={nextElement.id}
                  value={form ? form[nextElement.id] : undefined}
                  handleChange={handleChange}
                  form={form}
                />
              </section>
            </FormRow>
          )
        };
      } else {
        accum = {
          ...accum,
          [element.id]: (
            <FormRow singleRow>
              <section className="form-canvas__element">
                <FormElement
                  key={element.id}
                  element={element}
                  name={element.id}
                  value={form ? form[element.id] : undefined}
                  handleChange={handleChange}
                  form={form}
                />
              </section>
            </FormRow>
          )
        };
      }

      return accum;
    }, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section.elements, form]);

  return Object.entries(formRows).map(([key, Row]) => (
    <React.Fragment key={key}>{Row}</React.Fragment>
  ));
};

const FormCanvas = ({ entryForm, form, handleChange }) => (
  <article className={getClassNames('form-canvas', {})}>
    {(entryForm ?? []).map((section) => (
      <section className={getClassNames('form-canvas__section', {})} key={section.id}>
        <DetailsSection title="">
          <header className={getClassNames('form-canvas__section-header', {})}>
            <h5 className="form-canvas__section-title">{section.title}</h5>
          </header>
          <Form>
            <SectionBuilder section={section} form={form} handleChange={handleChange} />
          </Form>
        </DetailsSection>
      </section>
    ))}
  </article>
);

FormCanvas.defaultProps = {
  entryForm: [],
  editable: false,
  showTitle: true
};

export default FormCanvas;
