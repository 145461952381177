export const LOAD_COMPETITIONS = '[COMPETITION_STORE] Load competitions';
export const SET_COMPETITIONS = '[COMPETITION_STORE] Set competitions';
export const loadCompetitions = (onComplete) => ({
  type: LOAD_COMPETITIONS,
  onComplete
});

export const LOAD_TRENDING = '[COMPETITION_STORE] Load trending competitions';
export const SET_TRENDING = '[COMPETITION_STORE] Set trending competitions';
export const loadTrending = () => ({
  type: LOAD_TRENDING
});

export const LOAD_COMPETITION = '[COMPETITION_STORE] Load competition';
export const SET_COMPETITION = '[COMPETITION_STORE] Set competition';
export const loadCompetition = (id, onComplete) => ({
  type: LOAD_COMPETITION,
  id,
  onComplete
});

export const LOAD_ENTRY = '[COMPETITION_STORE] Load competition entry';
export const SET_ENTRY = '[COMPETITION_STORE] Set competition entry';
export const loadEntry = (id, entryId, onComplete) => ({
  type: LOAD_ENTRY,
  id,
  entryId,
  onComplete
});

export const SAVE_ENTRY = '[COMPETITION_STORE] Save competition entry';
export const saveEntry = (id, payload, onSuccess) => ({
  type: SAVE_ENTRY,
  id,
  payload,
  onSuccess
});

export const UPDATE_ENTRY = '[COMPETITION_STORE] Update competition entry';
export const updateEntry = (id, entryId, payload, onSuccess) => ({
  type: UPDATE_ENTRY,
  id,
  entryId,
  payload,
  onSuccess
});

export const LOAD_ENTRIES = '[COMPETITION_STORE] Load competition entries';
export const SET_ENTRIES = '[COMPETITION_STORE] Set competition entries';
export const loadEntries = (id, onComplete) => ({
  type: LOAD_ENTRIES,
  id,
  onComplete
});

export const LOAD_ENTRY_FORM = '[COMPETITION_STORE] Load competition entry form';
export const SET_ENTRY_FORM = '[COMPETITION_STORE] Set competition entry form';
export const loadEntryForm = (id) => ({
  type: LOAD_ENTRY_FORM,
  id
});

export const LOAD_MEMBER_TYPES = '[COMPETITION_STORE] Load competition member types';
export const SET_MEMBER_TYPES = '[COMPETITION_STORE] Set competition member types';
export const loadMemberTypes = (id) => ({
  type: LOAD_MEMBER_TYPES,
  id
});

export const LOAD_CLASSES = '[COMPETITION_STORE] Load competition classes';
export const SET_CLASSES = '[COMPETITION_STORE] Set competition classes';
export const loadClasses = (id) => ({
  type: LOAD_CLASSES,
  id
});

export const COMPLETE_ENTRY = '[COMPETITION_STORE] Complete competition entry';
export const completeEntry = (id, entryId, payload, onSuccess) => ({
  type: COMPLETE_ENTRY,
  id,
  entryId,
  payload,
  onSuccess
});

export const UPLOAD_PROOF_OF_PAYMENT = '[COMPETITION_STORE] Upload proof of payment';
export const uploadProofOfPayment = (id, entryId, payload, onSuccess) => ({
  type: UPLOAD_PROOF_OF_PAYMENT,
  id,
  entryId,
  payload,
  onSuccess
});
