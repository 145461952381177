import React from 'react';

import { ReactComponent as EventInformationIcon } from '../../../icons/event-information-icon.svg';
import { ReactComponent as HostEventsIcon } from '../../../icons/host-events-icon.svg';
import { ReactComponent as ResultsIcon } from '../../../icons/results-icon.svg';
import { ReactComponent as TicketScanningIcon } from '../../../icons/ticket-scanning-icon.svg';

export const FAQCARDDETAILS = [
  {
    title: 'Events Information',
    firstQuestion: 'How do I find an event?',
    secondQuestion: 'How do I enter an event?',
    firstAnswer:
      'In the navigation bar, at the top of the screen, click on "Events and Competitions". ' +
      'Once you have a view of all the available events, you can browse or use filters to perform a more refined search.',
    secondAnswer:
      'Find the event you wish to enter for and click on "View Event", once you have a view of the event details, ' +
      'click on "Enter Event" and follow the process through to payment.',
    icon: <EventInformationIcon />
  },
  {
    title: 'Hosting Events and Competitions',
    firstQuestion: 'Who can be an organiser?',
    secondQuestion: 'How can I become an organiser?',
    firstAnswer:
      'Anyone can be an organiser! If you host paid or non-paid events and/or ' +
      'competitions you can join EventHub as an organiser.',
    secondAnswer:
      'To become an organiser, in the navigation bar at the top of the screen, click ' +
      'on "Organiser Portal" and register to become an organiser. ',
    icon: <HostEventsIcon />
  },
  {
    title: 'Results',
    firstQuestion: 'When will my results be published?',
    secondQuestion: 'How do I find my results?',
    firstAnswer:
      'Results are manually loaded by your event organisers. Once they have published them ' +
      'an email will be sent to you letting you know they can be accessed on your personal profile!',
    secondAnswer:
      'To view your results, in the navigation bar at the top of the screen, click on ' +
      'Results. Once you are there you can search for the results for any of your past events/competitions.',
    icon: <ResultsIcon />
  },
  {
    title: 'Ticket Scanning',
    firstQuestion: 'How do I find my QR code?',
    firstAnswer:
      'Once you have successfully entered an event, an email containing a QR code will be sent to your email address. ' +
      'Alternatively you can find your QR code under your personal profile and clicking on "My Entries".',
    icon: <TicketScanningIcon />
  }
];
