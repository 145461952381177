import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { validateVerificationToken, verifyEmail } from '../../../actions/auth/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import VerifyEmail from '../../../components/auth/verify-email/VerifyEmail';

const VerifyEmailContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { token } = useParams();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(validateVerificationToken(token, navigate));
  }, [dispatch, token, navigate]);

  const onVerifyEmail = (payload) => {
    setLoading(true);
    dispatch(verifyEmail(token, payload, navigate, () => setLoading(false)));
  };

  return (
    <AuthLayout
      logo={false}
      title="Verify Email Address"
      subtitle="Please enter your email address to verify your profile"
    >
      <VerifyEmail loading={loading} onVerifyEmail={onVerifyEmail} />
    </AuthLayout>
  );
};

export default VerifyEmailContainer;
