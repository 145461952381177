import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setShowWelcome, validateVerificationToken } from '../../../../actions/auth/auth.actions';
import { updatePublicProfile } from '../../../../actions/profile/profile.action';

import PublicLayout from '../../../../components/layout/public/PublicLayout';
import CompleteProfile from '../../../../components/auth/complete-profile/CompleteProfile';

const CompleteProfileContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();

  const profile = useSelector(({ profile_store }) => profile_store.profile);

  useEffect(() => {
    dispatch(validateVerificationToken(token, navigate));
  }, [dispatch, token, navigate]);

  const handleSave = (payload) => {
    dispatch(
      updatePublicProfile(token, payload, () => {
        navigate('/dependants');
        dispatch(setShowWelcome(true));
      })
    );
  };

  return (
    <PublicLayout grey>
      <CompleteProfile profile={profile} onSave={handleSave} />
    </PublicLayout>
  );
};

export default CompleteProfileContainer;
