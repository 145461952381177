import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as QuestionIcon } from '../../../icons/question-icon.svg';
import { ReactComponent as PhoneIcon } from '../../../icons/phone-icon.svg';

import './contact-us.scss';

const ContactCard = ({ title, icon, subtitle, action, onClick }) => (
  <article className="contact-card">
    <section className="contact-card__details">
      <span className="contact-card__icon">{icon}</span>
      <section className="contact-card__detail">
        <p className="contact-card__title">{title}</p>
        <small className="contact-card__subtitle">{subtitle}</small>
      </section>
    </section>
    <footer className="contact-card__action">
      <Button label={action} tertiary onClick={onClick} />
    </footer>
  </article>
);

const contactCards = (navigate) => [
  {
    title: 'Frequently Asked Questions',
    subtitle: 'Find the most frequently asked questions and their answers',
    action: 'View FAQs',
    icon: <QuestionIcon />,
    onClick: () => navigate('/faqs')
  },
  {
    title: 'Call Us',
    subtitle: 'Need to speak to a human? Call us for support between 08:00 and 18:00',
    action: 'Get Dialling',
    icon: <PhoneIcon />,
    onClick: () => (window.location.href = 'tel:0836426646')
  },
  {
    title: 'Email Us',
    subtitle: 'Too scared to talk to a human? Send us an email rather any time of day',
    action: 'Open Mail',
    icon: <QuestionIcon />,
    onClick: () => (window.location.href = 'mailto:info@event-hub.co.za')
  }
];

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <article className="contact-us">
      {contactCards(navigate).map((item) => (
        <ContactCard
          key={item.title}
          title={item.title}
          subtitle={item.subtitle}
          action={item.action}
          icon={item.icon}
          onClick={item.onClick}
        />
      ))}
    </article>
  );
};

export default ContactUs;
