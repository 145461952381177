import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Loader } from '@neslotech/eventhub-ui-kit';

import {
  removeSport,
  retrieveProfile,
  updateProfile
} from '../../../actions/profile/profile.action';
import { loadSports } from '../../../actions/sport/sport.actions';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import SportsDetails from '../../../components/sports/details/SportsDetails';

const SportsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const sports = useSelector(({ sport_store }) => sport_store.sports);
  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadSports());
    dispatch(retrieveProfile(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onRemove = (sportId) => {
    dispatch(removeSport(profile.id, sportId));
  };

  const onSave = (payload, onComplete) => {
    dispatch(updateProfile(payload, () => navigate(-1), onComplete));
  };

  return (
    <PublicLayout>
      {loading && <Loader filled />}
      {!loading && (
        <SportsDetails
          allSports={sports}
          sports={profile?.sports ?? []}
          onRemove={onRemove}
          onSave={onSave}
        />
      )}
    </PublicLayout>
  );
};

export default SportsContainer;
