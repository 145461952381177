import { SET_SPORTS } from '../../actions/sport/sport.actions';

export const initialState = {
  sports: []
};

export const sportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPORTS:
      const { sports } = action;
      return {
        sports
      };
    default:
      return state;
  }
};
