import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@neslotech/eventhub-ui-kit';

import {
  loadCompetition,
  loadEntries as loadCompetitionEntries
} from '../../../../actions/competition/competition.action';
import { loadEntries as loadEventEntries, loadEvent } from '../../../../actions/event/event.action';

import PublicLayout from '../../../../components/layout/public/PublicLayout';
import EventDetails from '../../../../components/events/details/EventDetails';

const EventContainer = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { search } = useLocation();

  const competition = useSelector(({ competition_store }) => competition_store.competition);
  const competitionEntries = useSelector(({ competition_store }) => competition_store.entries);
  const competitionStateLoading = useSelector(({ competition_store }) => competition_store.loading);
  const event = useSelector(({ event_store }) => event_store.event);
  const eventEntries = useSelector(({ event_store }) => event_store.entries);
  const eventStateLoading = useSelector(({ competition_store }) => competition_store.loading);

  const [loading, setLoading] = useState(competitionStateLoading ?? eventStateLoading ?? true);
  const [loadingEntries, setLoadingEntries] = useState(true);

  const type = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get('type');
  }, [search]);

  const entity = useMemo(() => {
    return type === 'Competition' ? competition : event;
  }, [type, competition, event]);

  const entries = useMemo(() => {
    return type === 'Competition' ? competitionEntries : eventEntries;
  }, [type, competitionEntries, eventEntries]);

  useEffect(() => {
    if (type === 'Competition') {
      dispatch(loadCompetition(id, () => setLoading(false)));
      dispatch(loadCompetitionEntries(id, () => setLoadingEntries(false)));
    } else {
      dispatch(loadEvent(id, () => setLoading(false)));
      dispatch(loadEventEntries(id, () => setLoadingEntries(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <PublicLayout full>
      {loading && <Loader filled />}
      {!loading && (
        <EventDetails loadingEntries={loadingEntries} event={entity} entries={entries} />
      )}
    </PublicLayout>
  );
};

export default EventContainer;
