import React from 'react';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import Faqs from '../../../components/landing-page/faqs/Faqs';

const FaqsContainer = () => (
  <PublicLayout wide>
    <Faqs />
  </PublicLayout>
);

export default FaqsContainer;
