import React from 'react';

import { getClassNames } from '@neslotech/utils';

import { ReactComponent as Logo } from '../../../images/logo.svg';
import { ReactComponent as LogoAlt } from '../../../images/logo-alt.svg';

import './auth-layout.scss';

const AuthLayout = ({ title, subtitle, altTitle, logo, children }) => (
  <article className="auth-layout">
    {logo && (
      <article className="auth-layout__logo--alt">
        <LogoAlt />
      </article>
    )}
    <section className="auth-layout__content">
      <header>
        {logo && (
          <article className="auth-layout__logo">
            <Logo />
          </article>
        )}
        {title && <h3 className="auth-layout__title">{title}</h3>}
        {altTitle && (
          <h4 className={getClassNames('auth-layout__title', { alt: true })}>{altTitle}</h4>
        )}
        {subtitle && <p className="auth-layout__subtitle">{subtitle}</p>}
      </header>
      <main>{children}</main>
    </section>
  </article>
);

AuthLayout.defaultProps = {
  logo: true
};

export default AuthLayout;
