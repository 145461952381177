import React, { useMemo } from 'react';

import { Accordion, DetailsSection } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as EditIcon } from '../../../icons/edit-icon.svg';

import MotocrossForm from './details/MotocrossForm';
import DefaultForm from './details/DefaultForm';

const SportForm = ({ editable, sport, handleChange }) => {
  const Form = useMemo(() => {
    switch (sport.name) {
      case 'Motocross':
        return MotocrossForm;
      default:
        return DefaultForm;
    }
  }, [sport]);

  return (
    <article className="sports-information">
      <Form editable={editable} sport={sport} handleChange={handleChange} />
    </article>
  );
};

const SportsInformation = ({ editable, form, handleChange, skippable, setEditable }) => (
  <DetailsSection
    title="Sports Information"
    subtitle="Please fill out all necessary information for each selected sport."
    noCard
    icon={!skippable && <EditIcon onClick={() => setEditable(true)} />}
  >
    {(form.sports ?? []).map((sport, index) => (
      <Accordion key={sport.id} title={sport.name} open={index === 0}>
        <SportForm editable={editable} sport={sport} handleChange={handleChange} />
      </Accordion>
    ))}
  </DetailsSection>
);

export default SportsInformation;
