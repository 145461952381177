import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '@neslotech/eventhub-ui-kit';

import { retrieveProfile } from '../../../../actions/profile/profile.action';
import { loadDependant, updateDependant } from '../../../../actions/dependant/dependant.action';

import PublicLayout from '../../../../components/layout/public/PublicLayout';
import DependantDetails from '../../../../components/dependants/details/DependantDetails';

const DependantContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const dependant = useSelector(({ dependant_store }) => dependant_store.dependant);
  const stateLoading = useSelector(({ dependant_store }) => dependant_store.loading);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(retrieveProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile) {
      dispatch(loadDependant(profile.id, id, () => setLoading(false)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, id]);

  const handleSubmit = (payload) => {
    dispatch(updateDependant(profile.id, dependant.id, payload, () => navigate('/dependants')));
  };

  return (
    <PublicLayout>
      {loading && <Loader filled />}
      {!loading && <DependantDetails dependant={dependant} onSave={handleSubmit} />}
    </PublicLayout>
  );
};

export default DependantContainer;
