import React, { useMemo } from 'react';

import {
  Button,
  Datepicker,
  DetailsSection,
  Form,
  FormRow,
  Input,
  RadioGroup,
  Select,
  TextArea
} from '@neslotech/eventhub-ui-kit';
import { capitalise, getClassNames, isEmpty } from '@neslotech/utils';

import { ReactComponent as EditIcon } from '../../icons/edit-icon.svg';

import SportsInformation from './sports/SportsInformation';

import './profile-form.scss';

const familyItems = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const ProfileForm = ({
  dependant,
  isNew,
  form,
  handleChange,
  handleSportChange,
  handleSubmit,
  editable,
  setEditable,
  skippable,
  personalDetailsTitle,
  personalDetailsSubtitle,
  medicalInformationTitle,
  medicalInformationSubtitle
}) => {
  const invalidForm = useMemo(() => {
    if (!form?.valid) {
      return true;
    }

    if (isEmpty(form.sports)) {
      return false;
    }

    return form.sports.some((sportForm) => {
      if (!sportForm.detail) {
        return false;
      }

      if (!sportForm.detail?.valid) {
        return true;
      }

      return sportForm.detail.vehicles.some((vehicleForm) => !vehicleForm.valid);
    });
  }, [form]);

  return (
    <article className={getClassNames('profile-form', { editing: editable })}>
      <DetailsSection
        title={personalDetailsTitle}
        subtitle={personalDetailsSubtitle}
        icon={!skippable && <EditIcon onClick={() => setEditable(true)} />}
      >
        <Form>
          <FormRow fluidSpaced>
            <Input
              name="firstName"
              label="First Name"
              placeholder="First Name"
              value={form?.firstName}
              error={form?.messages?.firstName}
              onChange={handleChange}
              disabled={!editable}
            />
            <Input
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              value={form?.lastName}
              error={form?.messages?.lastName}
              onChange={handleChange}
              disabled={!editable}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              placeholder="Email address"
              label="Email address"
              type="email"
              disabled={!isNew}
              value={form?.email}
              error={form?.messages?.email}
              name="email"
              onChange={handleChange}
            />
            <Datepicker
              yearDropdownItemNumber={60}
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="scroll"
              disabled={!editable}
              name="dateOfBirth"
              label="Date of Birth"
              placeholder="Date of Birth"
              value={form?.dateOfBirth}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              name="idNumber"
              label="ID/Passport Number"
              placeholder="ID/Passport Number"
              value={form?.idNumber}
              error={form?.messages?.idNumber}
              onChange={handleChange}
              disabled={!editable}
            />
            <Select
              disabled={!editable}
              name="gender"
              label="Gender"
              placeholder="Select gender"
              value={capitalise(form?.gender)}
              error={form?.messages?.gender}
              items={[
                { text: 'Male', onClick: () => handleChange({ gender: 'male' }) },
                { text: 'Female', onClick: () => handleChange({ gender: 'female' }) }
              ]}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              name="contactNumber"
              label="Contact Number"
              placeholder="Contact Number"
              value={form?.contactNumber}
              error={form?.messages?.contactNumber}
              onChange={handleChange}
              disabled={!editable}
            />
            <Input
              disabled={!editable}
              name="province"
              label="Province"
              placeholder="Province"
              value={form?.province}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editable}
              name="address"
              label="Address"
              placeholder="Address"
              value={form?.address}
              onChange={handleChange}
            />
            <Input
              disabled={!editable}
              name="city"
              label="City"
              placeholder="City"
              value={form?.city}
              onChange={handleChange}
            />
          </FormRow>
          {dependant && (
            <FormRow singleRow>
              <RadioGroup
                fluid
                label="Family Member"
                name="familyMember"
                items={familyItems}
                error={form?.messages?.familyMember}
                value={form?.familyMember}
                onChange={handleChange}
              />
            </FormRow>
          )}
        </Form>
      </DetailsSection>
      <DetailsSection
        title={medicalInformationTitle}
        subtitle={medicalInformationSubtitle}
        icon={!skippable && <EditIcon onClick={() => setEditable(true)} />}
      >
        <Form>
          <FormRow fluidSpaced>
            <Input
              disabled={!editable}
              name="emergencyContactPerson"
              label="Emergency Contact Person"
              placeholder="Emergency Contact Person"
              value={form?.emergencyContactPerson}
              onChange={handleChange}
            />
            <Input
              disabled={!editable}
              name="emergencyContactNumber"
              label="Emergency Contact Number"
              placeholder="Emergency Contact Number"
              error={form?.messages?.emergencyContactNumber}
              value={form?.emergencyContactNumber}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editable}
              name="medicalAidName"
              label="Medical Aid Name"
              placeholder="Medical Aid Name"
              value={form?.medicalAidName}
              onChange={handleChange}
            />
            <Input
              disabled={!editable}
              name="medicalAidNumber"
              label="Medical Aid Number"
              placeholder="Medical Aid Number"
              value={form?.medicalAidNumber}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow fluidSpaced>
            <Input
              disabled={!editable}
              name="bloodType"
              label="Blood Type"
              placeholder="Blood Type"
              value={form?.bloodType}
              onChange={handleChange}
            />
            <Input
              disabled={!editable}
              name="allergies"
              label="Allergies"
              placeholder="Any major allergies?"
              value={form?.allergies}
              onChange={handleChange}
            />
          </FormRow>
          <FormRow>
            <TextArea
              disabled={!editable}
              boxed
              name="notes"
              label="Additional Notes"
              placeholder="Anything else you think we should know in an emergency?"
              value={form?.notes}
              onChange={handleChange}
            />
          </FormRow>
        </Form>
      </DetailsSection>
      {!isEmpty(form?.sports) && (
        <SportsInformation
          setEditable={setEditable}
          skippable={skippable}
          editable={editable}
          form={form}
          handleChange={handleSportChange}
        />
      )}
      {editable && (
        <footer className="profile-form__actions">
          <Button label="Save" disabled={invalidForm} onClick={handleSubmit} />
          {skippable && <Button hollow label="Skip Step" />}
        </footer>
      )}
    </article>
  );
};

ProfileForm.defaultProps = {
  personalDetailsTitle: 'Personal Details',
  medicalInformationTitle: 'Medical Information'
};

export default ProfileForm;
