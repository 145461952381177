import { combineReducers } from 'redux';

import { authReducer } from './auth/auth.reducer';
import { systemReducer } from './system/system.reducer';
import { profileReducer } from './profile/profile.reducer';
import { dependantReducer } from './dependant/dependant.reducer';
import { competitionReducer } from './competition/competition.reducer';
import { eventReducer } from './event/event.reducer';
import { sportReducer } from './sport/sport.reducer';

const rootReducer = combineReducers({
  auth_store: authReducer,
  system_store: systemReducer,
  profile_store: profileReducer,
  dependant_store: dependantReducer,
  competition_store: competitionReducer,
  event_store: eventReducer,
  sport_store: sportReducer
});

export default rootReducer;
