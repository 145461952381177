import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './basic-select.scss';

const getChangeValue = (name, value) => (name ? { [name]: value } : value);

const BasicSelect = ({
  label,
  multiple,
  name,
  value = '',
  placeholder,
  disabled,
  onChange,
  items,
  error
}) => {
  const handleChange = (e) => {
    let val = e.target.value;
    val = getChangeValue(name, val);
    onChange(val);
  };

  return (
    <article className="basic-select">
      <section className="basic-select__label">
        {label && <label htmlFor={name}>{label}</label>}
      </section>
      <section className={getClassNames('basic-select__select-wrapper', { disabled })}>
        <select
          id={name}
          multiple={multiple}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={handleChange}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {items.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </section>
      <section className="basic-select__error">
        {error && <small>{error}</small>}
        {value && !disabled && <p onClick={() => handleChange({ target: { value: '' } })}>Clear</p>}
      </section>
    </article>
  );
};

export default BasicSelect;
