import React from 'react';

import { Button } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as DefaultEventImage } from '../../images/defaut-event-image.svg';

import './public-event-card.scss';

const PublicEventCard = ({ entity, type, onClick }) => (
  <article className="public-event-card">
    <section className="public-event-card__image">
      {entity.image ? <img src={entity.image} alt={entity.name} /> : <DefaultEventImage />}
    </section>
    <section className="public-event-card__details">
      <div className="public-event-card__event-name">
        <h6>{entity.name}</h6>
        <h6>{entity.start_date}</h6>
      </div>
      <section className="public-event-card__description">
        <small>{entity.description}</small>
      </section>
    </section>
    <section className="public-event-card__action">
      <Button label={`View ${type}`} tertiary onClick={onClick} />
    </section>
  </article>
);

export default PublicEventCard;
