import { SET_PROFILE } from '../../actions/profile/profile.action';

export const initialState = {
  profile: undefined
};

export function profileReducer(state = initialState, action) {
  if (action.type === SET_PROFILE) {
    const { profile } = action;
    return {
      ...state,
      profile
    };
  } else {
    return state;
  }
}
