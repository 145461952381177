import React from 'react';
import { useDispatch } from 'react-redux';

import { Modal, ModalLayout } from '@neslotech/eventhub-ui-kit';

import { removeDependant } from '../../../../actions/dependant/dependant.action';

import RemoveDependant from '../../../../components/dependants/remove/RemoveDependant';

const RemoveDependantContainer = ({ profile, dependant, show, setShowModal }) => {
  const dispatch = useDispatch();

  const onClose = () => setShowModal(false);

  const handleConfirm = () => {
    dispatch(removeDependant(profile.id, dependant.id));
    onClose();
  };

  return (
    <>
      {show && (
        <Modal>
          <ModalLayout onClose={onClose} title="Remove Dependant">
            <RemoveDependant
              dependant={dependant}
              onConfirmClick={handleConfirm}
              onCancelClick={onClose}
            />
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default RemoveDependantContainer;
