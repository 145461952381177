import React from 'react';

import bannerImage from '../../../images/service-offerings-image.png';

import { ReactComponent as OrganiserIcon } from '../../../icons/organiser-service-icon.svg';
import { ReactComponent as HostingIcon } from '../../../icons/hosting-service-icon.svg';
import { ReactComponent as ResultsIcon } from '../../../icons/results-service-icon.svg';
import { ReactComponent as ScanningIcon } from '../../../icons/scanning-service-icon.svg';

import WaitingBanner from '../banners/WaitingBanner';

import './service-offerings.scss';

const OfferingCard = ({ icon, title, message }) => (
  <article className="offering-card">
    <header>{icon}</header>
    <main>
      <dl>
        <dt>{title}</dt>
        <dd>{message}</dd>
      </dl>
    </main>
  </article>
);

const offerings = [
  {
    icon: <OrganiserIcon />,
    title: 'Become an Organiser',
    message:
      'Create your own organisations or be invited to join others, organisers can use EventHub to create and host their own events.'
  },
  {
    icon: <HostingIcon />,
    title: 'Hosting Events and Competitions',
    message:
      'Host paid and non-paid events and competitions. ' +
      'Create a professional, seamless experience for your attendees at every stage of your event.'
  },
  {
    icon: <ResultsIcon />,
    title: 'Results',
    message:
      'Organisers and attendees can gain access to all event results viewing not only a leaderboard but an individuals results too.'
  },
  {
    icon: <ScanningIcon />,
    title: 'Ticket Scanning',
    message:
      'Monitor ticket sales and check-ins in real-time from a mobile device with our easy-to-use mobile app.'
  }
];

const ServiceOfferings = () => (
  <article className="service-offerings">
    <header>
      <img src={bannerImage} alt="Service offerings" />
    </header>
    <main>
      {offerings.map(({ icon, title, message }) => (
        <OfferingCard key={title} title={title} icon={icon} message={message} />
      ))}
    </main>
    <footer>
      <WaitingBanner />
    </footer>
  </article>
);

export default ServiceOfferings;
