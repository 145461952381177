import React from 'react';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import ExpiredLink from '../../../components/auth/expired-link/ExpiredLink';

const PasswordExpiredLinkContainer = () => (
  <AuthLayout logo={false}>
    <ExpiredLink subtitle="This link is invalid. To reset your password please try again." />
  </AuthLayout>
);

export default PasswordExpiredLinkContainer;
