import React from 'react';
import { Link } from 'react-router-dom';

import {
  Checkbox,
  Form,
  FormAction,
  FormRow,
  Input,
  PARTICIPANT_ROLE,
  PasswordInput
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './login.scss';

const rules = {
  validates: {
    email: ['isPresent', 'isEmail'],
    password: ['isPresent']
  }
};

const serverify = ({ email, password }) => ({
  email,
  password,
  role: PARTICIPANT_ROLE
});

const Login = ({ loading, onLogin }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    const credentials = serverify(form);
    onLogin(credentials);
  };

  return (
    <article className="login">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            type="email"
            value={form?.email}
            error={form?.messages?.email}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <PasswordInput
            name="password"
            label="Password"
            placeholder="Password"
            value={form?.password}
            error={form?.messages?.password}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow fluid>
          <Checkbox name="remember" label="Remember Me" />
          <Link to="/password/forgot">
            <small className="login__forgot-password">Forgot Password?</small>
          </Link>
        </FormRow>
        <FormAction
          label="Login"
          loading={loading}
          disabled={!form?.valid}
          onClick={handleSubmit}
        />
        <small className="login__register-link">
          Don’t have an account? <Link to="/register">Register Here.</Link>
        </small>
      </Form>
    </article>
  );
};

export default Login;
