import React, { useMemo } from 'react';

import { isMobile, Loader } from '@neslotech/eventhub-ui-kit';
import { getClassNames } from '@neslotech/utils';

import { useWizard } from '../../hooks/useWizard';

import './entry-wizard.scss';

const EntryWizard = () => {
  const { activeStep, onStepChange, loading, steps } = useWizard();

  const WizardComponent = useMemo(() => {
    return steps.find((step) => step.index === activeStep).component;
  }, [steps, activeStep]);

  return (
    <article className="entry-wizard">
      <aside className="entry-wizard__steps">
        {steps.map((step) => (
          <section
            key={step.index}
            className={getClassNames('entry-wizard__step', { active: activeStep === step.index })}
            onClick={() => onStepChange(step.index)}
          >
            <span className="entry-wizard__bubble">{step.index}</span>
            {!isMobile() && <p>{step.title}</p>}
          </section>
        ))}
      </aside>
      <main className="entry-wizard__content">
        {loading && <Loader filled />}
        {!loading && <WizardComponent />}
      </main>
    </article>
  );
};

export default EntryWizard;
