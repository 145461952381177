import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LandingPageContainer from './landing-page/LandingPage.container';
import ContactUsContainer from './contact-us/ContactUs.container';
import TermsContainer from './terms/Terms.container';
import PrivacyPolicyContainer from './privacy-policy/PrivacyPolicy.container';
import ServiceOfferingsContainer from './service-offerings/ServiceOfferings.container';
import FaqsContainer from './faqs/Faqs.container';

const PublicContainer = () => (
  <Routes>
    <Route exact path="/" element={<LandingPageContainer />} />
    <Route exact path="/service-offerings" element={<ServiceOfferingsContainer />} />
    <Route exact path="/contact-us" element={<ContactUsContainer />} />
    <Route exact path="/terms" element={<TermsContainer />} />
    <Route exact path="/privacy-policy" element={<PrivacyPolicyContainer />} />
    <Route exact path="/faqs" element={<FaqsContainer />} />
  </Routes>
);

export default PublicContainer;
