export const AUTHENTICATE = '[AUTH_STORE] Authenticate';
export const authenticate = (credentials, navigate, onComplete) => ({
  type: AUTHENTICATE,
  credentials,
  navigate,
  onComplete
});

export const SET_SHOW_WELCOME = '[AUTH_STORE] set show welcome';
export const setShowWelcome = (showWelcome) => ({
  type: SET_SHOW_WELCOME,
  showWelcome
});

export const REQUEST_PASSWORD_RESET = '[AUTH_STORE] Request password reset';
export const requestPasswordReset = (payload, navigate, onComplete) => ({
  type: REQUEST_PASSWORD_RESET,
  payload,
  navigate,
  onComplete
});

export const VALIDATE_RESET_TOKEN = '[AUTH_STORE] Validate reset token';
export const validateResetToken = (token, navigate) => ({
  type: VALIDATE_RESET_TOKEN,
  token,
  navigate
});

export const RESET_PASSWORD = '[AUTH_STORE] Reset password';
export const resetPassword = (token, payload, navigate, onComplete) => ({
  type: RESET_PASSWORD,
  token,
  payload,
  navigate,
  onComplete
});

export const REGISTER = '[AUTH_STORE] Register';
export const register = (payload, navigate, onComplete) => ({
  type: REGISTER,
  payload,
  navigate,
  onComplete
});

export const VALIDATE_VERIFICATION_TOKEN = '[AUTH_STORE] Validate verification token';
export const validateVerificationToken = (token, navigate) => ({
  type: VALIDATE_VERIFICATION_TOKEN,
  token,
  navigate
});

export const VERIFY_EMAIL = '[AUTH_STORE] Verify email';
export const verifyEmail = (token, payload, navigate, onComplete) => ({
  type: VERIFY_EMAIL,
  token,
  payload,
  navigate,
  onComplete
});
