import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@neslotech/eventhub-ui-kit';

import { ReactComponent as ExpiredLinkImage } from '../../../images/expired-link.svg';

import './expired-link.scss';

const ExpiredLink = ({ subtitle }) => (
  <section className="expired-link">
    <header className="expired-link__header">
      <ExpiredLinkImage />
    </header>
    <h3 className="expired-link__title">
      Oh No! <br />
      This link is broken
    </h3>
    <p className="expired-link__subtitle">{subtitle}</p>
    <footer className="expired-link__footer">
      <Link to="/">
        <Button label="Back To Login" />
      </Link>
    </footer>
  </section>
);

export default ExpiredLink;
