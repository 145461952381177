import React, { useEffect, useMemo } from 'react';

import { Accordion, AddAction, Form, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { generateId } from '@neslotech/utils';

import BasicSelect from '../../select/BasicSelect';

import './crew-members.mobile.scss';

const EMPTY_CREW_MEMBER = () => ({
  memberTypeId: '',
  price: '',
  fullName: '',
  contactNumber: '',
  email: '',
  idNumber: '',
  _id: generateId()
});

const rules = {
  validates: {
    memberTypeId: ['isPresent'],
    price: ['isPresent'],
    fullName: ['isPresent'],
    contactNumber: ['isPresent', 'isNumeric'],
    emailAddress: ['isPresent', 'isEmail'],
    idNumber: ['isPresent']
  }
};

const formify = (member, memberTypes) => ({
  id: member.id,
  memberTypeId: member.memberTypeId,
  price: memberTypes.find((type) => type.id === member.memberTypeId)?.price,
  fullName: member.fullName,
  contactNumber: member.contactNumber,
  email: member.email,
  idNumber: member.idNumber,
  _id: member._id ?? generateId()
});

const MemberForm = ({ member, memberTypes, handleValueChange, onRemove }) => {
  const [form, setForm] = useFormState(formify(member, memberTypes), rules);
  const handleChange = (newState) => {
    setForm({ ...form, ...newState });
    handleValueChange({ ...form, ...newState });
  };

  // combats an issue where the valid flag is set after the change has been propagated
  useEffect(() => {
    if (!form.memberTypeId) {
      form.valid = true;
      form.messages = {};
    }

    handleValueChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.valid]);

  const matchingType = useMemo(() => {
    return memberTypes.find((memberType) => memberType.id === Number(form?.memberTypeId));
  }, [form?.memberTypeId, memberTypes]);

  const memoizedTitle = useMemo(() => {
    if (matchingType) {
      return `${matchingType.name} - ${form.fullName}`;
    }

    return 'Select your member type';
  }, [form.fullName, matchingType]);

  return (
    <Accordion title={memoizedTitle}>
      <Form>
        <FormRow>
          <BasicSelect
            label="Select your member type"
            items={memberTypes.map((type) => ({
              value: type.id,
              label: type.name
            }))}
            borderless
            name="memberTypeId"
            placeholder="Member Type"
            value={form.memberTypeId}
            autoComplete="off"
            onChange={handleChange}
          />
        </FormRow>
        {}
        <FormRow>
          <Input
            name="price"
            label="Price"
            placeholder="Price"
            value={matchingType?.price}
            onChange={handleChange}
            disabled
          />
        </FormRow>
        <FormRow>
          <Input
            name="fullName"
            label="Full Name"
            placeholder="Full Name"
            value={form?.fullName}
            error={form?.messages?.fullName}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            value={form?.email}
            error={form?.messages?.email}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="contactNumber"
            label="Contact Number"
            placeholder="Contact Number"
            value={form?.contactNumber}
            error={form?.messages?.contactNumber}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="idNumber"
            label="ID Number"
            placeholder="ID Number"
            value={form?.idNumber}
            error={form?.messages?.idNumber}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <p onClick={() => onRemove(form._id)}>Remove</p>
        </FormRow>
      </Form>
    </Accordion>
  );
};

const CrewMembersMobile = ({ entrant, memberTypes, entrantForm, handleEntrantFormChange }) => {
  const mappedForms = useMemo(() => {
    return (entrantForm?.members ?? []).filter((member) => !member._destroy);
  }, [entrantForm?.members]);

  const addNewRow = () => {
    const members = mappedForms ?? [];
    members.push(EMPTY_CREW_MEMBER());
    handleEntrantFormChange({ members });
  };

  const removeRow = (id) => {
    const members = (mappedForms ?? []).reduce((accum, member) => {
      if (member._id === id) {
        member._destroy = '1';
      }

      return [...accum, member];
    }, []);
    handleEntrantFormChange({ members });
  };

  return (
    <article className="crew-members-mobile">
      <header>
        <h4>Add New Crew Member</h4>
        <p>
          Please add the details of the member(s) that will be accompanying {entrant.full_name} to
          the event.
        </p>
        <p>Any selections made that do not have a member type selected, will be ignored.</p>
      </header>
      <section className="crew-members-mobile__form">
        {mappedForms.map((form, index) => {
          const handleValueChange = (value) => {
            let type = mappedForms[index] ?? {};
            type = {
              ...type,
              ...value
            };
            mappedForms[index] = type;

            handleEntrantFormChange({ members: mappedForms });
          };

          return (
            <MemberForm
              key={form._id}
              member={form}
              memberTypes={memberTypes}
              handleValueChange={handleValueChange}
              onRemove={removeRow}
            />
          );
        })}
      </section>
      <footer className="crew-members-mobile__actions">
        <AddAction title="Add new crew member" onClick={addNewRow} />
      </footer>
    </article>
  );
};

export default CrewMembersMobile;
