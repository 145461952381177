import React from 'react';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import SectionLayout from '../../../components/layout/section/SectionLayout';
import ContactUs from '../../../components/landing-page/contact-us/ContactUs';

const ContactUsContainer = () => (
  <PublicLayout>
    <SectionLayout title="Contact Us">
      <ContactUs />
    </SectionLayout>
  </PublicLayout>
);

export default ContactUsContainer;
