import React, { useMemo } from 'react';

import { Accordion, Button, isMobile, Table } from '@neslotech/eventhub-ui-kit';
import { isEmpty } from '@neslotech/utils';

import { useWizard } from '../../../hooks/useWizard';

import EventDescription from '../../events/details/EventDescription';

import './overview.scss';

const totalDueCols = [
  {
    accessor: 'participant',
    Header: 'Participant',
    disableSortBy: isMobile()
  },
  {
    accessor: 'price',
    Header: 'Price',
    disableSortBy: isMobile()
  }
];

const Overview = () => {
  const { context, stateEntry, onStepChange, activeStep } = useWizard();

  const totalDueRows = useMemo(() => {
    return (stateEntry.pricing_model?.entrant_pricing_list ?? []).map((item) => ({
      participant: item.entrant.full_name,
      price: item.subtotal_cost
    }));
  }, [stateEntry]);

  const handlePreviousStep = () => {
    onStepChange(activeStep - 1);
  };

  const handleNextStep = () => {
    onStepChange(activeStep + 1);
  };

  return (
    <article className="entry-overview">
      <h2>Overview</h2>
      {context && <EventDescription event={context} />}
      <section className="entry-overview__entrants">
        <p>Please review all the information you have provided and ensure it is all correct</p>
        <p className="entry-overview__reference">
          Entry reference number: {stateEntry?.reference_number}
        </p>
      </section>
      <section className="entry-overview__entrants">
        {stateEntry.entrants.map((entrant) => (
          <EntrantPricing key={entrant.id} entrant={entrant} entry={stateEntry} />
        ))}
      </section>
      <Accordion title="Total Due" open>
        <Table cols={totalDueCols} rowData={totalDueRows} />
        <section className="entry-overview__total-due-amount">
          <CostBreakdown pricing={stateEntry.pricing_model} />
        </section>
      </Accordion>
      <footer className="entry-overview__actions">
        <Button hollow label="Previous Step" onClick={handlePreviousStep} />
        <Button label="Next Step" onClick={handleNextStep} />
      </footer>
    </article>
  );
};

const classSelectionCols = [
  {
    accessor: 'className',
    Header: 'Class Name'
  },
  {
    accessor: 'firstClass',
    Header: '1st Class'
  },
  {
    accessor: 'secondClass',
    Header: '2nd Class'
  },
  {
    accessor: 'firstFamily',
    Header: '1st Class (Family Member)'
  },
  {
    accessor: 'secondFamily',
    Header: '2nd Class (Family Member)'
  },
  {
    accessor: 'price',
    Header: 'Price'
  }
];

const mobileClassSelectionCols = [
  {
    accessor: 'className',
    Header: 'Class',
    disableSortBy: true
  },
  {
    accessor: 'firstClass',
    Header: '1st Class',
    disableSortBy: true
  },
  {
    accessor: 'secondClass',
    Header: '2nd Class',
    disableSortBy: true
  },
  {
    accessor: 'price',
    Header: 'Price',
    disableSortBy: true
  }
];

const memberSelectionCols = [
  {
    accessor: 'memberType',
    Header: 'Member Type'
  },
  {
    accessor: 'amountLabel',
    Header: 'Amount'
  },
  {
    accessor: 'price',
    Header: 'Price'
  }
];

const EntrantPricing = ({ entrant, entry }) => {
  const entrantPricing = useMemo(() => {
    return (entry.pricing_model?.entrant_pricing_list ?? []).find(
      (entrantPricing) => entrantPricing.entrant.id === entrant.id
    );
  }, [entry, entrant]);

  const classRowData = useMemo(() => {
    return (entrantPricing?.class_pricing ?? []).map((classPricing) => ({
      className: (
        <section>
          <p>{classPricing.competition_class.name}</p>
          <small>{classPricing.vehicle_name}</small>
        </section>
      ),
      firstClass: classPricing.first_class_price,
      secondClass: classPricing.second_class_price,
      firstFamily: classPricing.first_class_family_price,
      secondFamily: classPricing.second_class_family_price,
      price: classPricing.price
    }));
  }, [entrantPricing]);

  const memberRowData = useMemo(() => {
    const rows = (entrantPricing?.member_pricing ?? []).map((memberPricing) => ({
      memberType: memberPricing.member_type.name,
      amount: 1,
      amountLabel: '1x',
      price: memberPricing.price
    }));

    return rows.reduce((accum, row) => {
      const matchingItem = accum.find((item) => item.memberType === row.memberType);
      if (matchingItem) {
        matchingItem.amount = matchingItem.amount + 1;
        matchingItem.amountLabel = `${matchingItem.amount}x`;
      } else {
        accum.push(row);
      }

      return accum;
    }, []);
  }, [entrantPricing]);

  return (
    <Accordion title={entrant.full_name} open>
      <article className="entry-overview__class-selection">
        <p>Class Selection</p>
        <Table
          cols={isMobile() ? mobileClassSelectionCols : classSelectionCols}
          rowData={classRowData}
        />
      </article>
      {!isEmpty(memberRowData) && (
        <article className="entry-overview__member-selection">
          <p>Member Selection</p>
          <Table cols={memberSelectionCols} rowData={memberRowData} />
        </article>
      )}
      <footer className="entry-overview__entrant-total">
        <CostBreakdown pricing={entrantPricing} />
      </footer>
    </Accordion>
  );
};

const CostBreakdown = ({ pricing }) => (
  <ul>
    <li>
      <span>Subtotal</span>
      <p>{pricing?.subtotal_cost}</p>
    </li>
    <li>
      <span>Total</span>
      <p>{pricing?.total_cost}</p>
    </li>
  </ul>
);

export default Overview;
