import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@neslotech/eventhub-ui-kit';

import WeightsImage from '../../../images/weights-image.png';
import TennisImage from '../../../images/tennis-image.png';
import SnowImage from '../../../images/snow-image.png';

import './list-events.scss';

const ListEvents = () => {
  const navigate = useNavigate();

  return (
    <article className="list-events">
      <section className="list-events__details">
        <h1 className="list-events__title">Want to list your events?</h1>
        <p className="list-events__description">
          Do you host sporting events and want a seamless and effective booking process for your
          customers? View a handful of our service offerings to find out if EventHub is the solution
          for you.
        </p>
        <section className="list-events__action">
          <Button label="Learn More" onClick={() => navigate('/service-offerings')} />
        </section>
      </section>
      <section className="list-events__image">
        <section className="list-events__image-split">
          <img alt="Weights" src={WeightsImage} />
          <img alt="Tennis" src={TennisImage} />
        </section>
        <section className="list-events__image-split">
          <img alt="Snow" src={SnowImage} />
        </section>
      </section>
    </article>
  );
};

export default ListEvents;
