import axios from 'axios';
import { all, call, put, takeLatest } from '@redux-saga/core/effects';

import { noOp, SNACK_CRITICAL } from '@neslotech/utils';

import { getLoadSportsRequest } from '../../tools/api/sport.endpoints';

import { addSystemNotice } from '../../actions/system/system.actions';
import { LOAD_SPORTS, SET_SPORTS } from '../../actions/sport/sport.actions';

export function* performLoadSports({ onComplete = noOp }) {
  try {
    // get endpoint and http request options
    const [endpoint, requestOptions] = getLoadSportsRequest();

    // make the request, no need to check the response
    const { data } = yield call(axios, endpoint, requestOptions);

    // set sports on the state
    yield put({ type: SET_SPORTS, sports: data });
  } catch ({ response }) {
    yield put(addSystemNotice(response?.data?.error ?? 'Failed to load sports.', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadSportsRequest() {
  yield takeLatest(LOAD_SPORTS, performLoadSports);
}

export default function* sportSaga() {
  yield all([watchForLoadSportsRequest()]);
}
