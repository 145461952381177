import React from 'react';

import { ReactComponent as NoDataIcon } from '../../icons/no-data-icon.svg';

import './empty-message.scss';

const EmptyMessage = ({ type, action, message }) => (
  <section className="empty-message">
    <NoDataIcon />
    <h1>No {type}s found</h1>
    <p>{message}</p>
    {action}
  </section>
);

export default EmptyMessage;
