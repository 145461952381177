import React from 'react';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import ExpiredLink from '../../../components/auth/expired-link/ExpiredLink';

const VerificationExpiredLinkContainer = () => (
  <AuthLayout logo={false}>
    <ExpiredLink subtitle="This link is invalid. Please contact your system admin." />
  </AuthLayout>
);

export default VerificationExpiredLinkContainer;
