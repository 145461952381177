import React from 'react';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import SectionLayout from '../../../components/layout/section/SectionLayout';
import Terms from '../../../components/terms/Terms';

const TermsContainer = () => (
  <PublicLayout>
    <SectionLayout title="Terms and Conditions">
      <Terms />
    </SectionLayout>
  </PublicLayout>
);

export default TermsContainer;
