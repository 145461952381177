import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@neslotech/eventhub-ui-kit';

import PublicEventCard from '../../card/PublicEventCard';

import './trending-section.scss';

const TrendingSection = ({ entities, title, type }) => {
  const navigate = useNavigate();

  return (
    <article className="trending-section">
      <header>
        <h1 className="trending-section__title">{title}</h1>
      </header>
      <main className="trending-section__cards">
        {entities.map((item) => (
          <PublicEventCard
            key={item.id}
            entity={item}
            type={type}
            onClick={() => navigate(`events/${item.id}?type=${type}`)}
          />
        ))}
      </main>
      <footer className="trending-section__actions">
        <Button label="View All" onClick={() => navigate('/events')} />
      </footer>
    </article>
  );
};

export default TrendingSection;
