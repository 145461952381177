import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { register } from '../../../actions/auth/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Register from '../../../components/auth/register/Register';

const RegisterContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onRegister = (payload) => {
    setLoading(true);
    dispatch(register(payload, navigate, () => setLoading(false)));
  };

  return (
    <AuthLayout title="Register" logo={false}>
      <Register loading={loading} onRegister={onRegister} />
    </AuthLayout>
  );
};

export default RegisterContainer;
