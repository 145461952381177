import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SnackBar } from '@neslotech/eventhub-ui-kit';

import { removeSystemNotice } from './actions/system/system.actions';

import AuthProvider from './providers/Auth.provider';

import AuthContainer from './containers/auth/Auth.container';
import PublicContainer from './containers/public/Public.container';
import ParticipantContainer from './containers/participant/Participant.container';

const App = () => {
  const dispatch = useDispatch();
  const notices = useSelector(({ system_store }) => system_store.notices);

  const handleCloseNotice = (noticeId) => {
    dispatch(removeSystemNotice(noticeId));
  };

  return (
    <main className="app-root">
      <AuthProvider>
        <PublicContainer />
        <AuthContainer />

        <ParticipantContainer />
      </AuthProvider>

      <SnackBar notices={notices} onCloseNotice={handleCloseNotice} />
    </main>
  );
};

export default App;
