import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAuthTokenCookie, removeAuthTokenCookie } from '../tools/auth.util';

import { AuthContext } from '../contexts/Auth.context';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const cookieValue = getAuthTokenCookie();
  const [token, setToken] = useState(cookieValue);

  useEffect(() => {
    setToken(cookieValue);
  }, [cookieValue]);

  const handleLogout = () => {
    setToken(null);
    removeAuthTokenCookie();

    navigate('/login');
  };

  const value = {
    token,
    onLogout: handleLogout
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
