import { SET_SHOW_WELCOME } from '../../actions/auth/auth.actions';

export const initialState = {
  showWelcome: false
};

export const authReducer = (state = initialState, action) => {
  if (action.type === SET_SHOW_WELCOME) {
    const { showWelcome } = action;
    return {
      ...state,
      showWelcome
    };
  }

  return state;
};
