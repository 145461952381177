import React, { useEffect } from 'react';

import { useFormState } from '@neslotech/hooks';
import { DetailsSection, FormRow, Input } from '@neslotech/eventhub-ui-kit';

const rules = {
  validates: {
    name: ['isPresent'],
    manufacturer: ['isPresent'],
    model: ['isPresent']
  }
};

const formify = (vehicle) => ({
  _id: vehicle._id,
  id: vehicle.id,
  name: vehicle.name,
  raceNumber: vehicle.race_number,
  manufacturer: vehicle.manufacturer,
  model: vehicle.model,
  chassisNumber: vehicle.chassis_number,
  engineNumber: vehicle.engine_number
});

const Motorbike = ({ editable, vehicle, handleChange }) => {
  const [form, setForm] = useFormState(formify(vehicle), rules);
  const handleFormChange = (newState) => {
    setForm({ ...form, ...newState });
    handleChange({ ...form, ...newState });
  };

  // combats an issue where the valid flag is set after the change has been propagated
  useEffect(() => {
    handleChange(form);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.valid]);

  return (
    <DetailsSection title={form?.name ? `Bike Details - ${form?.name}` : 'Bike Details'} noCard>
      <article className="sport-information__vehicle">
        <FormRow fluidSpaced>
          <Input
            disabled={!editable}
            name="name"
            placeholder="Bike Name"
            label="Bike Name"
            value={form?.name}
            error={form?.messages?.name}
            onChange={handleFormChange}
          />
          <Input
            disabled={!editable}
            name="raceNumber"
            placeholder="Race Number"
            label="Race Number"
            value={form?.raceNumber}
            error={form?.messages?.raceNumber}
            onChange={handleFormChange}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Input
            disabled={!editable}
            name="manufacturer"
            placeholder="Manufacturer"
            label="Manufacturer"
            value={form?.manufacturer}
            error={form?.messages?.manufacturer}
            onChange={handleFormChange}
          />
          <Input
            disabled={!editable}
            name="model"
            placeholder="Model"
            label="Model"
            value={form?.model}
            error={form?.messages?.model}
            onChange={handleFormChange}
          />
        </FormRow>
        <FormRow fluidSpaced>
          <Input
            disabled={!editable}
            name="chassisNumber"
            placeholder="Chassis Number"
            label="Chassis Number"
            value={form?.chassisNumber}
            error={form?.messages?.chassisNumber}
            onChange={handleFormChange}
          />
          <Input
            disabled={!editable}
            name="engineNumber"
            placeholder="Engine Number"
            label="Engine Number"
            value={form?.engineNumber}
            error={form?.messages?.engineNumber}
            onChange={handleFormChange}
          />
        </FormRow>
      </article>
    </DetailsSection>
  );
};

export default Motorbike;
