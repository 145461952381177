import { API_HOST } from './index';

import { getHttpDeleteOptions, getHttpGetOptions, getHttpPutOptions } from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

const getProfileEndpoint = () => `${API_HOST}/profile`;
export const getLoadProfileRequest = () => [
  getProfileEndpoint(),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateProfileRequest = (profile) => [
  getProfileEndpoint(),
  getHttpPutOptions(profile, getAuthHeaders())
];

const getPublicProfileEndpoint = (token) => `${API_HOST}/profiles/${token}/public-complete`;
export const getUpdatePublicProfileRequest = (token, profile) => [
  getPublicProfileEndpoint(token),
  getHttpPutOptions(profile, getAuthHeaders())
];

const getUserSportsEndpoint = (id, sportId) => `${API_HOST}/users/${id}/sports/${sportId}`;
export const getRemoveSportRequest = (id, sportId) => [
  getUserSportsEndpoint(id, sportId),
  getHttpDeleteOptions(getAuthHeaders())
];
