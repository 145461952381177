import { getHttpGetOptions, getHttpPostOptions, getHttpPutOptions } from '@neslotech/utils';

import { API_HOST } from './index';

const getAuthenticateEndpoint = () => `${API_HOST}/authenticate`;
export const getAuthenticationRequest = (credentials) => [
  getAuthenticateEndpoint(),
  getHttpPostOptions(credentials)
];

const getForgotPasswordEndpoint = () => `${API_HOST}/passwords/forgot`;
export const getForgotPasswordRequest = (payload) => [
  getForgotPasswordEndpoint(),
  getHttpPostOptions(payload)
];

const getPasswordValidateTokenEndpoint = (token) => `${API_HOST}/passwords/${token}/validate`;
export const getValidateResetTokenRequest = (token) => [
  getPasswordValidateTokenEndpoint(token),
  getHttpGetOptions()
];

const getResetPasswordEndpoint = (token) => `${API_HOST}/passwords/${token}/reset`;
export const getResetPasswordRequest = (token, payload) => [
  getResetPasswordEndpoint(token),
  getHttpPutOptions(payload)
];

const getRegisterEndpoint = () => `${API_HOST}/register`;
export const getRegisterRequest = (payload) => [getRegisterEndpoint(), getHttpPostOptions(payload)];

const getProfileValidateTokenEndpoint = (token) => `${API_HOST}/profiles/${token}/validate`;
export const getValidateVerificationTokenRequest = (token) => [
  getProfileValidateTokenEndpoint(token),
  getHttpGetOptions()
];

const getVerifyEmailEndpoint = (token) => `${API_HOST}/profiles/${token}/verify`;
export const getVerifyEmailRequest = (token, payload) => [
  getVerifyEmailEndpoint(token),
  getHttpPostOptions(payload)
];
