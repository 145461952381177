import React from 'react';

import { getClassNames } from '@neslotech/utils';

import { ReactComponent as RemoveIcon } from '../../icons/remove-circle-filled-icon.svg';

import './pill.scss';

const Pill = ({ fitted, name, selected, onClick }) => (
  <article className={getClassNames('pill', { selected, fitted })} onClick={onClick}>
    <p className="pil__name">{name}</p>
    {selected && <RemoveIcon />}
  </article>
);

export default Pill;
