import React from 'react';

import './event-description.scss';

const EventDescription = ({ event, description }) => (
  <article className="event-description">
    <h2>{event.name}</h2>
    <h4>
      {new Date(event.start_date).toDateString()} - {new Date(event.end_date).toDateString()} @{' '}
      {event.venue}
    </h4>
    {description && <p>{event.description}</p>}
  </article>
);

EventDescription.defaultProps = {
  description: true
};

export default EventDescription;
