import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from '@neslotech/utils';

import { setShowWelcome } from '../../../actions/auth/auth.actions';
import { loadTrending as loadTrendingCompetitions } from '../../../actions/competition/competition.action';
import { loadTrending as loadTrendingEvents } from '../../../actions/event/event.action';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import SportSearch from '../../../components/landing-page/sport-search/SportSearch';
import TrendingSection from '../../../components/landing-page/trending-section/TrendingSection';
import ListEvents from '../../../components/landing-page/list-events/ListEvents';
import ContactUs from '../../../components/landing-page/contact-us/ContactUs';
import SectionLayout from '../../../components/layout/section/SectionLayout';
import WelcomeBanner from '../../../components/landing-page/banners/WelcomeBanner';

const LandingPageContainer = () => {
  const dispatch = useDispatch();
  const showWelcome = useSelector(({ auth_store }) => auth_store.showWelcome);

  const competitions = useSelector(({ competition_store }) => competition_store.trending);
  const events = useSelector(({ event_store }) => event_store.trending);

  const hideBanner = useCallback(() => {
    dispatch(setShowWelcome(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(loadTrendingCompetitions());
    dispatch(loadTrendingEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showWelcome) {
      const timeout = setTimeout(() => {
        hideBanner();
        clearTimeout(timeout);
      }, 5000);
    }
  }, [showWelcome, hideBanner]);

  return (
    <PublicLayout wide>
      {showWelcome && <WelcomeBanner />}
      <SportSearch />
      {!isEmpty(competitions) && (
        <TrendingSection entities={competitions} title="Trending Competitions" type="Competition" />
      )}
      {!isEmpty(events) && (
        <TrendingSection entities={events} title="Trending Events" type="Event" />
      )}
      <ListEvents />
      <SectionLayout title="Contact Us">
        <ContactUs />
      </SectionLayout>
    </PublicLayout>
  );
};

export default LandingPageContainer;
