import React from 'react';
import { Route, Routes } from 'react-router-dom';

import FilterProvider from '../../providers/Filter.provider';

import ProfileContainer from './profile/Profile.container';
import DependantsContainer from './dependants/Dependants.container';
import AddDependantsContainer from './dependants/add/AddDependants.container';
import DependantContainer from './dependants/details/Dependant.container';
import EventsContainer from './events/Events.container';
import EventContainer from './events/details/Event.container';
import EnterEventContainer from './events/enter/EnterEvent.container';
import SportsContainer from './sports/Sports.container';
import DependantSportsContainer from './dependants/details/sports/DependantSports.container';

const ParticipantContainer = () => (
  <Routes>
    <Route exact path="/profile" element={<ProfileContainer />} />

    <Route exact path="/dependants" element={<DependantsContainer />} />
    <Route exact path="/dependants/add" element={<AddDependantsContainer />} />
    <Route exact path="/dependants/:id" element={<DependantContainer />} />
    <Route exact path="/dependants/:id/sports" element={<DependantSportsContainer />} />

    <Route exact path="/sports" element={<SportsContainer />} />

    <Route
      exact
      path="/events"
      element={
        <FilterProvider>
          <EventsContainer />
        </FilterProvider>
      }
    />
    <Route exact path="/events/:id" element={<EventContainer />} />
    <Route exact path="/events/:id/enter" element={<EnterEventContainer />} />
  </Routes>
);

export default ParticipantContainer;
