import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AddAction, Card } from '@neslotech/eventhub-ui-kit';
import { isEmpty } from '@neslotech/utils';

import { ReactComponent as BackIcon } from '../../../icons/chevron-left-icon.svg';

import Pill from '../../pill/Pill';
import AddSport from '../add/AddSport';

import './sports-details.scss';

const SportsDetails = ({ sports, onRemove, onSave, allSports, title }) => {
  const navigate = useNavigate();

  const [addMode, setAddMode] = useState(false);

  const unselectedSports = useMemo(() => {
    return allSports.filter((sport) =>
      sports.every((selectedSport) => selectedSport.id !== sport.id)
    );
  }, [allSports, sports]);

  return (
    <article className="sports-details">
      <header>
        <section>
          <BackIcon onClick={() => navigate(-1)} />
          <h4 className="sports-details__title">{title ? title : 'My Sports'} </h4>
        </section>
        <p className="sports-details__subtitle">Below are the sports you have selected</p>
      </header>
      <main className="sports-details__selections">
        <Card padded>
          {isEmpty(sports) && (
            <p>
              You have no sports selected yet! Click on "Add New Sport" below to select a sport.
            </p>
          )}
          {sports.map((sport) => (
            <Pill key={sport.id} name={sport.name} selected onClick={() => onRemove(sport.id)} />
          ))}
        </Card>
      </main>
      <footer className="sports-details__actions">
        {!addMode && <AddAction title="Add New Sport" onClick={() => setAddMode(true)} />}
      </footer>
      {addMode && <AddSport sports={unselectedSports} onSave={onSave} />}
    </article>
  );
};

export default SportsDetails;
