import React from 'react';
import { Route, Routes } from 'react-router-dom';

import LoginContainer from './login/Login.container';
import ForgotPasswordContainer from './forgot-password/ForgotPassword.container';
import ResetPasswordContainer from './reset-password/ResetPassword.container';
import PasswordExpiredLinkContainer from './expired-link/PasswordExpiredLink.container';
import RegisterContainer from './register/Register.container';
import VerifyEmailContainer from './verify-email/VerifyEmail.container';
import VerificationExpiredLinkContainer from './expired-link/VerificationExpiredLink.container';
import CompleteProfileContainer from './complete-profile/details/CompleteProfile.container';
import SportSelectionContainer from './complete-profile/sports/SportSelection.container';

const AuthContainer = () => (
  <Routes>
    <Route exact path="/login" element={<LoginContainer />} />

    <Route exact path="/register" element={<RegisterContainer />} />

    <Route exact path="/password/forgot" element={<ForgotPasswordContainer />} />
    <Route exact path="/password/:token/reset" element={<ResetPasswordContainer />} />
    <Route exact path="/password/:token/expired" element={<PasswordExpiredLinkContainer />} />

    <Route exact path="/profile/:token/verify-email" element={<VerifyEmailContainer />} />
    <Route exact path="/profile/:token/expired" element={<VerificationExpiredLinkContainer />} />
    <Route
      exact
      path="/profile/:token/complete-profile/sports"
      element={<SportSelectionContainer />}
    />
    <Route
      exact
      path="/profile/:token/complete-profile/details"
      element={<CompleteProfileContainer />}
    />
  </Routes>
);

export default AuthContainer;
