import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getProfileInitialsForForm,
  ProfileImage,
  Tabs,
  toBase64
} from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { getClassNames } from '@neslotech/utils';

import { serverifySportDetails } from '../../profile/sports/sports.helpers';

import { ReactComponent as BackIcon } from '../../../icons/chevron-left-icon.svg';
import { ReactComponent as SportsIcon } from '../../../icons/pulse-icon.svg';

import ProfileForm from '../../profile/ProfileForm';

import './dependant-details.scss';

const rules = {
  validates: {
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    idNumber: ['isPresent'],
    contactNumber: ['isNumeric'],
    emergencyContactNumber: ['isNumeric'],
    familyMember: ['isPresent']
  }
};

const formify = (dependant) => ({
  id: dependant?.id,
  image: dependant?.image,
  email: dependant?.email,
  firstName: dependant?.first_name,
  lastName: dependant?.last_name,
  contactNumber: dependant?.phone_number,
  idNumber: dependant?.id_number,
  gender: dependant?.gender,
  dateOfBirth: dependant?.date_of_birth ? new Date(dependant?.date_of_birth) : new Date(),
  address: dependant?.address,
  city: dependant?.city,
  province: dependant?.province,
  familyMember: dependant?.family_member,
  emergencyContactPerson: dependant?.medical_information?.contact_person,
  emergencyContactNumber: dependant?.medical_information?.contact_number,
  medicalAidName: dependant?.medical_information?.medical_aid,
  medicalAidNumber: dependant?.medical_information?.medical_aid_number,
  bloodType: dependant?.medical_information?.blood_type,
  allergies: dependant?.medical_information?.allergies,
  notes: dependant?.medical_information?.notes,
  sports: dependant?.sports
});

const serverify = (form) => ({
  first_name: form.firstName,
  last_name: form.lastName,
  phone_number: form.contactNumber,
  image: form.image,
  gender: form.gender,
  date_of_birth: form.dateOfBirth,
  id_number: form.idNumber,
  address: form.address,
  city: form.city,
  province: form.province,
  family_member: form.familyMember,
  medical_information_attributes: {
    contact_person: form.emergencyContactPerson,
    contact_number: form.emergencyContactNumber,
    medical_aid: form.medicalAidName,
    medical_aid_number: form.medicalAidNumber,
    blood_type: form.bloodType,
    allergies: form.allergies,
    notes: form.notes
  },
  sport_details_attributes: serverifySportDetails(form.sports)
});

const personalDetailsSubtitle =
  'If certain fields are not applicable to the dependant such as email ' +
  'address please fill in your own details so we can contact you on their behalf.';

const medicalInformationSubtitle =
  'If you are the emergency contact person for your dependant please ' +
  'fill our your own information so we can contact you in case of a scratch or graze.';

const SPORTS_KEY = 'sports';

const tabs = (navigate) => [
  {
    key: SPORTS_KEY,
    label: 'Sports for this Dependant',
    icon: <SportsIcon />,
    onClick: () => navigate('sports')
  }
];

const DependantDetails = ({ dependant, onSave }) => {
  const navigate = useNavigate();
  const [form, setForm] = useFormState(formify(dependant), rules);

  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (dependant?.id === !form?.id) {
      setForm(formify(dependant));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependant, form?.id]);

  const handleFileChange = async (file) => {
    const data = await toBase64(file);
    handleChange({ image: data });
  };

  const handleChange = (newState) => setForm({ ...form, ...newState });
  const handleSportChange = (newSportState) => {
    const updatedSports = form.sports.reduce((accum, sport) => {
      if (sport.id === newSportState.sport_id) {
        sport = { ...sport, detail: { ...sport.detail, ...newSportState } };
      }

      accum.push(sport);
      return accum;
    }, []);
    handleChange({ sports: updatedSports });
  };

  const handleSubmit = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className={getClassNames('dependant-details', { editing: editMode })}>
      <header>
        <BackIcon role="button" onClick={() => navigate(-1)} />
        <h4 className="dependant-details__title">{dependant?.full_name}</h4>
      </header>
      <main className="dependant-details__content">
        <section className="dependant-details__context">
          <section className="dependant-details__avatar">
            <ProfileImage
              imgSrc={form?.image}
              initials={getProfileInitialsForForm(form)}
              editMode={editMode}
              handleFileChange={handleFileChange}
            />
          </section>
          <section className="dependant-details__tabs">
            {tabs && <Tabs tabs={tabs(navigate)} vertical />}
          </section>
        </section>
        <section className="dependant-details__form">
          <ProfileForm
            dependant
            editable={editMode}
            setEditable={setEditMode}
            form={form}
            handleSportChange={handleSportChange}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            personalDetailsTitle="Dependants Details"
            personalDetailsSubtitle={personalDetailsSubtitle}
            medicalInformationTitle="Dependants Medical Information"
            medicalInformationSubtitle={medicalInformationSubtitle}
          />
        </section>
      </main>
    </article>
  );
};

export default DependantDetails;
