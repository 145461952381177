import Cookies from 'js-cookie';

const ACCESS_TOKEN_ID = 'eventhub-participant-auth-token';
const REMEMBER_ME_ID = 'eventhub-participant-remember';

/** Retrieves the remember me cookie */
export const getRememberMeCookie = () => {
  return Cookies.get(REMEMBER_ME_ID);
};

/** Sets the remember me cookie */
export const setRememberMeCookie = (token) => {
  Cookies.set(REMEMBER_ME_ID, token);
};

/** Removes the remember me cookie */
export const removeRememberMeCookie = () => {
  Cookies.remove(REMEMBER_ME_ID);
};

/** Sets the auth token cookie */
export const setAuthTokenCookie = (token) => {
  Cookies.set(ACCESS_TOKEN_ID, token);
};

/** Get the auth token cookie */
export const getAuthTokenCookie = () => {
  return Cookies.get(ACCESS_TOKEN_ID);
};

/** Remove the auth token cookie */
export const removeAuthTokenCookie = () => {
  Cookies.remove(ACCESS_TOKEN_ID);
};

export const getAuthHeaders = () => {
  return { Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN_ID)}` };
};
