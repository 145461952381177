import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@neslotech/eventhub-ui-kit';

import { retrieveProfile } from '../../../actions/profile/profile.action';
import { loadDependants } from '../../../actions/dependant/dependant.action';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import DependantsList from '../../../components/dependants/DependantsList';

const DependantsContainer = () => {
  const dispatch = useDispatch();

  const profile = useSelector(({ profile_store }) => profile_store.profile);
  const stateLoading = useSelector(({ profile_store }) => profile_store.loading);
  const dependants = useSelector(({ dependant_store }) => dependant_store.dependants);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(retrieveProfile(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profile) {
      dispatch(loadDependants(profile.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <PublicLayout>
      {loading && <Loader filled />}
      {!loading && <DependantsList profile={profile} dependants={dependants} />}
    </PublicLayout>
  );
};

export default DependantsContainer;
