export const LOAD_EVENTS = '[EVENT_STORE] Load events';
export const SET_EVENTS = '[EVENT_STORE] Set events';
export const loadEvents = (onComplete) => ({
  type: LOAD_EVENTS,
  onComplete
});

export const LOAD_TRENDING = '[EVENT_STORE] Load trending events';
export const SET_TRENDING = '[EVENT_STORE] Set trending events';
export const loadTrending = () => ({
  type: LOAD_TRENDING
});

export const LOAD_EVENT = '[EVENT_STORE] Load event';
export const SET_EVENT = '[EVENT_STORE] Set event';
export const loadEvent = (id, onComplete) => ({
  type: LOAD_EVENT,
  id,
  onComplete
});

export const LOAD_ENTRIES = '[EVENT_STORE] Load event entries';
export const SET_ENTRIES = '[EVENT_STORE] Set event entries';
export const loadEntries = (id, onComplete) => ({
  type: LOAD_ENTRIES,
  id,
  onComplete
});

export const LOAD_ENTRY = '[EVENT_STORE] Load event entry';
export const SET_ENTRY = '[EVENT_STORE] Set event entry';
export const loadEntry = (id, entryId, onComplete) => ({
  type: LOAD_ENTRY,
  id,
  entryId,
  onComplete
});

export const SAVE_ENTRY = '[EVENT_STORE] Save event entry';
export const saveEntry = (id, payload, onSuccess) => ({
  type: SAVE_ENTRY,
  id,
  payload,
  onSuccess
});

export const UPDATE_ENTRY = '[EVENT_STORE] Update event entry';
export const updateEntry = (id, entryId, payload, onSuccess) => ({
  type: UPDATE_ENTRY,
  id,
  entryId,
  payload,
  onSuccess
});

export const LOAD_ENTRY_FORM = '[EVENT_STORE] Load event entry form';
export const SET_ENTRY_FORM = '[EVENT_STORE] Set event entry form';
export const loadEntryForm = (id) => ({
  type: LOAD_ENTRY_FORM,
  id
});

export const LOAD_MEMBER_TYPES = '[EVENT_STORE] Load event member types';
export const SET_MEMBER_TYPES = '[EVENT_STORE] Set event member types';
export const loadMemberTypes = (id) => ({
  type: LOAD_MEMBER_TYPES,
  id
});

export const LOAD_CLASSES = '[EVENT_STORE] Load event classes';
export const SET_CLASSES = '[EVENT_STORE] Set event classes';
export const loadClasses = (id) => ({
  type: LOAD_CLASSES,
  id
});

export const COMPLETE_ENTRY = '[EVENT_STORE] Complete event entry';
export const completeEntry = (id, entryId, payload, onSuccess) => ({
  type: COMPLETE_ENTRY,
  id,
  entryId,
  payload,
  onSuccess
});

export const UPLOAD_PROOF_OF_PAYMENT = '[EVENT_STORE] Upload proof of payment';
export const uploadProofOfPayment = (id, entryId, payload, onSuccess) => ({
  type: UPLOAD_PROOF_OF_PAYMENT,
  id,
  entryId,
  payload,
  onSuccess
});
