import React, { useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  Button,
  ButtonGroup,
  getProfileInitials,
  Header,
  isMobile,
  ProfileImage
} from '@neslotech/eventhub-ui-kit';
import { getClassNames, noOp, scrollIntoView } from '@neslotech/utils';

import { retrieveProfile } from '../../../actions/profile/profile.action';

import { useAuth } from '../../../hooks/useAuth';

import { ReactComponent as Logo } from '../../../images/footer-logo.svg';
import { ReactComponent as LogoutIcon } from '../../../icons/logout-icon.svg';
import { ReactComponent as ProfileIcon } from '../../../icons/profile-icon.svg';
import { ReactComponent as DependantsIcon } from '../../../icons/dependants-icon.svg';
import { ReactComponent as SportsIcon } from '../../../icons/pulse-icon.svg';

import './public-layout.scss';

const navItems = [
  {
    title: 'Home',
    path: '/'
  },
  {
    title: 'Events and Competitions',
    path: '/events'
  },
  {
    title: 'Organiser Portal',
    path: 'https://organiser.event-hub.co.za'
  },
  {
    title: 'Contact Us',
    path: '/contact-us'
  }
];

const anonymousItems = (navigate) => [
  {
    text: 'Login',
    onClick: () => navigate('/login')
  },
  {
    text: 'Register',
    onClick: () => navigate('/register')
  }
];

const loggedInItems = (navigate, auth) => [
  {
    text: 'My Profile',
    icon: <ProfileIcon />,
    onClick: () => navigate('/profile')
  },
  {
    text: 'My Dependants',
    icon: <DependantsIcon />,
    onClick: () => navigate('/dependants')
  },
  {
    text: 'My Sports',
    icon: <SportsIcon />,
    onClick: () => navigate('/sports')
  },
  {
    text: 'Log Out',
    icon: <LogoutIcon />,
    onClick: auth?.onLogout ?? noOp
  }
];

const PublicLayout = ({ white, grey, children, wide, full }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useAuth();

  const profile = useSelector(({ profile_store }) => profile_store.profile);

  useEffect(() => {
    if (auth.token) {
      dispatch(retrieveProfile());
    }

    scrollIntoView('root');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profileItems = useMemo(() => {
    return profile ? loggedInItems : anonymousItems;
  }, [profile]);

  const navigateToAuth = (e, path) => {
    e.stopPropagation();
    navigate(path);
  };

  const profileIcon = profile ? (
    <ProfileImage small imgSrc={profile.image} initials={getProfileInitials(profile)} />
  ) : (
    <ButtonGroup fluid spaced>
      <Button
        label="Register"
        secondary={isMobile()}
        onClick={(e) => navigateToAuth(e, '/register')}
      />
      <Button
        label="Login"
        hollow={!isMobile()}
        secondary={isMobile()}
        onClick={(e) => navigateToAuth(e, '/login')}
      />
    </ButtonGroup>
  );
  return (
    <article className={getClassNames('public-layout', { white, grey, wide, full })}>
      <Header
        publicMode
        navItems={navItems}
        items={profileItems(navigate, auth)}
        icon={profileIcon}
      >
        {profile && <p className="public-layout__username">{profile.full_name}</p>}
      </Header>
      <section className="public-layout__content">{children}</section>
      <footer>
        <section className="public-layout__detail">
          <section>
            <ul>
              <li>
                <Link to="/terms">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <a
                  href="https://organiser.event-hub.co.za"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Organiser Portal
                </a>
              </li>
            </ul>
            <Logo />
          </section>
          <Link to="/contact-us">Contact Us</Link>
        </section>
        <section className="public-layout__copyright">
          <small>Copyright &copy; 2023 EventHub (PTY) Ltd.</small>
          <small>All rights reserved</small>
          <small>Designed and developed by Neslo</small>
        </section>
      </footer>
    </article>
  );
};

export default PublicLayout;
