import React from 'react';

import { ReactComponent as FilledCheckIcon } from '../../../icons/filled-check-icon.svg';

import './welcome-banner.scss';

const WelcomeBanner = ({ onClick }) => (
  <article className="welcome-banner" onClick={onClick}>
    <FilledCheckIcon />
    <p className="welcome-banner__message">
      You have logged in successfully. You may now start entering events and competitions
    </p>
  </article>
);

export default WelcomeBanner;
