export const LOAD_DEPENDANTS = '[DEPENDANT_STORE] Load dependants';
export const SET_DEPENDANTS = '[DEPENDANT_STORE] Set dependants';
export const loadDependants = (id) => ({
  type: LOAD_DEPENDANTS,
  id
});

export const CREATE_DEPENDANT = '[DEPENDANT_STORE] Create dependant';
export const createDependant = (id, dependant, navigate) => ({
  type: CREATE_DEPENDANT,
  id,
  dependant,
  navigate
});

export const LOAD_DEPENDANT = '[DEPENDANT_STORE] Load dependant';
export const SET_DEPENDANT = '[DEPENDANT_STORE] Set dependant';
export const loadDependant = (id, dependantId, onComplete) => ({
  type: LOAD_DEPENDANT,
  id,
  dependantId,
  onComplete
});

export const UPDATE_DEPENDANT = '[DEPENDANT_STORE] Update dependant';
export const updateDependant = (id, dependantId, payload, onSuccess, onComplete) => ({
  type: UPDATE_DEPENDANT,
  id,
  dependantId,
  payload,
  onSuccess,
  onComplete
});

export const REMOVE_DEPENDANT = '[DEPENDANT_STORE] Remove dependant';
export const removeDependant = (id, dependantId) => ({
  type: REMOVE_DEPENDANT,
  id,
  dependantId
});

export const REMOVE_SPORT = '[DEPENDANT_STORE] Remove sport from dependant';
export const removeDependantSport = (id, dependantId, sportId) => ({
  type: REMOVE_SPORT,
  id,
  dependantId,
  sportId
});
