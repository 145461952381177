import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getProfileInitialsForForm, ProfileImage, toBase64 } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';
import { isEmailValue } from '@neslotech/utils';

import { ReactComponent as BackIcon } from '../../../icons/chevron-left-icon.svg';

import ProfileForm from '../../profile/ProfileForm';

import './add-dependant.scss';

const rules = {
  validates: {
    email: ['isEmailIfPresent'],
    firstName: ['isPresent'],
    lastName: ['isPresent'],
    idNumber: ['isPresent'],
    contactNumber: ['isNumeric'],
    emergencyContactNumber: ['isNumeric'],
    familyMember: ['isTrueOrFalse']
  },

  isTrueOrFalse(value) {
    if (value !== true && value !== false) {
      return 'This is required';
    }
  },

  isEmailIfPresent(value) {
    if (!value || value.trimEnd() === '') {
      return false;
    }

    if (!isEmailValue(value)) {
      return 'If an email address is provided, it needs to be valid';
    }
  }
};

const serverify = (form) => ({
  email: form.email,
  first_name: form.firstName,
  last_name: form.lastName,
  phone_number: form.contactNumber,
  image: form.image,
  gender: form.gender,
  date_of_birth: form.dateOfBirth,
  id_number: form.idNumber,
  address: form.address,
  city: form.city,
  province: form.province,
  family_member: form.familyMember,
  medical_information_attributes: {
    contact_person: form.emergencyContactPerson,
    contact_number: form.emergencyContactNumber,
    medical_aid: form.medicalAidName,
    medical_aid_number: form.medicalAidNumber,
    blood_type: form.bloodType,
    allergies: form.allergies,
    notes: form.notes
  }
});

const personalDetailsSubtitle =
  'If certain fields are not applicable to the dependant such as email ' +
  'address please fill in your own details so we can contact you on their behalf.';

const medicalInformationSubtitle =
  'If you are the emergency contact person for your dependant please ' +
  'fill our your own information so we can contact you in case of a scratch or graze.';

const AddDependant = ({ onSave }) => {
  const navigate = useNavigate();
  const [form, setForm] = useFormState(undefined, rules);

  const handleFileChange = async (file) => {
    const data = await toBase64(file);
    handleChange({ image: data });
  };

  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    const payload = serverify(form);
    onSave(payload);
  };

  return (
    <article className="add-dependant">
      <header>
        <BackIcon role="button" onClick={() => navigate(-1)} />
        <h4 className="add-dependant__title">Create New Dependant</h4>
      </header>
      <main className="add-dependant__content">
        <section className="add-dependant__context">
          <section className="add-dependant__avatar">
            <ProfileImage
              imgSrc={form?.image}
              initials={getProfileInitialsForForm(form)}
              editMode
              handleFileChange={handleFileChange}
            />
          </section>
        </section>
        <section className="add-dependant__form">
          <ProfileForm
            dependant
            isNew
            editable
            form={form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            personalDetailsTitle="Dependants Details"
            personalDetailsSubtitle={personalDetailsSubtitle}
            medicalInformationTitle="Dependants Medical Information"
            medicalInformationSubtitle={medicalInformationSubtitle}
          />
        </section>
      </main>
    </article>
  );
};

export default AddDependant;
