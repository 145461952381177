import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { searchElement } from '@neslotech/eventhub-ui-kit';

import { FilterContext } from '../contexts/Filter.context';

import { loadCompetitions } from '../actions/competition/competition.action';
import { loadEvents } from '../actions/event/event.action';

const filterElement = (element, filters) => {
  if (!filters || Object.keys(filters).length === 0) {
    return true;
  }

  if (
    filters.date &&
    new Date(element.start_date).toLocaleDateString() === filters.date.toLocaleDateString()
  ) {
    return true;
  }

  if (filters.events && element.type === 'Event') {
    return true;
  }

  if (filters.competitions && element.type === 'Competition') {
    return true;
  }

  if (filters.national && element.national) {
    return true;
  }

  return !!(filters.regional && !element.national);
};

const FilterProvider = ({ children }) => {
  const dispatch = useDispatch();

  const { events, competitions } = useSelector(({ event_store, competition_store }) => ({
    events: event_store.events,
    competitions: competition_store.competitions
  }));

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState();
  const [search, setSearch] = useState();

  useEffect(() => {
    dispatch(loadCompetitions(() => dispatch(loadEvents(() => setLoading(false)))));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mappedEvents = useMemo(() => {
    const eventsWithType = events
      .map((item) => ({ ...item, type: 'Event' }))
      .filter((item) => filterElement(item, filters))
      .filter((item) => searchElement(item.name, search));
    const competitionsWithType = competitions
      .map((item) => ({ ...item, type: 'Competition' }))
      .filter((item) => filterElement(item, filters))
      .filter((item) => searchElement(item.name, search) || searchElement(item.start_date, search));

    return competitionsWithType.concat(eventsWithType);
  }, [events, competitions, filters, search]);

  const handleFilterChange = (newFilter) => {
    const entries = Object.entries({ ...filters, ...newFilter }).filter(([_, value]) => !!value);
    setFilters(Object.fromEntries(entries));
  };

  const handleClearFilters = () => {
    setFilters();
  };

  const value = {
    loading,
    filters,
    search,
    events: mappedEvents,
    onFilterChange: handleFilterChange,
    onSearchChange: setSearch,
    onClearFilters: handleClearFilters
  };

  return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};

export default FilterProvider;
