import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '@neslotech/eventhub-ui-kit';

import { validateVerificationToken } from '../../../../actions/auth/auth.actions';
import { loadSports } from '../../../../actions/sport/sport.actions';
import { updatePublicProfile } from '../../../../actions/profile/profile.action';

import PublicLayout from '../../../../components/layout/public/PublicLayout';
import SportSelection from '../../../../components/sports/SportSelection';

const SportSelectionContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { token } = useParams();

  const sports = useSelector(({ sport_store }) => sport_store.sports);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(validateVerificationToken(token, navigate));
    dispatch(loadSports(() => setLoading(false)));
  }, [dispatch, token, navigate]);

  const handleSave = (payload, onComplete) => {
    dispatch(updatePublicProfile(token, payload, handleSkip, onComplete));
  };

  const handleSkip = () => {
    navigate(`/profile/${token}/complete-profile/details`);
  };

  return (
    <PublicLayout grey>
      {loading && <Loader filled />}
      {!loading && <SportSelection sports={sports} onSave={handleSave} onSkip={handleSkip} />}
    </PublicLayout>
  );
};

export default SportSelectionContainer;
