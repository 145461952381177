import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { retrieveProfile } from '../../../../actions/profile/profile.action';
import { createDependant } from '../../../../actions/dependant/dependant.action';

import PublicLayout from '../../../../components/layout/public/PublicLayout';
import AddDependant from '../../../../components/dependants/add/AddDependant';

const AddDependantsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(({ profile_store }) => profile_store.profile);

  useEffect(() => {
    dispatch(retrieveProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (payload) => {
    dispatch(createDependant(profile.id, payload, navigate));
  };

  return (
    <PublicLayout>
      <AddDependant onSave={handleSubmit} />
    </PublicLayout>
  );
};

export default AddDependantsContainer;
