import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authenticate } from '../../../actions/auth/auth.actions';

import AuthLayout from '../../../components/layout/auth/AuthLayout';
import Login from '../../../components/auth/login/Login';

const LoginContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const onLogin = (payload) => {
    setLoading(true);
    dispatch(authenticate(payload, navigate, () => setLoading(false)));
  };

  return (
    <AuthLayout altTitle="Login or Sign up to continue" subtitle="Login below to continue">
      <Login loading={loading} onLogin={onLogin} />
    </AuthLayout>
  );
};

export default LoginContainer;
