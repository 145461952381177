import React from 'react';

import './section-layout.scss';

const SectionLayout = ({ title, children }) => (
  <article className="section-layout">
    <header>
      <h1 className="section-layout__title">{title}</h1>
    </header>
    <main className="section-layout__content">{children}</main>
  </article>
);

export default SectionLayout;
