import { API_HOST } from './index';

import {
  getHttpDeleteOptions,
  getHttpGetOptions,
  getHttpPostOptions,
  getHttpPutOptions
} from '@neslotech/utils';

import { getAuthHeaders } from '../auth.util';

const getDependantsEndpoint = (id) => `${API_HOST}/users/${id}/dependants`;
export const getLoadDependantsRequest = (id) => [
  getDependantsEndpoint(id),
  getHttpGetOptions(getAuthHeaders())
];

export const getCreateDependantRequest = (id, dependant) => [
  getDependantsEndpoint(id),
  getHttpPostOptions(dependant, getAuthHeaders())
];

const getDependantEndpoint = (id, dependantId) =>
  `${API_HOST}/users/${id}/dependants/${dependantId}`;
export const getLoadDependantRequest = (id, dependantId) => [
  getDependantEndpoint(id, dependantId),
  getHttpGetOptions(getAuthHeaders())
];

export const getUpdateDependantRequest = (id, dependantId, payload) => [
  getDependantEndpoint(id, dependantId),
  getHttpPutOptions(payload, getAuthHeaders())
];

export const getRemoveDependantRequest = (id, dependantId) => [
  getDependantEndpoint(id, dependantId),
  getHttpDeleteOptions(getAuthHeaders())
];

const getUserSportsEndpoint = (id, sportId) => `${API_HOST}/users/${id}/sports/${sportId}`;
export const getRemoveSportRequest = (id, sportId) => [
  getUserSportsEndpoint(id, sportId),
  getHttpDeleteOptions(getAuthHeaders())
];
