export const SET_PROFILE = '[PROFILE_STORE] Set profile';

export const RETRIEVE_PROFILE = '[PROFILE_STORE] Retrieve profile';
export const retrieveProfile = (onSuccess) => ({
  type: RETRIEVE_PROFILE,
  onSuccess
});

export const UPDATE_PROFILE = '[PROFILE_STORE] Update my profile';
export const updateProfile = (profile, onSuccess, onComplete) => ({
  type: UPDATE_PROFILE,
  profile,
  onSuccess,
  onComplete
});

export const UPDATE_PUBLIC_PROFILE = '[PROFILE_STORE] Update public profile';
export const updatePublicProfile = (token, profile, onSuccess, onComplete) => ({
  type: UPDATE_PUBLIC_PROFILE,
  token,
  profile,
  onSuccess,
  onComplete
});

export const REMOVE_SPORT = '[PROFILE_STORE] Remove sport from profile';
export const removeSport = (id, sportId) => ({
  type: REMOVE_SPORT,
  id,
  sportId
});
