import React from 'react';

import { Loader } from '@neslotech/eventhub-ui-kit';

import { useFilters } from '../../../hooks/useFilters';

import PublicLayout from '../../../components/layout/public/PublicLayout';
import PublicFilterLayout from '../../../components/layout/filter/PublicFilterLayout';
import EventsList from '../../../components/events/EventsList';

const EventsContainer = () => {
  const { loading, events } = useFilters();

  return (
    <PublicLayout full>
      <PublicFilterLayout>
        {loading && <Loader filled />}
        {!loading && <EventsList events={events} />}
      </PublicFilterLayout>
    </PublicLayout>
  );
};

export default EventsContainer;
