import {
  SET_CLASSES,
  SET_COMPETITION,
  SET_COMPETITIONS,
  SET_ENTRIES,
  SET_ENTRY,
  SET_ENTRY_FORM,
  SET_MEMBER_TYPES,
  SET_TRENDING
} from '../../actions/competition/competition.action';

export const initialState = {
  competitions: [],
  trending: [],
  entries: [],
  competition: undefined,
  entry: undefined,
  entryForm: undefined,
  memberTypes: undefined,
  classes: undefined
};

export function competitionReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COMPETITIONS:
      const { competitions } = action;
      return {
        ...state,
        competitions
      };
    case SET_TRENDING:
      const { trending } = action;
      return {
        ...state,
        trending
      };
    case SET_COMPETITION:
      const { competition } = action;
      return {
        ...state,
        competition
      };
    case SET_ENTRY:
      const { entry } = action;
      return {
        ...state,
        entry
      };
    case SET_ENTRY_FORM:
      const { entryForm } = action;
      return {
        ...state,
        entryForm
      };
    case SET_MEMBER_TYPES:
      const { memberTypes } = action;
      return {
        ...state,
        memberTypes
      };
    case SET_CLASSES:
      const { classes } = action;
      return {
        ...state,
        classes
      };
    case SET_ENTRIES:
      const { entries } = action;
      return {
        ...state,
        entries
      };
    default:
      return state;
  }
}
