import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Button,
  getProfileInitials,
  ProfileImage,
  Table,
  Tabs,
  isMobile
} from '@neslotech/eventhub-ui-kit';

import { ReactComponent as ProfileIcon } from '../../icons/profile-square-icon.svg';
import { ReactComponent as DependantsIcon } from '../../icons/dependants-icon.svg';
import { ReactComponent as SportsIcon } from '../../icons/pulse-icon.svg';

import RemoveDependantContainer from '../../containers/participant/dependants/remove/RemoveDependant.container';

import './dependants-list.scss';

const PROFILE_KEY = 'profile';
const DEPENDANTS_KEY = 'dependants';
const SPORTS_KEY = 'sports';

const dependantsCols = [
  { Header: '', accessor: 'image', disableSortBy: true },
  { Header: 'Dependant Name', accessor: 'full_name' },
  { Header: 'Email Address', accessor: 'email' },
  { Header: 'Date Added', accessor: 'created_at' },
  { Header: '', accessor: 'actions', disableSortBy: true }
];

const mobileDependantsCols = [
  { Header: 'Dependant Name', accessor: 'full_name' },
  { Header: '', accessor: 'actions', disableSortBy: true }
];

const colModifiers = {
  image: {
    actions: true,
    thin: true
  },
  actions: {
    actions: true,
    thin: true
  }
};

const tabs = (navigate) => [
  {
    key: PROFILE_KEY,
    label: 'My Profile',
    icon: <ProfileIcon />,
    onClick: () => navigate('/profile')
  },
  {
    key: DEPENDANTS_KEY,
    label: 'My Dependants',
    icon: <DependantsIcon />,
    onClick: () => navigate('/dependants')
  },
  {
    key: SPORTS_KEY,
    label: 'My Sports',
    icon: <SportsIcon />,
    onClick: () => navigate('/sports')
  }
];

const dependantsTitle =
  'Listed below are all the dependants you have saved to your profile. If you wish to remove a ' +
  'dependant click on the remove button next to their name in the table';

const DependantsList = ({ profile, dependants }) => {
  const navigate = useNavigate();

  const [deleteContext, setDeleteContext] = useState();

  const mappedDependants = useMemo(() => {
    return dependants.map((item) => ({
      ...item,
      image: <ProfileImage small imgSrc={item?.image} initials={getProfileInitials(item)} />,
      actions: (
        <>
          <Button
            label="Remove"
            tertiary
            onClick={(e) => {
              e.stopPropagation();
              setDeleteContext(item);
            }}
          />
        </>
      ),
      onClick: () => navigate(`${item.id}`)
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependants]);

  return (
    <>
      <article className="dependants-list">
        <header>
          <h4 className="dependants-list__title">My Dependants</h4>
        </header>
        <main className="dependants-list__content">
          <section className="dependants-list__context">
            <section className="dependants-list__avatar">
              <ProfileImage imgSrc={profile?.image} initials={getProfileInitials(profile)} />
            </section>
            <section className="dependants-list__tabs">
              {tabs && <Tabs activeTab={DEPENDANTS_KEY} tabs={tabs(navigate)} vertical />}
            </section>
          </section>
          <article className="dependants-list__dependants">
            <header className="dependants-list__table-header">
              <div className="dependants-list__message">
                <h5 className="dependants-list__table-title">All Dependants</h5>
                {!isMobile() && (
                  <Button longText label="Add Dependant" onClick={() => navigate('add')} />
                )}
              </div>
              <p>{dependantsTitle}</p>
            </header>
            <main className="dependants-list__table">
              <Table
                headerModifiers={colModifiers}
                columnModifiers={colModifiers}
                cols={isMobile() ? mobileDependantsCols : dependantsCols}
                emptySubtitle="You have no dependants saved"
                rowData={mappedDependants}
              />
            </main>
            {isMobile() && (
              <footer className="dependants-list__actions">
                <Button longText label="Add Dependant" onClick={() => navigate('add')} />
              </footer>
            )}
          </article>
        </main>
      </article>
      <RemoveDependantContainer
        profile={profile}
        dependant={deleteContext}
        show={deleteContext}
        setShowModal={setDeleteContext}
      />
    </>
  );
};

export default DependantsList;
