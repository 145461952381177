import React from 'react';

import { Form, FormAction, FormRow, Input } from '@neslotech/eventhub-ui-kit';
import { useFormState } from '@neslotech/hooks';

import './verify-email.scss';

const rules = {
  validates: {
    email: ['isPresent', 'isEmail']
  }
};

const serverify = ({ email }) => ({
  email
});

const VerifyEmail = ({ loading, onVerifyEmail }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    const payload = serverify(form);
    onVerifyEmail(payload);
  };

  return (
    <section className="verify-email">
      <Form>
        <FormRow>
          <Input
            name="email"
            label="Email Address"
            placeholder="Email Address"
            type="email"
            onChange={handleChange}
            value={form?.email}
            error={form?.messages?.email}
          />
        </FormRow>
        <FormAction
          loading={loading}
          label="Continue"
          onClick={handleSubmit}
          disabled={!form?.valid}
        />
      </Form>
    </section>
  );
};

export default VerifyEmail;
