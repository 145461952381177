export const serverifySportDetails = (sports) =>
  (sports ?? [])
    .filter((sport) => !!sport.detail && !!sport.detail.type)
    .map(({ detail }) => ({
      id: detail.id,
      type: detail.type,
      sport_id: detail.sport_id,
      license_number: detail.licenseNumber,
      sponsors: detail.sponsors,
      team_name: detail.teamName,
      vehicles_attributes: serverifyVehicles(detail.vehicles)
    }));

const serverifyVehicles = (vehicles) =>
  (vehicles ?? [])
    .filter((vehicle) => !!vehicle.type)
    .map((vehicle) => ({
      id: vehicle.id,
      type: vehicle.type,
      chassis_number: vehicle.chassisNumber,
      engine_number: vehicle.engineNumber,
      manufacturer: vehicle.manufacturer,
      model: vehicle.model,
      name: vehicle.name,
      race_number: vehicle.raceNumber
    }));
