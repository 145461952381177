import React, { useState } from 'react';

import { Button, Card, isMobile } from '@neslotech/eventhub-ui-kit';
import { isEmpty } from '@neslotech/utils';

import Pill from '../pill/Pill';

import './sport-selection.scss';

const serverify = (selections) => ({
  user_sports_attributes: selections.map((selection) => ({ sport_id: selection.id }))
});

const SportSelection = ({ sports, onSkip, onSave, skippable }) => {
  const [selections, setSelections] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSelection = (sport) => {
    const exists = selections.some((selection) => selection.id === sport.id);
    if (exists) {
      setSelections(selections.filter((selection) => selection.id !== sport.id));
    } else {
      setSelections([...selections, sport]);
    }
  };

  const handleSave = () => {
    setLoading(true);
    const payload = serverify(selections);

    onSave(payload, () => setLoading(false));
  };

  return (
    <article className="sport-selection">
      {skippable && (
        <header>
          <h4 className="sport-selection__title">Select your sports</h4>
          <p className="sport-selection__subtitle">
            Select a sport from the list that you would like to add to your profile
          </p>
        </header>
      )}
      <main className="sport-selection__content">
        <Card padded={!isMobile()}>
          <section className="sport-selection__selections">
            {(sports ?? []).map((sport) => {
              const isSelected = selections.some((selection) => selection.id === sport.id);

              return (
                <Pill
                  key={sport.id}
                  name={sport.name}
                  selected={isSelected}
                  onClick={() => handleSelection(sport)}
                />
              );
            })}
          </section>
        </Card>
      </main>
      <footer className="sport-selection__actions">
        <Button
          loading={loading}
          disabled={isEmpty(selections)}
          label="Save"
          onClick={handleSave}
        />
        {skippable && <Button hollow label="Skip Step" onClick={onSkip} />}
      </footer>
    </article>
  );
};

SportSelection.defaultProps = {
  skippable: true
};

export default SportSelection;
