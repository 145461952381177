import React, { useState } from 'react';

import { getClassNames } from '@neslotech/utils';

import { FAQCARDDETAILS } from './faq.util';

import './faqs.scss';

const FaqCard = ({
  title,
  secondQuestion,
  firstQuestion,
  icon,
  clickedItem,
  setClickedItem,
  firstAnswer,
  secondAnswer
}) => (
  <article className="faq-card">
    <section className="faq-card__details">
      <span className="faq-card__icon">{icon}</span>
      <p className="faq-card__title">{title}</p>
      <p
        onClick={() => setClickedItem(firstQuestion)}
        className={getClassNames('faq-card__question', { clicked: clickedItem === firstQuestion })}
      >
        {firstQuestion}
      </p>
      {clickedItem === firstQuestion && <p className="faq-card__answer">{firstAnswer}</p>}
      {secondQuestion && (
        <p
          onClick={() => setClickedItem(secondQuestion)}
          className={getClassNames('faq-card__question', {
            clicked: clickedItem === secondQuestion
          })}
        >
          {secondQuestion}
        </p>
      )}
      {clickedItem === secondQuestion && secondAnswer && (
        <p className="faq-card__answer">{secondAnswer}</p>
      )}
    </section>
  </article>
);

const Faqs = () => {
  const [clickedItem, setClickedItem] = useState(undefined);

  return (
    <article className="faqs">
      <section className="faqs__title">
        <span>FAQ</span>
        <p>
          Having some problems or want to ask us anything - here are some of our most asked
          questions
        </p>
      </section>
      <section className="faqs__cards">
        {FAQCARDDETAILS.map((item) => (
          <FaqCard
            key={item.title}
            title={item.title}
            icon={item.icon}
            firstQuestion={item.firstQuestion}
            secondQuestion={item.secondQuestion}
            firstAnswer={item.firstAnswer}
            secondAnswer={item.secondAnswer}
            clickedItem={clickedItem}
            setClickedItem={setClickedItem}
          />
        ))}
      </section>
    </article>
  );
};

export default Faqs;
